<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>{{$t('公會審核')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('公會審核')}}</li>
      </ol>
    </section>
    <section class="content" style="min-height:auto;">
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 }">
            <a @click="setTab(1)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('公會待審核')}}</a>
          </li>
          <li :class="{ active: tab == 2 }" v-if="can歷程">
            <a @click="setTab(2)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('公會審核歷程')}}</a>
          </li>
        </ul>
        <div class="tab-content" v-if="tab == 1">
          <form class="top-box clearfix" @submit.prevent="getDataList()">
            <div class="form-group m-0">
              <!-- <label class="control-label pull-left mt-7">公會名稱：</label> -->
              <div class="select-group mb-10 mr-10">
                <select class="form-control" v-model="searchFilter.type">
                  <option value="暱稱">{{$t('暱稱')}}</option>
                  <option value="UID">UID</option>
                </select>
              </div>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="searchFilter.暱稱" class="form-control" :placeholder="$t(searchFilter.type == 'UID' ? 'UID' : '暱稱')" >
              </div>
              <!-- <div class="select-group mb-10 mr-10">
                <select class="form-control" v-model="searchFilter.審核狀態">
                  <option value="">{{$t('全部狀態')}}</option>
                  <option v-for="(name, key) in reviewStatus" :key="key" :value="key">{{$t(name)}}</option>
                </select>
              </div> -->
              <!-- <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="searchFilter.處理人員" class="form-control" :placeholder="$t('處理人員')" >
              </div> -->
              <div class="form-group pull-left m-0">
                <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
                <div class="input-group date datetime pull-left mb-10 mr-10" style="width: 300px;">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <DateRangePicker
                    :propStartDate.sync="searchFilter.開始時間"
                    :propEndDate.sync="searchFilter.結束時間"
                    :propTimePicker="true"
                    displayFormat="YYYY/MM/DD HH:mm"
                  />
                </div>
              </div>
            </div>
            <div class="pull-left mb-10 mr-10">
              <button type="submit" class="btn btn-primary mr-10">
                查詢
              </button>
              <button type="button" class="btn btn-default" @click="exportData()" :disabled="datas.length === 0">
                {{$t('匯出')}}
              </button>
            </div>
          </form>
          <div class="table-responsive">
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:100px; width:10%">{{$t('申請時間')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('申請人暱稱')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('申請人UID')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('公會名稱')}}</th>
                  <th style="min-width:150px; width: 5%">{{$t('備註')}}</th>
                  <th style="min-width:135px; width: 5%">{{$t('審核狀態')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="message"><td colspan="11">{{ $t(message) }}</td></tr>
                <tr v-else-if="datas.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <!-- <tr v-else-if="datas.length > 0 && filterData.length == 0"><td colspan="11">{{ $t('資料中無符合搜尋條件的結果') }}</td></tr> -->
                <tr v-else v-for="(item, index) in pagedData" :key="item.shortID || item.公會長UID">
                  <td>{{ item.申請日期 }}</td>
                  <td v-html="item.暱稱 "></td>
                  <td>{{ item.shortID }}</td>
                  <td v-html="item.公會名稱"></td>
                  <td>{{ item.備註 || "-" }}</td>
                  <td>
                    <div style="display:flex; gap:5px; justify-content:center;" v-if="item.狀態 == 0">
                      <button type="button" v-if="can通過" @click="acceptGuild(item)"
                        class="btn btn-success">{{$t('通過')}}</button>
                      <button type="button" v-if="can駁回" @click="rejectGuild(item)"
                        class="btn btn-danger">{{$t('駁回')}}</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-10">
            <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" @pageMethod="onPageChange" />
          </div>
          <!-- <pre>{{ datas }}</pre> -->
        </div>
        <div class="tab-content" v-if="tab == 2">
          <form class="top-box clearfix" @submit.prevent="getPastList()">
            <div class="form-group m-0">
              <!-- <label class="control-label pull-left mt-7">公會名稱：</label> -->
              <div class="select-group mb-10 mr-10">
                <select class="form-control" v-model="searchData.type" @change="getPastList()">
                  <option value="暱稱">{{$t('暱稱')}}</option>
                  <option value="UID">UID</option>
                </select>
              </div>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="searchData.暱稱" class="form-control" :placeholder="$t(searchData.type == 'UID' ? 'UID' : '暱稱')" >
              </div>
              <div class="select-group mb-10 mr-10">
                <select class="form-control" v-model="searchData.審核狀態" @change="getPastList()">
                  <option :value="-1">{{$t('全部狀態')}}</option>
                  <option v-for="(name, key) in reviewStatus" :key="key" :value="key">{{$t(name)}}</option>
                </select>
              </div>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="searchData.處理人員" class="form-control" :placeholder="$t('處理人員')" >
              </div>
              <div class="form-group pull-left m-0">
                <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
                <div class="input-group date datetime pull-left mb-10 mr-10" style="width: 300px;">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <DateRangePicker
                    :propStartDate.sync="searchData.開始時間"
                    :propEndDate.sync="searchData.結束時間"
                    :propTimePicker="true"
                    displayFormat="YYYY/MM/DD HH:mm"
                  />
                </div>
              </div>
            </div>
            <div class="pull-left mb-10 mr-10">
              <button type="submit" class="btn btn-primary mr-10">
                查詢
              </button>
              <button type="button" class="btn btn-default" @click="exportPast()" :disabled="datas.length === 0">
                {{$t('匯出')}}
              </button>
            </div>
          </form>
          <div class="table-responsive">
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:100px; width:10%">{{$t('申請時間')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('公會長UID')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('公會長暱稱')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('公會名稱')}}</th>
                  <th style="min-width: 75px; width: 5%">{{$t('審核狀態')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('處理時間')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('處理人員')}}</th>
                  <th style="min-width:150px; width: 5%">{{$t('備註')}}</th>
                  <!-- <th style="min-width:135px; width: 5%">{{$t('審核狀態')}}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="message"><td colspan="11">{{ $t(message) }}</td></tr>
                <tr v-else-if="datas.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <!-- <tr v-else-if="datas.length > 0 && filterData.length == 0"><td colspan="11">{{ $t('資料中無符合搜尋條件的結果') }}</td></tr> -->
                <tr v-else v-for="(item, index) in pastDatas" :key="item.id">
                  <td>{{ item.申請日期 || item.申請時間 }}</td>
                  <td>{{ item.shortID || item.公會長UID }}</td>
                  <td v-html="item.暱稱 || item.公會長暱稱"></td>
                  <td v-html="item.公會名稱"></td>
                  <td>{{ (reviewStatus[item.狀態 || item.審核狀態]) || (item.狀態 || item.審核狀態) }}</td>
                  <td>{{ item.處理時間 || "-" }}</td>
                  <td>{{ item.處理人員 || "-" }}</td>
                  <td>{{ item.備註 || "-" }}</td>
                  <!-- <td>
                    <div style="display:flex; gap:5px; justify-content:center;" v-if="item.審核狀態 == 0">
                      <button type="button" v-if="can通過" @click="acceptGuild(item)"
                        class="btn btn-success">{{$t('通過')}}</button>
                      <button type="button" v-if="can駁回" @click="rejectGuild(item)"
                        class="btn btn-danger">{{$t('駁回')}}</button>
                    </div>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-10">
            <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" @pageMethod="onPageChange" />
          </div>
          <!-- <pre>{{ pastDatas }}</pre> -->
        </div>
      </div>
    </section>

    <!-- <pre>{{ datas }}</pre> -->
  </div>
</template>

<style lang="scss" scoped>
  table.table>tbody>tr {
    word-break: break-word;
  }
  table.table>thead>tr>th {
    word-break: keep-all;
  }
</style>
<style scoped>
  .table>>>mark{
    padding: 0;
    background: #ff8;
  }
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import router from "../../../router";
import utils from "@/assets/js/app-utils";

const reviewStatus = {
  0: "待審核",
  1: "已審核",
  2: "駁回",
  3: "失敗"
}

export default {
  components: {
    DateRangePicker,
    Pagination,
    router
  },
  data() {
    return {
      tab: 1,
      projectId: this.$store.state.projectId,
      reviewStatus: reviewStatus,

      searchFilter: {
        /** @type {"暱稱"|"UID"} */ 
        type: "暱稱", 
        暱稱: "",
        審核狀態: "",
        處理人員: "",
        開始時間: new moment(new Date("2024-01-01")).format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
      },
      loading: false,
      message: "",
      total: 0, //總筆數
      datas: [],
      pageNum: 1,
      pageSize: 20,

      searchData: {
        /** @type {"暱稱"|"UID"} */ 
        type: "暱稱",
        "公會長暱稱": "",
        "公會長UID": "",
        "審核狀態": -1,
        "處理人員": "",
        "開始時間": new moment().add(-1, "month").format("YYYY-MM-DD 00:00:00"),
        "結束時間": new moment().format("YYYY-MM-DD 23:59:59"),
        "pageNum": 1,
        "pageSize": 10
      },
      pastLoading: false,
      pastMessage: "",
      pastTotal: 0, //總筆數
      pastDatas: [],

      // 權限
      can查詢: this.$user.hasPermission(dfn.permission.公會審核清單查詢),
      can通過: this.$user.hasPermission(dfn.permission.公會審核通過),
      can駁回: this.$user.hasPermission(dfn.permission.公會審核駁回),
      can歷程: this.$user.hasPermission(dfn.permission.公會審核歷程查詢),
    };
  },
  async created() {
    if (this.$route.query.tab){
      this.tab = this.$route.query.tab
    }
    this.getData();
    // this.getTypes();
    // window.v = this
  },
  watch: {

  },
  computed:{
    filterData: function() {
      let data = this.datas;
      let f = this.searchFilter;
      let startDate = new Date(f.開始時間).valueOf();
      let endDate = new Date(f.結束時間).valueOf();
      let arr = [];
      for (let item of data){
        let isMatch = true;
        let date = new Date(item.申請日期).valueOf();
        if (f.type == "UID"){
          if (f.暱稱 && item.shortID && item.shortID.toLowerCase().indexOf(f.暱稱.toLowerCase()) < 0) isMatch = false;
        } else {
          if (f.暱稱 && item.暱稱 && item.暱稱.toLowerCase().indexOf(f.暱稱.toLowerCase()) < 0) isMatch = false;
        }
        if (f.審核狀態 != "" && item.狀態 != f.審核狀態) isMatch = false;
        if (f.處理人員 && item.處理人員 && item.處理人員.toLowerCase().indexOf(f.處理人員.toLowerCase()) < 0) isMatch = false;
        if (date < startDate || endDate < date) isMatch = false;
        if (isMatch) arr.push(item);
      }

      this.pageNum = 1;
      this.total = arr.length;
      return arr;
    },
    pagedData: function () {
      return this.filterData.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
  },
  methods: {
    setTab(num){
      this.tab = num;
      let query = Object.assign({}, this.$route.query, {tab:num});
      if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
        this.$router.replace({query:query});
      }
    },
    async onPageChange(pageIdx, pageSize) {
      if (this.tab == 2) {
        this.pastPageNum = pageIdx;
        this.pastPageSize = pageSize;
        // this.getPastInfo();
      } else {
        this.searchData.pageNum = pageIdx;
        this.searchData.pageSize = pageSize;
        // this.getServiceInfo();
      }
    },
    // getTypes() {
    //   this.$store.dispatch("xsw/服務類別查詢").then((result) => {
    //     for (let item of result) {
    //       if (item.啟用YN == 0) { // 0 == 啟用
    //         this.$set(this.serviceTypes, item.編號, item.名稱);
    //         // console.log(item);
    //       }
    //     }
    //   });
    // },
    getData(){
      this.getDataList();
      if(this.can歷程) this.getPastList();
    },
    getDataList() {
      this.loading = true;
      this.$store.dispatch("xsw/公會審核清單查詢")
      .then(res => {
        let data = res || [];
        this.message = "";
        this.datas = data;
        this.total = data.length;
        this.loading = false;
      }, err => {
        this.message = err;
        this.datas = [];
        this.total = 0;
        this.loading = false;
      });
    },
    getPastList() {
      this.pastLoading = true;
      this.searchData.公會長UID = this.searchData.type == 'UID' ? this.searchData.暱稱 : '';
      this.searchData.公會長暱稱 = this.searchData.type == '暱稱' ? this.searchData.暱稱 : '';
      this.$store.dispatch("xsw/公會審核歷程查詢", this.searchData)
      .then(res => {
        let data = res.datas || [];
        this.pastMessage = "";
        this.pastDatas = data;
        this.pastTotal = res.total;
        this.pastLoading = false;
      }, err => {
        this.pastMessage = err;
        this.pastDatas = [];
        this.pastTotal = 0;
        this.pastLoading = false;
      });
    },
    acceptGuild(guild){
      this.$store.dispatch("xsw/公會審核通過", {
        玩家識別值: guild.shortID || guild.公會長UID,
        公會長暱稱: guild.暱稱 || guild.公會長暱稱,
        申請時間: new moment().format("YYYY-MM-DD HH:mm:ss"),
        公會名稱: guild.公會名稱,
        備註: "公會審核通過",
      }).then(res => {
        this.$eventBus.$emit("showAlert", `「${guild.暱稱}」的公會「${guild.公會名稱}」，審核審核<span class="text-green">「通過」</span>`);
        this.getData();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      })
      
    },
    rejectGuild(guild){
      this.$store.dispatch("xsw/公會審核駁回", {
        玩家識別值: guild.shortID || guild.公會長UID,
        公會長暱稱: guild.暱稱 || guild.公會長暱稱,
        申請時間: new moment().format("YYYY-MM-DD HH:mm:ss"),
        公會名稱: guild.公會名稱,
        備註: "公會審核駁回",
      }).then(res => {
        this.$eventBus.$emit("showAlert", `「${guild.暱稱}」的公會「${guild.公會名稱}」，審核<span class="text-red">「駁回」</span>`);
        this.getData();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      })
    },
    exportData() {
      let data = utils.deepCloneObj(this.datas);
      let items = [];
      data.forEach(item => {
        // console.log(item);
        items.push({
          "申請日期": item.申請日期,
          "申請人暱稱": item.暱稱,
          "申請人UID": item.shortID,
          "公會名稱": item.公會名稱,
          "備註": item.備註,
        });
      });
      utils.exportData(
          items,
          `${utils.formatTime(new Date(), 'YYYYMMDD')}_公會審核.xlsx`,
          '公會審核'
      );
    },
    exportPast() {
      let data = utils.deepCloneObj(this.pastDatas);
      utils.exportData(
          data,
          `${utils.formatTime(new Date(), 'YYYYMMDD')}_公會審核歷程.xlsx`,
          '公會審核歷程'
      );
    },
    uiDate(date) {
      let d = new Date(date);
      if (d != "Invalid Date") return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
      else return date;
    }
  },
};
</script>