/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';

import Content from './components/Content';
import Login from './views/Login';
import Home from './views/Home';
import AnnouncementList from './views/AnnouncementList';
import AnnouncementEdit from './views/AnnouncementEdit';
import ClassTypeManager from './views/ClassTypeManager';
import AdvertisementList from './views/AdvertisementList';
import AdvertisementEdit from './views/AdvertisementEdit';
import SalePointList from './views/SalePointList';
import SalePointEdit from './views/SalePointEdit';
import PaymentOrderList from "@/views/Report/PaymentOrderList";
import CollaborateGameList from "@/views/CollaborateGameList";
import CollaborateGameEdit from "@/views/CollaborateGameEdit";
import MediaList from "@/views/MediaList";

import ServerStatus from './views/ServerStatus';
// import GiftDeliveryList from './views/GiftDeliveryList';
// import GiftDeliveryEdit from './views/GiftDeliveryEdit';
import AsgardServerStatus from '@/views/projects/asgard/ServerStatus';
import AsgardUpdateCSV from '@/views/projects/asgard/UpdateCSV';
import AsgardSendPlayerGift from '@/views/projects/asgard/SendPlayerGift';
import AsgardKickPlayer from '@/views/projects/asgard/KickPlayer';
import VideoList from './views/projects/waninFoundation/VideoList';
import VideoEdit from './views/projects/waninFoundation/VideoEdit';
import WaninFundAlbumList from './views/projects/waninFoundation/AlbumList';
import AlbumEditing from './views/projects/waninFoundation/AlbumEditing';
import AlbumPhotoManage from './views/projects/waninFoundation/AlbumPhotoManage';
import WaninFundIndexList from './views/projects/waninFoundation/IndexList';
import WaninFundIndexEdit from './views/projects/waninFoundation/IndexEdit';
import ContactUsList from './views/projects/waninFoundation/ContactUsList';
import ContactUsEdit from './views/projects/waninFoundation/ContactUsEdit';
import PartnerList from './views/projects/waninFoundation/PartnerList';
import PartnerEdit from './views/projects/waninFoundation/PartnerEdit';
import DonationList from './views/projects/waninFoundation/DonationList';
import WaninFundDonationEdit from './views/projects/waninFoundation/DonationEdit';
import SNManagerList from "@/views/SNManager/SNManagerList";
import AwardList from "@/views/SNManager/AwardList";
import AwardEdit from "@/views/SNManager/AwardEdit";
import ActivityManager from "@/views/SNManager/ActivityManager";
import ActivityEdit from "@/views/SNManager/ActivityEdit";
import ItemsList from "@/views/SNManager/ItemsList";
import ItemsType from "@/views/SNManager/ItemsType";
import SNDetail from "@/views/SNManager/SNDetail";
import WaninFundCarouselList from "@/views/projects/waninFoundation/CarouselList";
import WaninFundCarouselEdit from "@/views/projects/waninFoundation/CarouselEdit";
import AboutUsList from "@/views/projects/waninFoundation/AboutUsList";
import WaninFundAboutHistoryList from "@/views/projects/waninFoundation/AboutHistoryList";
import WaninFundAboutHistoryEdit from "@/views/projects/waninFoundation/AboutHistoryEdit";
import WaninFundServiceList from "@/views/projects/waninFoundation/ServiceList";
import WaninFundServiceEdit from "@/views/projects/waninFoundation/ServiceEdit";
import ServiceList01 from "@/views/projects/waninFoundation/ServiceList01";
import ServiceList02 from "@/views/projects/waninFoundation/ServiceList02";
import WaninFundWaninJobList from "@/views/projects/waninFoundation/WaninJobList";
import WaninFundWaninJobEdit from "@/views/projects/waninFoundation/WaninJobEdit";
import ContentList from '@/views/content/ContentList';
import ContentEdit from '@/views/content/ContentEdit';
import BonusAndRefundList from '@/views/BonusAndRefundList';
import BonusAndRefundEdit from "@/views/BonusAndRefundEdit";
import RedeemPrizesList from '@/views/RedeemPrizes/RedeemPrizesList';
import RedeemPrizesInfo from '@/views/RedeemPrizes/RedeemPrizesInfo';
import DKActivityRecord from "@/views/DKActivityRecord";
import WhiteListByPhone from "@/views/WhiteListRecord/WhiteListByPhone";
import WaninUnite2020AlbumList from './views/projects/WaninGames/Events/WaninUnite2020AlbumList';
import WaninUnite2020AlbumEditing from './views/projects/WaninGames/Events/WaninUnite2020AlbumEditing';
import RedeemCodeRecordList from './views/projects/WaninGames/RedeemCodeRecordList';
import RedeemCodeList from "@/views/EventManager/RedeemCodeList";
import DailySignInList from "@/views/EventManager/DailySignInList";
import XinContactUsList from "@/views/ContactUsList";
import XinContactUsEdit from "@/views/ContactUsEdit";
import AlbumList from "@/views/AlbumManager/AlbumList";
import AlbumEdit from "@/views/AlbumManager/AlbumEdit";

import QppAnniuncementList from "@/views/projects/qpp/AnnouncementList";
import QppAnniuncementEdit from "@/views/projects/qpp/AnnouncementEdit";
import QppForumMuteSetting from "@/views/projects/qpp/ForumMuteSetting";
import QppForumReportList from "@/views/projects/qpp/ForumReportList";

// Newtalk
import NewtalkForumMuteSetting from "@/views/projects/Newtalk/ForumMuteSetting";
import NewtalkForumReportList from "@/views/projects/Newtalk/ForumReportList";
import NewtalkAnnouncementList from "@/views/projects/Newtalk/AnnouncementList";
import NewtalkAnnouncementEdit from "@/views/projects/Newtalk/AnnouncementEdit";
import NewtalkAnnouncementDetailList from "@/views/projects/Newtalk/AnnouncementDetailList";
import NewtalkForumStatisticList from "@/views/projects/Newtalk/ForumStatisticList";
import NewtalkVoteList from "@/views/projects/Newtalk/VoteList";
import NewtalkVoteEdit from "@/views/projects/Newtalk/VoteEdit";
import NewtalkVoteDetailList from "@/views/projects/Newtalk/VoteDetailList";
import NewtalkVoteResult from "@/views/projects/Newtalk/VoteResult";
import NewtalkElectionHistorical from "@/views/projects/Newtalk/ElectionHistorical";
import NewtalkElectionGroupList from "@/views/projects/Newtalk/ElectionGroupList";
import NewtalkElectionGroupEdit from "@/views/projects/Newtalk/ElectionGroupEdit";
import NewtalkElectionPersonList from "@/views/projects/Newtalk/ElectionPersonList";
import MemberMute from "@/views/projects/Newtalk/MemberMute";
import ImagesList from "@/views/projects/Newtalk/ImagesList";
import ImagesEdit from "@/views/projects/Newtalk/ImagesEdit";
import NewtalkSurvey from "@/views/projects/Newtalk/Survey";

//FC
import VersionSettings from "@/views/projects/FC/VersionSettings";
import CustomerService from "@/views/projects/FC/CustomerService";
import CustomerServiceEdit from "@/views/projects/FC/CustomerServiceEdit";
import TopupRecordList from "@/views/projects/FC/TopupRecordList";
import TopupRecordEdit from "@/views/projects/FC/TopupRecordEdit";
import RewardMessage from "@/views/projects/FC/RewardMessage";
import RewardMessageHistory from "@/views/projects/FC/RewardMessageHistory";
import RewardMessageEdit from "@/views/projects/FC/RewardMessageEdit";
import PlayerDataList from "@/views/projects/FC/PlayerDataList";
import PlayerHistoryList from "@/views/projects/FC/PlayerHistoryList";
import PlayerSettingList from "@/views/projects/FC/PlayerSettingList";
import FcAnnouncementList from "@/views/projects/FC/AnnouncementList";
import FcAnnouncementEdit from "@/views/projects/FC/AnnouncementEdit";

// NMJ
import NmjClassTypeManager from "@/views/projects/NMJ/ClassTypeManager";
import NmjServiceTypeManager from "@/views/projects/NMJ/ServiceTypeManager";
import NmjCustomerService from "@/views/projects/NMJ/CustomerService";
import NmjCustomerServiceEdit from "@/views/projects/NMJ/CustomerServiceEdit";
import NmjAnnouncementList from "@/views/projects/NMJ/AnnouncementList";
import NmjAnnouncementEdit from "@/views/projects/NMJ/AnnouncementEdit";
import NmjAppBannerList from "@/views/projects/NMJ/AppBannerList";
import NmjAppBannerEdit from "@/views/projects/NMJ/AppBannerEdit";
import NmjMarquee from "@/views/projects/NMJ/Marquee";
import NmjMarqueeEdit from "@/views/projects/NMJ/MarqueeEdit";
import NmjNotification from "@/views/projects/NMJ/Notification";
import NmjNotificationEdit from "@/views/projects/NMJ/NotificationEdit";
import NmjWebBanner from "@/views/projects/NMJ/WebBanner";
import NmjWebBannerEdit from "@/views/projects/NMJ/WebBannerEdit";
import NmjWebNews from "@/views/projects/NMJ/WebNews";
import NmjWebNewsEdit from "@/views/projects/NMJ/WebNewsEdit";
import NmjWebQuestion from "@/views/projects/NMJ/WebQuestion";
import NmjWebHighlight from "@/views/projects/NMJ/WebHighlight";
import NmjRewardMessage from "@/views/projects/NMJ/RewardMessage";
import NmjRewardMessageHistory from "@/views/projects/NMJ/RewardMessageHistory";
import NmjRewardMessageEdit from "@/views/projects/NMJ/RewardMessageEdit";
import NmjPlayerData from "@/views/projects/NMJ/PlayerData";
import NmjPlayerSetting from "@/views/projects/NMJ/PlayerSetting";
import NmjPlayerHistory from "@/views/projects/NMJ/PlayerHistory";
import NmjPlayerDeposit from "@/views/projects/NMJ/PlayerDeposit";
import NmjGameRecord from "@/views/projects/NMJ/GameRecord";
import NmjGameBattle from "@/views/projects/NMJ/GameBattle";
import NmjGameBet from "@/views/projects/NMJ/GameBet";
import NmjGameBetList from "@/views/projects/NMJ/GameBetList";
import NmjHistory from "@/views/projects/NMJ/History";

// New_H5
import AWH5ClassManager from "@/views/projects/AWH5/ClassManager";
import AWH5GameIntroList from "@/views/projects/AWH5/GameIntroList";
import AWH5GameIntroEdit from "@/views/projects/AWH5/GameIntroEdit";
import AWH5GameOperationList from "@/views/projects/AWH5/GameOperationList";
import AWH5GameOperationEdit from "@/views/projects/AWH5/GameOperationEdit";
import AWH5WebHome from "@/views/projects/AWH5/WebHome";
import AWH5WebGame from "@/views/projects/AWH5/WebGame";
import AWH5WebGameEdit from "@/views/projects/AWH5/WebGameEdit";
import AWH5WebNews from "@/views/projects/AWH5/WebNews";
import AWH5WebNewsEdit from "@/views/projects/AWH5/WebNewsEdit"; 

// XinStar_Web
import XswTypeManager from "@/views/projects/XSW/TypeManager";
import XswMemberData from "@/views/projects/XSW/MemberData";
import XswMemberRebate from "@/views/projects/XSW/MemberRebate";
import XswMemberDelete from "@/views/projects/XSW/MemberDelete";
import XswGuildData from "@/views/projects/XSW/GuildData";
import XswGuildDetail from "@/views/projects/XSW/GuildDetail";
import XswGameHistory from "@/views/projects/XSW/GameHistory";
import XswGameMessage from "@/views/projects/XSW/GameMessage";
import XswGameMessageEdit from "@/views/projects/XSW/GameMessageEdit";
import XswHistory from "@/views/projects/XSW/History";
import XswSerial from "@/views/projects/XSW/Serial";
import XswDeposit from "@/views/projects/XSW/Deposit";

// XinStar_Global
import XsgDashboard from "@/views/projects/XSG/Dashboard";
import XsgMember from "@/views/projects/XSG/Member";
import XsgWebGame from "@/views/projects/XSG/WebGame";
import XsgWebGameEdit from "@/views/projects/XSG/WebGameEdit";
import XsgWebGameTag from "@/views/projects/XSG/WebGameTag";
import XsgWebBanner from "@/views/projects/XSG/WebBanner";
import XsgWebNews from "@/views/projects/XSG/WebNews";
import XsgGameAnnouncement from "@/views/projects/XSG/GameAnnouncement";
import XsgGameBanner from "@/views/projects/XSG/GameBanner";
import XsgGameBannerEdit from "@/views/projects/XSG/GameBannerEdit";
import XsgWebBannerEdit from "@/views/projects/XSG/WebBannerEdit";
import XsgGameHistory from "@/views/projects/XSG/GameHistory";
import XsgGameMessage from "@/views/projects/XSG/GameMessage";
import XsgGameMessageEdit from "@/views/projects/XSG/GameMessageEdit";
import XsgGuildData from "@/views/projects/XSG/GuildData";
import XsgGuildReview from "@/views/projects/XSG/GuildReview";
import XsgPayment from "@/views/projects/XSG/Payment";
import XsgSerial from "@/views/projects/XSG/Serial";
//spider
import SpiderGroupSetList from "@/views/projects/spider/GroupSetList";
import SpiderGroupSetEdit from "@/views/projects/spider/GroupSetEdit";
import SpiderBigDataList from "@/views/projects/spider/BigData";
import SpiderBigDataEdit from "@/views/projects/spider/BigDataEdit";
import SpiderBigDataKOL from "@/views/projects/spider/BigDataKOL";
import SpiderBigDataPost from "@/views/projects/spider/BigDataPost";
import SpiderSubManagement from "@/views/projects/spider/SubManagement";
import KOLLeaderboard from "@/views/projects/spider/KOLLeaderboard";
import CollectTypeManager from "@/views/projects/spider/CollectTypeManager";

// MFTD
import BeforeLoginList from "@/views/projects/MFTD/BeforeLoginList";
import BeforeShareList from "@/views/projects/MFTD/BeforeShareList";
import MftdContactUsList from "@/views/projects/MFTD/ContactUsList";
import MftdContactUsEdit from "@/views/projects/MFTD/ContactUsEdit";
import MftdLotteryList from "@/views/projects/MFTD/MftdLotteryList";

// StarCastle
import StarCastleBeforeLoginList from "@/views/projects/StarCastle/BeforeLoginList";
import StarCastleBeforeShareList from "@/views/projects/StarCastle/BeforeShareList";
import StarCastleBeforePrizeList from "@/views/projects/StarCastle/BeforePrizeList";

// Heti
import HetiPaymentOrderList from "@/views/projects/heti/PaymentOrderList";
import HetiVideo from "@/views/projects/heti/Video";
import HetiAttractions from "@/views/projects/heti/Attractions";
import HetiAttractionsEdit from "@/views/projects/heti/AttractionsEdit";

//IPS
import PaymentOwedList from "@/views/projects/ips/PaymentOwedList";
import PaymentOwedEdit from "@/views/projects/ips/PaymentOwedEdit";
import DisputedOrderRecords from "@/views/projects/ips/DisputedOrderRecords"

import IPSSettingList from "@/views/projects/ips/IPSSettingList";
import IPSSettingEdit from "@/views/projects/ips/IPSSettingEdit";
import IPSSettingAccountList from "@/views/projects/ips/IPSSettingAccountList";
import IPSSettingAccountEdit from "@/views/projects/ips/IPSSettingAccountEdit";
import IPSSettingPayList from "@/views/projects/ips/IPSSettingPayList";
import IPSSettingPayEdit from "@/views/projects/ips/IPSSettingPayEdit";
import FirmSettingList from "@/views/projects/ips/FirmSettingList";
import FirmSettingEdit from "@/views/projects/ips/FirmSettingEdit";
import FirmPaySettingList from "@/views/projects/ips/FirmPaySettingList";
import FirmPaySettingEdit from "@/views/projects/ips/FirmPaySettingEdit";
import PayFlowMaintainList from "@/views/projects/ips/PayFlowMaintainList";
import GoodsManagerList from "@/views/projects/ips/GoodsManagerList";
import GoodsManagerEdit from "@/views/projects/ips/GoodsManagerEdit";
import FirmPayFlowList from "@/views/projects/ips/FirmPayFlowList";
import FirmPayFlowEdit from "@/views/projects/ips/FirmPayFlowEdit";
import HistoryManage from "@/views/projects/ips/HistoryManage";
import TransactionInfoList from "@/views/projects/ips/TransactionInfoList";
import FMSPage from "@/views/projects/ips/FMS/FMSPage";
import RefundSearchList from "@/views/projects/ips/RefundSearchList";
import Invoice from "@/views/projects/ips/Invoice";
import InvoiceElectronicProcess from "@/views/projects/ips/InvoiceElectronicProcess";
import InvoiceElectronicProcessPrint from "@/views/projects/ips/InvoiceElectronicProcessPrint";
import InvoiceMaintain from "@/views/projects/ips/Invoice/InvoiceMaintain";

import ProductList from "@/views/projects/ips/product/ProductList";

import RiskWhiteList from "@/views/projects/ips/Risk/RiskWhiteList";
import RiskLogList from "@/views/projects/ips/Risk/RiskLogList";
import PayLimitList from "@/views/projects/ips/Risk/PayLimitList";

//xin
import XinCurrentAccount from "@/views/projects/xin/currentAccount";
import XinPlatformBuyDel from "@/views/projects/xin/PlatformBuyDel";
import XinGameIntroList from "@/views/projects/xin/GameIntroList";
import XinGameIntroEdit from "@/views/projects/xin/GameIntroEdit";
import XinGameOperationList from "@/views/projects/xin/GameOperationList";
import XinGameOperationEdit from "@/views/projects/xin/GameOperationEdit";
import XinVideoList from "@/views/projects/xin/VideoList";

// wanin
import WaninAdvertisementList from "@/views/projects/wanin/AdvertisementList";
import WaninAdvertisementEdit from "@/views/projects/wanin/AdvertisementEdit";
import WaninAnnouncementList from "@/views/projects/wanin/AnnouncementList";
import WaninAnnouncementEdit from "@/views/projects/wanin/AnnouncementEdit";

//FB Robot
import FBRobotList from "@/views/projects/FBRobot/RobotList.vue";
import FBPostKeywordList from "@/views/projects/FBRobot/PostKeywordList.vue";
import FBKeywords from "@/views/projects/FBRobot/Keywords.vue";
import FBFanPage from "@/views/projects/FBRobot/FanPage.vue";

// KF3
import KF3BeforeLoginList from "@/views/projects/KF3/BeforeLoginList";
import KF3BeforeShareList from "@/views/projects/KF3/BeforeShareList";
import ArtCreationList from "@/views/projects/KF3/events/ArtCreationList";
import ArtCreationEditing from "@/views/projects/KF3/events/ArtCreationEditing";
import TopUp from "@/views/projects/KF3/TopUp";
import KF3Maintain from "@/views/projects/KF3/Maintain";
import Correspond from "@/views/projects/KF3/Correspond";
import WinnerList from "@/views/projects/KF3/WinnerList";

//xac
import XacAlbumList from './views/projects/xac/AlbumList';
import XacAlbumEditing from './views/projects/xac/AlbumEditing';
import XacCarouselList from './views/projects/xac/CarouselList';
import XacCarouselEdit from './views/projects/xac/CarouselEdit';
import XacPartnerList from "@/views/projects/xac/PartnerList";
import XacPartnerEdit from "@/views/projects/xac/PartnerEdit";
import XacServiceList from "@/views/projects/xac/ServiceList";
import XacServiceEdit from "@/views/projects/xac/ServiceEdit";
import XacAnnouncementList from "@/views/projects/xac/AnnouncementList";
import XacAnnouncementEdit from "@/views/projects/xac/AnnouncementEdit";
import XacGamePartnerList from "@/views/projects/xac/PartnerListGame";
import XacGamePartnerEdit from "@/views/projects/xac/PartnerEditGame";

//smm
import smmAnnouncementList from "@/views/projects/smm/AnnouncementList";
import smmAnnouncementEdit from "@/views/projects/smm/AnnouncementEdit";
import smmVideoList from "@/views/projects/smm/VideoList";
import smmVideotrace from "@/views/projects/smm/Videotrace";
import smmMsgtrace from "@/views/projects/smm/Msgtrace";
import smmKeywords from "@/views/projects/smm/Keywords";
import smmWarnKeywords from "@/views/projects/smm/WarnKeywords";
//MessageSystem
import MessageSystem from "@/views/projects/MessageSystem/MessageSystem.vue";
import GroupSystem from "@/views/projects/MessageSystem/GroupSystem.vue";
import MessageStatistics from "@/views/projects/MessageSystem/MessageStatistics.vue";
import CustomerServiceLog from "@/views/projects/MessageSystem/CustomerServiceLog.vue";
import ServerManager from "@/views/projects/MessageSystem/ServerManager";

//監頻
import ChannelManager from "@/views/projects/ChannelMonitor/ChannelManager";
import KeyWordManager from "@/views/projects/ChannelMonitor/KeyWordManager";
import CommunicationLog from "@/views/projects/ChannelMonitor/CommunicationLog";

import MessageClassTypeManager from "@/views/projects/FBRobot/MessageClassTypeManager.vue";
import MessageCanMsgManager from "@/views/projects/FBRobot/MessageCanMsgManager.vue";
import MessageSocialManager from "@/views/projects/MessageSystem/MessageSocialManager.vue";
import MessageSocialDetailManager from "@/views/projects/MessageSystem/MessageSocialDetailManager.vue";
// 簡訊系統
import SMSProvider from "@/views/projects/SMS/Provider.vue";
import SMSAccount from "@/views/projects/SMS/Account.vue";
import SMSAccountEdit from "@/views/projects/SMS/AccountEdit.vue";
import SMSSetting from "@/views/projects/SMS/Setting.vue";
import SMSState from "@/views/projects/SMS/State.vue";
import SMSDetails from "@/views/projects/SMS/Details.vue";
import SMSCustomize from "@/views/projects/SMS/Customize.vue";
import SMSCustomizeEdit from "@/views/projects/SMS/CustomizeEdit.vue";
import SMSCustomizeDetail from "@/views/projects/SMS/CustomizeDetail.vue";
import SMSBlockList from "@/views/projects/SMS/blockList.vue";
import SMSMessage from "@/views/projects/SMS/Message.vue";
import SMSGroup from "@/views/projects/SMS/Group.vue";
import SMSDeliveryRate from "@/views/projects/SMS/DeliveryRate.vue";

// slot
import slotClientGameList from "@/views/projects/slot/clientGameList";
import slotClientGameHallList from "@/views/projects/slot/clientGameHallList";
import slotClientGameActivity from "@/views/projects/slot/clientGameActivity";
import clientGameContent from "@/views/projects/slot/clientGameContent";
import slotAnalyzeList from "@/views/projects/slot/analyzeList";
import slotAnalyze from "@/views/projects/slot/analyze";
import slotCompanyList from "@/views/projects/slot/companyList";
import slotGameCategory from "@/views/projects/slot/gameCategory";
import slotGameList from "@/views/projects/slot/gameList";
import slotGamehallList from "@/views/projects/slot/gamehallList";
import slotGameContent from "@/views/projects/slot/gameContent";
import slotGameContentEdit from "@/views/projects/slot/gameContentEdit";
import slotGameInfo from "@/views/projects/slot/gameInfo";
import slotActivity from "@/views/projects/slot/activity";
import slotActivityList from "@/views/projects/slot/activityList";
import slotActivityEdit from "@/views/projects/slot/activityEdit";
import slotActivityItem from "@/views/projects/slot/activityItem";
import slotActivityItemSet from "@/views/projects/slot/activityItemSet";
import slotActivityItemSetEdit from "@/views/projects/slot/activityItemSetEdit";
import slotActivityItemControl from "@/views/projects/slot/activityItemControl";
import slotPlayCategory from "@/views/projects/slot/playCategory";
import slotPlayList from "@/views/projects/slot/playList";
import slotPlayEdit from "@/views/projects/slot/playEdit";
import slotArticleCategory from "@/views/projects/slot/articleCategory";
import slotArticleList from "@/views/projects/slot/articleList";
import slotArticleEdit from "@/views/projects/slot/articleEdit";
import slotTagList from "@/views/projects/slot/tagList";
import slotSearch from "@/views/projects/slot/search";
import slotSearchTag from "@/views/projects/slot/searchTag";

//MID
import MIDAnnouncementList from "@/views/projects/MID/AnnouncementList";
import MIDAnnouncementEdit from "@/views/projects/MID/AnnouncementEdit";
import MIDCertificationList from "@/views/projects/MID/CertificationList";
import MIDCertificationDetailList from "@/views/projects/MID/CertificationDetailList";

// 金筷子
import PotMember from "@/views/projects/Pot/Member";
// DEM
import DEMBeforeLoginList from "@/views/projects/DEM/BeforeLoginList";
import DEMBeforeShareList from "@/views/projects/DEM/BeforeShareList";
import DEMGamer from "@/views/projects/DEM/Gamer";
import DEMMaintain from "@/views/projects/DEM/Maintain";

import store from './store';


import { loadLangByProject } from '@/assets/js/i18nFunc';
Vue.use(Router);

let rou = new Router({
    // mode: "history",
    routes: [
        {
            path: '/Login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/InvoiceElectronicProcessPrint/:id?',
            name: 'InvoiceElectronicProcessPrint',
            component: InvoiceElectronicProcessPrint,
        },
        {
            path: '/Home',
            name: 'gotoProject',
            component: Content,
            children: [
                {
                    path: '/',
                    name: 'Home',
                    component: Home,
                },
                {
                    path: '/AnnouncementList', // 公告列表
                    name: 'AnnouncementList',
                    component: AnnouncementList,
                    props: true,
                },
                {
                    path: '/AnnouncementEdit/:id?', // 公告編輯
                    name: 'AnnouncementEdit',
                    component: AnnouncementEdit,
                    props: true,
                },
                {
                    path: '/ClassTypeManager', // 分類管理
                    name: 'ClassTypeManager',
                    component: ClassTypeManager,
                },
                {
                    path: '/AdvertisementList', // 廣告管理
                    name: 'AdvertisementList',
                    component: AdvertisementList,
                },
                {
                    path: '/AdvertisementEdit/:id?', // 廣告編輯
                    name: 'AdvertisementEdit',
                    component: AdvertisementEdit,
                },
                {
                    path: '/PaymentOrderList', // 儲值歷程
                    name: 'PaymentOrderList',
                    component: PaymentOrderList,
                },
                {
                    path: '/SalePointList', // 銷售據點管理
                    name: 'SalePointList',
                    component: SalePointList,
                },
                {
                    path: '/SalePointEdit/:id?', // 銷售據點編輯
                    name: 'SalePointEdit',
                    component: SalePointEdit,
                },
                {
                    path: '/CollaborateGameList', // 遊戲管理
                    name: 'CollaborateGameList',
                    component: CollaborateGameList,
                },
                {
                    path: '/CollaborateGameEdit/:id?', // 遊戲編輯
                    name: 'CollaborateGameEdit',
                    component: CollaborateGameEdit,
                },
                {
                    path: '/MediaList', // 音樂管理
                    name: 'MediaList',
                    component: MediaList,
                },
                {
                    path: '/ServerStatus', // 伺服器狀態
                    name: 'ServerStatus',
                    component: ServerStatus,
                },
                {
                    path: '/SNManagerList', // 序號管理
                    name: 'SNManagerList',
                    component: SNManagerList,
                },
                {
                    path: '/Asgard/ServerStatus',
                    name: 'AsgardServerStatus',
                    component: AsgardServerStatus,
                },
                {
                    path: '/ActivityManager', // 活動管理
                    name: 'ActivityManager',
                    component: ActivityManager,
                },
                {
                    path: '/Asgard/UpdateCSV',
                    name: 'AsgardUpdateCSV',
                    component: AsgardUpdateCSV,
                },
                {
                    path: '/ActivityEdit/:id?', // 活動編輯
                    name: 'ActivityEdit',
                    component: ActivityEdit,
                },
                {
                    path: '/Asgard/SendPlayerGift',
                    name: 'AsgardSendPlayerGift',
                    component: AsgardSendPlayerGift,
                },
                {
                    path: '/AwardList', // 獎項列表
                    name: 'AwardList',
                    component: AwardList,
                },
                {
                    path: '/Asgard/KickPlayer',
                    name: 'AsgardKickPlayer',
                    component: AsgardKickPlayer,
                },
                {
                    path: '/AwardEdit/:id?', // 獎項編輯
                    name: 'AwardEdit',
                    component: AwardEdit,
                },
                {
                    path: '/ItemsList', // 道具列表
                    name: 'ItemsList',
                    component: ItemsList,
                },
                {
                    path: '/ItemsType', // 道具種類設定
                    name: 'ItemsType',
                    component: ItemsType,
                },
                {
                    path: '/WaninFund/AlbumList',
                    name: 'WaninFundAlbumList',
                    component: WaninFundAlbumList,
                    props: true,
                },
                {
                    path: '/AlbumEditing',
                    name: 'AlbumEditing',
                    component: AlbumEditing,
                },
                {
                    path: '/AlbumPhotoManage',
                    name: 'AlbumPhotoManage',
                    component: AlbumPhotoManage,
                },
                {
                    path: '/ContactUsList',
                    name: 'ContactUsList',
                    component: ContactUsList,
                    props: true,
                },
                {
                    path: '/ContactUsEdit',
                    name: 'ContactUsEdit',
                    component: ContactUsEdit,
                    props: true,
                },
                {
                    path: '/PartnerList',
                    name: 'PartnerList',
                    component: PartnerList,
                    props: true,
                },
                {
                    path: '/PartnerEdit',
                    name: 'PartnerEdit',
                    component: PartnerEdit,
                    props: true,
                },
                {
                    path: '/DonationList',
                    name: 'DonationList',
                    component: DonationList,
                    props: true,
                },
                {
                    path: '/WaninFund/DonationEdit',
                    name: 'WaninFundDonationEdit',
                    component: WaninFundDonationEdit,
                    props: true,
                },
                {
                    path: '/WaninFund/CarouselList',
                    name: 'WaninFundCarouselList',
                    component: WaninFundCarouselList,
                    props: true,
                },
                {
                    path: '/WaninFund/CarouselEdit',
                    name: 'WaninFundCarouselEdit',
                    component: WaninFundCarouselEdit,
                    props: true,
                },
                {
                    path: '/WaninFund/VideoList',
                    name: 'WaninFundVideoList',
                    component: VideoList,
                },
                {
                    path: '/WaninFund/VideoEdit',
                    name: 'WaninFundVideoEdit',
                    component: VideoEdit,
                },
                {
                    path: '/AboutUsList',
                    name: 'AboutUsList',
                    component: AboutUsList,
                    props: true,
                },
                {
                    path: '/AboutHistoryList',
                    name: 'WaninFundAboutHistoryList',
                    component: WaninFundAboutHistoryList,
                    props: true,
                },
                {
                    path: '/WaninFund/AboutHistoryEdit',
                    name: 'WaninFundAboutHistoryEdit',
                    component: WaninFundAboutHistoryEdit,
                    props: true,
                },
                {
                    path: '/WaninFund/IndexList',
                    name: 'WaninFundIndexList',
                    component: WaninFundIndexList,
                    props: true,
                },
                {
                    path: '/WaninFund/IndexEdit',
                    name: 'WaninFundIndexEdit',
                    component: WaninFundIndexEdit,
                    props: true,
                },
                {
                    path: '/WaninFund/ServiceList',
                    name: 'WaninFundServiceList',
                    component: WaninFundServiceList,
                    props: true,
                },
                {
                    path: '/WaninFund/ServiceEdit',
                    name: 'WaninFundServiceEdit',
                    component: WaninFundServiceEdit,
                    props: true,
                },
                {
                    path: '/ServiceList01',
                    name: 'ServiceList01',
                    component: ServiceList01,
                    props: true,
                },
                {
                    path: '/ServiceList02',
                    name: 'ServiceList02',
                    component: ServiceList02,
                    props: true,
                },
                {
                    path: '/WaninFund/WaninJobList',
                    name: 'WaninFundWaninJobList',
                    component: WaninFundWaninJobList,
                    props: true,
                },
                {
                    path: '/WaninFund/WaninJobEdit',
                    name: 'WaninFundWaninJobEdit',
                    component: WaninFundWaninJobEdit,
                    props: true,
                },
                {
                    path: '/SNDetail/:activityId?/:awardsId?', // 序號明細
                    name: 'SNDetail',
                    component: SNDetail,
                },
                {
                    path: '/ContentList/:module',
                    name: 'ContentList',
                    component: ContentList,
                },
                {
                    path: '/ContentEdit/:id?',
                    name: 'ContentEdit',
                    component: ContentEdit,
                },
                {
                    path: '/BonusAndRefundList', // 補扣點列表
                    name: 'BonusAndRefundList',
                    component: BonusAndRefundList,
                },
                {
                    path: '/BonusAndRefundEdit', // 補扣點列表
                    name: 'BonusAndRefundEdit',
                    component: BonusAndRefundEdit,
                },
                {
                    path: '/RedeemPrizesList', // 獎項兌換列表
                    name: 'RedeemPrizesList',
                    component: RedeemPrizesList,
                },
                {
                    path: '/RedeemPrizesInfo/:id?',
                    name: 'RedeemPrizesInfo',
                    component: RedeemPrizesInfo,
                },
                {
                    path: '/DKActivityRecord',
                    name: 'DKActivityRecord',
                    component: DKActivityRecord,
                },
                {
                    path: '/WhiteListByPhone',
                    name: 'WhiteListByPhone',
                    component: WhiteListByPhone,
                },
                {
                    path: '/WaninGames/Events/WaninUnite2020AlbumList',
                    name: 'WaninUnite2020AlbumList',
                    component: WaninUnite2020AlbumList,
                    props: true,
                },
                {
                    path: '/WaninUnite2020AlbumEditing',
                    name: 'WaninUnite2020AlbumEditing',
                    component: WaninUnite2020AlbumEditing,
                },
                {
                    path: '/WaninGames/RedeemCodeRecordList',
                    name: 'RedeemCodeRecordList',
                    component: RedeemCodeRecordList,
                },
                {
                    path: '/Xin/ContactUsList',
                    name: 'XinContactUsList',
                    component: XinContactUsList,
                },
                {
                    path: '/Xin/ContactUsEdit',
                    name: 'XinContactUsEdit',
                    component: XinContactUsEdit,
                },
                {
                    path: '/Qpp/AnniuncementList',
                    name: 'QppAnniuncementList',
                    component: QppAnniuncementList
                },
                {
                    path: '/Qpp/AnniuncementEdit/:lang?/:id?',
                    name: 'QppAnniuncementEdit',
                    component: QppAnniuncementEdit
                },
                {
                    path: '/Qpp/ForumMuteSetting',
                    name: 'QppForumMuteSetting',
                    component: QppForumMuteSetting
                },
                {
                    path: '/Qpp/ForumReportList',
                    name: 'QppForumReportList',
                    component: QppForumReportList
                },
                {
                    path: '/Event/DailySignInList',
                    name: 'DailySignInList',
                    component: DailySignInList
                },
                {
                    path: '/Event/RedeemCodeList',
                    name: 'RedeemCodeList',
                    component: RedeemCodeList
                },
                {
                    path: '/AlbumList',
                    name: 'AlbumList',
                    component: AlbumList
                },
                {
                    path: '/AlbumEdit/:id',
                    name: 'AlbumEdit',
                    component: AlbumEdit
                },
                // Newtalk
                {
                    path: '/Newtalk/ForumMuteSetting',
                    name: 'NewtalkForumMuteSetting',
                    component: NewtalkForumMuteSetting
                },
                {
                    path: '/Newtalk/ForumReportList',
                    name: 'NewtalkForumReportList',
                    component: NewtalkForumReportList
                },
                {
                    path: '/Newtalk/AnnouncementList',
                    name: 'NewtalkAnnouncementList',
                    component: NewtalkAnnouncementList
                },
                {
                    path: '/Newtalk/AnnouncementEdit/:id?',
                    name: 'NewtalkAnnouncementEdit',
                    component: NewtalkAnnouncementEdit
                },
                {
                    path: '/Newtalk/AnnouncementDetailList/:id?/:type?',
                    name: 'NewtalkAnnouncementDetailList',
                    component: NewtalkAnnouncementDetailList
                },
                {
                    path: '/Newtalk/ForumStatisticList',
                    name: 'NewtalkForumStatisticList',
                    component: NewtalkForumStatisticList
                },
                {
                    path: '/Newtalk/VoteList',
                    name: 'NewtalkVoteList',
                    component: NewtalkVoteList
                },
                {
                    path: '/Newtalk/VoteEdit/:id?',
                    name: 'NewtalkVoteEdit',
                    component: NewtalkVoteEdit
                },
                {
                    path: '/Newtalk/VoteDetailList/:id?/:type?',
                    name: 'NewtalkVoteDetailList',
                    component: NewtalkVoteDetailList
                },
                {
                    path: '/Newtalk/VoteResult/:id?',
                    name: 'NewtalkVoteResult',
                    component: NewtalkVoteResult
                },
                {
                    path: '/Newtalk/ElectionHistorical/:type?',
                    name: 'NewtalkElectionHistorical',
                    component: NewtalkElectionHistorical
                },
                {
                    path: '/Newtalk/ElectionGroupList',
                    name: 'NewtalkElectionGroupList',
                    component: NewtalkElectionGroupList
                },
                {
                    path: '/Newtalk/ElectionGroupEdit/:id?',
                    name: 'NewtalkElectionGroupEdit',
                    component: NewtalkElectionGroupEdit
                },
                {
                    path: '/Newtalk/ElectionPersonList',
                    name: 'NewtalkElectionPersonList',
                    component: NewtalkElectionPersonList
                },
                {
                    path: '/Newtalk/MemberMute',
                    name: 'MemberMute',
                    component: MemberMute
                },
                {
                    path: '/Newtalk/ImagesList',
                    name: 'ImagesList',
                    component: ImagesList
                },
                {
                    path: '/Newtalk/ImagesEdit',
                    name: 'ImagesEdit',
                    component: ImagesEdit
                },
                {
                    path: '/Newtalk/Survey',
                    name: 'NewtalkSurvey',
                    component: NewtalkSurvey
                },
                //FC
                {
                    path: '/FC/VersionSettings',
                    name: 'VersionSettings',
                    component: VersionSettings
                },
                {
                    path: '/FC/CustomerService',
                    name: 'CustomerService',
                    component: CustomerService
                },
                {
                    path: '/FC/CustomerServiceEdit/:type?/:id?',
                    name: 'CustomerServiceEdit',
                    component: CustomerServiceEdit,
                    props: true,
                },
                {
                    path: '/FC/TopupRecordList',
                    name: 'TopupRecordList',
                    component: TopupRecordList
                },
                {
                    path: '/FC/TopupRecordEdit/:id?',
                    name: 'TopupRecordEdit',
                    component: TopupRecordEdit
                },
                {
                    path: '/FC/RewardMessage',
                    name: 'RewardMessage',
                    component: RewardMessage
                },
                {
                    path: '/FC/RewardMessageHistory/:id?',
                    name: 'RewardMessageHistory',
                    component: RewardMessageHistory,
                    props: true,
                },
                {
                    path: '/FC/RewardMessageEdit/:id?',
                    name: 'RewardMessageEdit',
                    component: RewardMessageEdit,
                    props: true,
                },
                {
                    path: '/FC/PlayerDataList',
                    name: 'PlayerDataList',
                    component: PlayerDataList
                },
                {
                    path: '/FC/PlayerHistoryList/:id?',
                    name: 'PlayerHistoryList',
                    component: PlayerHistoryList
                },
                {
                    path: '/FC/PlayerSettingList',
                    name: 'PlayerSettingList',
                    component: PlayerSettingList
                },
                {
                    path: '/FC/AnnouncementList',
                    name: 'FcAnnouncementList',
                    component: FcAnnouncementList
                },
                {
                    path: '/FC/AnnouncementEdit/:type?/:id?',
                    name: 'FcAnnouncementEdit',
                    component: FcAnnouncementEdit,
                    props: true,
                },
                // NMJ
                {
                    path: '/NMJ/ClassTypeManager', // 分類管理
                    name: 'NmjClassTypeManager',
                    component: NmjClassTypeManager,
                },
                {
                    path: '/NMJ/ServiceTypeManager', // 分類管理
                    name: 'NmjServiceTypeManager',
                    component: NmjServiceTypeManager,
                },
                {
                    path: '/NMJ/CustomerService',
                    name: 'NmjCustomerService',
                    component: NmjCustomerService
                },
                {
                    path: '/NMJ/CustomerServiceEdit/:type?/:id?',
                    name: 'NmjCustomerServiceEdit',
                    component: NmjCustomerServiceEdit,
                    props: true,
                },
                {
                    path: '/NMJ/AnnouncementList',
                    name: 'NmjAnnouncementList',
                    component: NmjAnnouncementList
                },
                {
                    path: '/NMJ/AnnouncementEdit/:type?/:id?',
                    name: 'NmjAnnouncementEdit',
                    component: NmjAnnouncementEdit,
                    props: true,
                },
                {
                    path: '/NMJ/AppBannerList',
                    name: 'NmjAppBannerList',
                    component: NmjAppBannerList
                },
                {
                    path: '/NMJ/AppBannerEdit/:type?/:id?',
                    name: 'NmjAppBannerEdit',
                    component: NmjAppBannerEdit,
                    props: true,
                },
                {
                    path: '/NMJ/Marquee',
                    name: 'NmjMarquee',
                    component: NmjMarquee
                },
                {
                    path: '/NMJ/MarqueeEdit/:type?/:id?',
                    name: 'NmjMarqueeEdit',
                    component: NmjMarqueeEdit,
                    props: true,
                },
                {
                    path: '/NMJ/Notification',
                    name: 'NmjNotification',
                    component: NmjNotification
                },
                {
                    path: '/NMJ/NotificationEdit/:type?/:id?',
                    name: 'NmjNotificationEdit',
                    component: NmjNotificationEdit,
                    props: true,
                },
                {
                    path: '/NMJ/WebBanner',
                    name: 'NmjWebBanner',
                    component: NmjWebBanner,
                },
                {
                    path: '/NMJ/WebBannerEdit/:type?/:id?',
                    name: 'NmjWebBannerEdit',
                    component: NmjWebBannerEdit,
                    props: true,
                },
                {
                    path: '/NMJ/WebNews',
                    name: 'NmjWebNews',
                    component: NmjWebNews,
                },
                {
                    path: '/NMJ/WebNewsEdit/:type?/:id?',
                    name: 'NmjWebNewsEdit',
                    component: NmjWebNewsEdit,
                    props: true,
                },
                {
                    path: '/NMJ/WebQuestion',
                    name: 'NmjWebQuestion',
                    component: NmjWebQuestion,
                },
                {
                    path: '/NMJ/WebHighlight',
                    name: 'NmjWebHighlight',
                    component: NmjWebHighlight,
                },
                {
                    path: '/NMJ/RewardMessage',
                    name: 'NmjRewardMessage',
                    component: NmjRewardMessage
                },
                {
                    path: '/NMJ/RewardMessageHistory/:id?',
                    name: 'NmjRewardMessageHistory',
                    component: NmjRewardMessageHistory,
                    props: true,
                },
                {
                    path: '/NMJ/RewardMessageEdit/:id?',
                    name: 'NmjRewardMessageEdit',
                    component: NmjRewardMessageEdit,
                    props: true,
                },
                {
                    path: '/NMJ/PlayerData',
                    name: 'NmjPlayerData',
                    component: NmjPlayerData,
                },
                {
                    path: '/NMJ/PlayerSetting',
                    name: 'NmjPlayerSetting',
                    component: NmjPlayerSetting,
                },
                {
                    path: '/NMJ/PlayerHistory',
                    name: 'NmjPlayerHistory',
                    component: NmjPlayerHistory,
                },
                {
                    path: '/NMJ/PlayerDeposit',
                    name: 'NmjPlayerDeposit',
                    component: NmjPlayerDeposit
                },
                {
                    path: '/NMJ/GameRecord',
                    name: 'NmjGameRecord',
                    component: NmjGameRecord,
                },
                {
                    path: '/NMJ/GameBattle',
                    name: 'NmjGameBattle',
                    component: NmjGameBattle,
                },
                {
                    path: '/NMJ/GameBet/:entry?/:event?',
                    name: 'NmjGameBet',
                    component: NmjGameBet,
                    props: true,
                },
                {
                    path: '/NMJ/GameBetList',
                    name: 'NmjGameBetList',
                    component: NmjGameBetList,
                },
                {
                    path: '/NMJ/History',
                    name: 'NmjHistory',
                    component: NmjHistory
                },
                // ApexWin_H5 
                {
                    path: '/AWH5/ClassManager/:module?',
                    name: 'AWH5ClassManager',
                    component: AWH5ClassManager,
                    props: true,
                },
                {
                    path: '/AWH5/GameIntro',
                    name: 'AWH5GameIntroList',
                    component: AWH5GameIntroList,
                },
                {
                    path: '/AWH5/GameIntro/:id?',
                    name: 'AWH5GameIntroEdit',
                    component: AWH5GameIntroEdit,
                    props: true,
                },
                {
                    path: '/AWH5/GameOperation',
                    name: 'AWH5GameOperationList',
                    component: AWH5GameOperationList,
                },
                {
                    path: '/AWH5/GameOperation/:id?',
                    name: 'AWH5GameOperationEdit',
                    component: AWH5GameOperationEdit,
                    props: true,
                },
                {
                    path: '/AWH5/WebHome/:tab?',
                    name: 'AWH5WebHome',
                    component: AWH5WebHome,
                    props: true,
                },
                {
                    path: '/AWH5/WebGame',
                    name: 'AWH5WebGame',
                    component: AWH5WebGame,
                    // props: true,
                },
                {
                    path: '/AWH5/WebGame/:id?',
                    name: 'AWH5WebGameEdit',
                    component: AWH5WebGameEdit,
                    props: true,
                },
                {
                    path: '/AWH5/WebNews',
                    name: 'AWH5WebNews',
                    component: AWH5WebNews,
                },
                {
                    path: '/AWH5/WebNews/:id?',
                    name: 'AWH5WebNewsEdit',
                    component: AWH5WebNewsEdit,
                    props: true,
                },

                //XinStar_Web
                {
                    path: '/XSW/TypeManager/:type?',
                    name: 'XswTypeManager',
                    component: XswTypeManager,
                    props: true,
                },
                {
                    path: '/XSW/MemberData/:name?',
                    name: 'XswMemberData',
                    component: XswMemberData,
                    props: true,
                },
                {
                    path: '/XSW/MemberDelete/:id?',
                    name: 'XswMemberDelete',
                    component: XswMemberDelete,
                    props: true,
                },
                {
                    path: '/XSW/MemberRebate',
                    name: 'XswMemberRebate',
                    component: XswMemberRebate,
                },
                {
                    path: '/XSW/GuildData',
                    name: 'XswGuildData',
                    component: XswGuildData,
                },
                {
                    path: '/XSW/GuildData/:id?',
                    name: 'XswGuildDetail',
                    component: XswGuildDetail,
                    props: true,
                },
                {
                    path: '/XSW/GameHistory',
                    name: 'XswGameHistory',
                    component: XswGameHistory,
                },
                {
                    path: '/XSW/GameMessage',
                    name: 'XswGameMessage',
                    component: XswGameMessage,
                },
                {
                    path: '/XSW/GameMessageEdit',
                    name: 'XswGameMessageEdit',
                    component: XswGameMessageEdit,
                },
                {
                    path: '/XSW/History',
                    name: 'XswHistory',
                    component: XswHistory
                },
                {
                    path: '/XSW/Serial',
                    name: 'XswSerial',
                    component: XswSerial
                },
                {
                    path: '/XSW/Deposit',
                    name: 'XswDeposit',
                    component: XswDeposit
                },
                
                // XinStar_Global
                {
                    path: '/XSG/Dashboard',
                    name: 'XsgDashboard',
                    component: XsgDashboard,
                },
                {
                    path: '/XSG/Member/:id?',
                    name: 'XsgMember',
                    component: XsgMember,
                    props: true,
                },
                {
                    path: '/XSG/WebGame/',
                    name: 'XsgWebGame',
                    component: XsgWebGame,
                },
                {
                    path: '/XSG/WebGame/:id?',
                    name: 'XsgWebGameEdit',
                    component: XsgWebGameEdit,
                    props: true,
                },
                {
                    path: '/XSG/WebGameTag',
                    name: 'XsgWebGameTag',
                    component: XsgWebGameTag,
                },
                {
                    path: '/XSG/WebBanner/:id?',
                    name: 'XsgWebBanner',
                    component: XsgWebBanner,
                    props: true,
                },
                {
                    path: '/XSG/WebNews/:id?',
                    name: 'XsgWebNews',
                    component: XsgWebNews,
                    props: true,
                },
                {
                    path: '/XSG/GameBanner',
                    name: 'XsgGameBanner',
                    component: XsgGameBanner,
                },
                {
                    path: '/XSG/GameHistory',
                    name: 'XsgGameHistory',
                    component: XsgGameHistory,
                    props: true,
                },
                {
                    path: '/XSG/GameMessage',
                    name: 'XsgGameMessage',
                    component: XsgGameMessage,
                },
                {
                    path: '/XSG/GameMessageEdit',
                    name: 'XsgGameMessageEdit',
                    component: XsgGameMessageEdit,
                },
                {
                    path: '/XSG/GameBanner/:id?',
                    name: 'XsgGameBannerEdit',
                    component: XsgGameBannerEdit,
                    props: true,
                },
                {
                    path: '/XSG/WebBanner/:id?',
                    name: 'XsgWebBannerEdit',
                    component: XsgWebBannerEdit,
                    props: true,
                },
                {
                    path: '/XSG/GameAnnouncement/:id?',
                    name: 'XsgGameAnnouncement',
                    component: XsgGameAnnouncement,
                    props: true,
                },
                {
                    path: '/XSG/Payment/:platform?',
                    name: 'XsgPayment',
                    component: XsgPayment,
                    props: true,
                },
                {
                    path: '/XSG/Serial/:id?',
                    name: 'XsgSerial',
                    component: XsgSerial,
                    props: true,
                },
                {
                    path: '/XSG/GuildData',
                    name: 'XsgGuildData',
                    component: XsgGuildData,
                },
                {
                    path: '/XSG/GuildReview',
                    name: 'XsgGuildReview',
                    component: XsgGuildReview,
                },
                //Spider
                {
                    path: '/Spider/GroupSetList',
                    name: 'SpiderGroupSetList',
                    component: SpiderGroupSetList
                },
                {
                    path: '/Spider/GroupSetEdit',
                    name: 'SpiderGroupSetEdit',
                    component: SpiderGroupSetEdit
                },
                {
                    path: '/Spider/BigData',
                    name: 'SpiderBigDataList',
                    component: SpiderBigDataList
                },
                {
                    path: '/Spider/BigDataEdit/:id?/:back?',
                    name: 'SpiderBigDataEdit',
                    component: SpiderBigDataEdit
                },
                {
                    path: '/Spider/BigDataKOL/:id?/:back?',
                    name: 'SpiderBigDataKOL',
                    component: SpiderBigDataKOL
                },
                {
                    path: '/Spider/BigDataPost/:id?/:post_id?/:back?',
                    name: 'SpiderBigDataPost',
                    component: SpiderBigDataPost
                },
                {
                    path: '/Spider/SubManagement',
                    name: 'SpiderSubManagement',
                    component: SpiderSubManagement
                },
                {
                    path: '/Spider/KOLLeaderboard',
                    name: 'KOLLeaderboard',
                    component: KOLLeaderboard,
                },
                {
                    path: '/Spider/CollectTypeManager',
                    name: 'CollectTypeManager',
                    component: CollectTypeManager
                },
                //MFTD
                {
                    path: '/MFTD/BeforeLoginList',
                    name: 'BeforeLoginList',
                    component: BeforeLoginList
                },
                {
                    path: '/MFTD/BeforeShareList',
                    name: 'BeforeShareList',
                    component: BeforeShareList
                },
                {
                    path: '/MFTD/ContactUsList',
                    name: 'MftdContactUsList',
                    component: MftdContactUsList
                },
                {
                    path: '/MFTD/ContactUsEdit/:id?',
                    name: 'MftdContactUsEdit',
                    component: MftdContactUsEdit
                },
                {
                    path: '/MFTD/LotteryList',
                    name: 'MftdLotteryList',
                    component: MftdLotteryList
                },
                //StarCastle
                {
                    path: '/StarCastle/BeforeLoginList',
                    name: 'StarCastleBeforeLoginList',
                    component: StarCastleBeforeLoginList
                },
                {
                    path: '/StarCastle/BeforeShareList',
                    name: 'StarCastleBeforeShareList',
                    component: StarCastleBeforeShareList
                },
                {
                    path: '/StarCastle/BeforePrizeList',
                    name: 'StarCastleBeforePrizeList',
                    component: StarCastleBeforePrizeList
                },
                //Heti
                {
                    path: '/heti/PaymentOrderList',
                    name: 'HetiPaymentOrderList',
                    component: HetiPaymentOrderList,
                    props: true,
                },
                {
                    path: '/heti/SpecialOffer',
                    name: 'HetiSpecialOffer',
                    component: AnnouncementList,
                    props: true,
                },
                {
                    path: '/heti/SpecialOfferEdit/:type?/:id?',
                    name: 'HetiSpecialOfferEdit',
                    component: AnnouncementEdit,
                    props: true,
                },
                {
                    path: '/heti/Video',
                    name: 'HetiVideo',
                    component: HetiVideo,
                    props: true,
                },
                {
                    path: '/heti/Attractions',
                    name: 'HetiAttractions',
                    component: HetiAttractions,
                    props: true,
                },
                {
                    path: '/heti/AttractionsEdit',
                    name: 'HetiAttractionsEdit',
                    component: HetiAttractionsEdit,
                    props: true,
                },
                //ips
                {
                    path: '/ips/PaymentOwedList',
                    name: 'PaymentOwedList',
                    component: PaymentOwedList
                },
                {
                    path: '/ips/PaymentOwedEdit/:id?',
                    name: 'PaymentOwedEdit',
                    component: PaymentOwedEdit,
                },
                {
                    path: '/ips/IPSSettingList',
                    name: 'IPSSettingList',
                    component: IPSSettingList
                },
                {
                    path: '/ips/IPSSettingEdit/:ipsId?',
                    name: 'IPSSettingEdit',
                    component: IPSSettingEdit,
                },
                {
                    path: '/ips/IPSSettingAccountList/:ipsId?',
                    name: 'IPSSettingAccountList',
                    component: IPSSettingAccountList,
                },
                {
                    path: '/ips/IPSSettingAccountEdit/:ipsId?/:id?',
                    name: 'IPSSettingAccountEdit',
                    component: IPSSettingAccountEdit,
                },
                {
                    path: '/ips/IPSSettingPayList/:ipsId?',
                    name: 'IPSSettingPayList',
                    component: IPSSettingPayList,
                },
                {
                    path: '/ips/IPSSettingPayEdit/:ipsId?/:id?',
                    name: 'IPSSettingPayEdit',
                    component: IPSSettingPayEdit,
                },
                {
                    path: '/ips/FirmSettingList/:id?',
                    name: 'FirmSettingList',
                    component: FirmSettingList
                },
                {
                    path: '/ips/FirmSettingEdit/:firmId?',
                    name: 'FirmSettingEdit',
                    component: FirmSettingEdit,
                },
                {
                    path: '/ips/FirmPaySettingList/:firmId?',
                    name: 'FirmPaySettingList',
                    component: FirmPaySettingList,
                },
                {
                    path: '/ips/FirmPaySettingEdit/:firmId?/:firmPayId?',
                    name: 'FirmPaySettingEdit',
                    component: FirmPaySettingEdit,
                },
                {
                    path: '/ips/PayFlowMaintainList/:id?',
                    name: 'PayFlowMaintainList',
                    component: PayFlowMaintainList
                },
                {
                    path: '/ips/TransactionInfoList/:id?',
                    name: 'TransactionInfoList',
                    component: TransactionInfoList
                },
                {
                    path: '/ips/DisputedOrderRecords',
                    name: 'DisputedOrderRecords',
                    component: DisputedOrderRecords
                },
                {
                    path: '/ips/GoodsManagerList',
                    name: 'GoodsManagerList',
                    component: GoodsManagerList
                },
                {
                    path: '/ips/GoodsManagerEdit/:goodId?',
                    name: 'GoodsManagerEdit',
                    component: GoodsManagerEdit,
                },
                {
                    path: '/ips/FirmPayFlowList',
                    name: 'FirmPayFlowList',
                    component: FirmPayFlowList
                },
                {
                    path: '/ips/FirmPayFlowEdit/:firmPayFlowId?',
                    name: 'FirmPayFlowEdit',
                    component: FirmPayFlowEdit,
                },
                {
                    path: '/ips/HistoryManage',
                    name: 'HistoryManage',
                    component: HistoryManage
                },
                {
                    path: '/ips/RefundSearchList',
                    name: 'RefundSearchList',
                    component: RefundSearchList
                },
                {
                    path: '/ips/Invoice',
                    name: 'Invoice',
                    component: Invoice
                },
                {
                    path: '/ips/InvoiceElectronicProcess',
                    name: 'InvoiceElectronicProcess',
                    component: InvoiceElectronicProcess
                },
                {
                    path: '/ips/Invoice/InvoiceMaintain',
                    name: 'InvoiceMaintain',
                    component: InvoiceMaintain
                },
                {
                    path: '/ips/Product/ProductList',
                    name: 'ProductList',
                    component: ProductList
                },
                {
                    path: '/ips/Risk/RiskWhiteList',
                    name: 'RiskWhiteList',
                    component: RiskWhiteList//() => import("@/views/projects/ips/Risk/RiskWhiteList")
                },
                {
                    path: '/ips/Risk/RiskLogList',
                    name: 'RiskLogList',
                    component: RiskLogList// () => import("@/views/projects/ips/Risk/RiskLogList")
                },
                {
                    path: '/ips/Risk/PayLimitList',
                    name: 'PayLimitList',
                    component: PayLimitList// () => import("@/views/projects/ips/Risk/PayLimitList")
                },
                {
                    path: '/ips/FMS/FMSPage',
                    name: 'FMSPage',
                    component: FMSPage
                },
                //xin  
                {
                    path: '/xin/currentAccount/',
                    name: 'XinCurrentAccount',
                    component: XinCurrentAccount
                },
                {
                    path: '/Xin/PlatformBuyDel',
                    name: 'XinPlatformBuyDel',
                    component: XinPlatformBuyDel
                },
                {
                    path: '/Xin/GameIntroList',
                    name: 'XinGameIntroList',
                    component: XinGameIntroList
                },
                {
                    path: '/Xin/GameIntroEdit/:id?',
                    name: 'XinGameIntroEdit',
                    component: XinGameIntroEdit
                },
                ,
                {
                    path: '/Xin/GameOperationList',
                    name: 'XinGameOperationList',
                    component: XinGameOperationList
                },
                {
                    path: '/Xin/GameOperationEdit/:id?',
                    name: 'XinGameOperationEdit',
                    component: XinGameOperationEdit
                },

                {
                    path: '/Xin/VideoList',
                    name: 'XinVideoList',
                    component: XinVideoList
                },

                // wanin
                {
                    path: '/wanin/AdvertisementList', // 廣告管理
                    name: 'WaninAdvertisementList',
                    component: WaninAdvertisementList
                },
                {
                    path: '/wanin/AdvertisementEdit/:id?', // 廣告編輯
                    name: 'WaninAdvertisementEdit',
                    component: WaninAdvertisementEdit
                },
                {
                    path: '/wanin/AnnouncementList', // ESG管理
                    name: 'WaninAnnouncementList',
                    component: WaninAnnouncementList
                },
                {
                    path: '/wanin/AnnouncementEdit/:id?', // ESG編輯
                    name: 'WaninAnnouncementEdit',
                    component: WaninAnnouncementEdit
                },
                //FB Robot
                {
                    path: '/FBRobot/RobotList/:fanPage?',
                    name: 'FBRobotList',
                    component: FBRobotList
                },
                {
                    path: '/FBRobot/PostKeywordList/:fanPage?/:id?/:back?',
                    name: 'FBPostKeywordList',
                    component: FBPostKeywordList
                },
                {
                    path: '/FBRobot/Keywords/:fanPage?/:id?/:key_id?/:back?',
                    name: 'FBKeywords',
                    component: FBKeywords
                },
                // {
                //     path: '/FBRobot/PostKeywordList/:id?/:back?',
                //     name: 'FBPostKeywordList',
                //     component: FBPostKeywordList
                // },
                // {
                //     path: '/FBRobot/Keywords/:id?/:key_id?/:back?',
                //     name: 'FBKeywords',
                //     component: FBKeywords
                // },
                {
                    path: '/FBRobot/FanPage',
                    name: 'FBFanPage',
                    component: FBFanPage
                },

                //KF3
                {
                    path: '/KF3/BeforeLoginList',
                    name: 'KF3BeforeLoginList',
                    component: KF3BeforeLoginList
                },
                {
                    path: '/KF3/BeforeShareList',
                    name: 'KF3BeforeShareList',
                    component: KF3BeforeShareList
                },
                {
                    path: '/KF3/ArtCreationList',
                    name: 'ArtCreationList',
                    component: ArtCreationList,
                    props: true,
                },
                {
                    path: '/KF3/ArtCreationEditing/:id?',
                    name: 'ArtCreationEditing',
                    component: ArtCreationEditing
                },
                {
                    path: '/KF3/TopUp',
                    name: 'TopUp',
                    component: TopUp
                },
                {
                    path: '/KF3/Maintain',
                    name: 'KF3Maintain',
                    component: KF3Maintain
                },
                {
                    path: '/KF3/Correspond',
                    name: 'Correspond',
                    component: Correspond
                },
                {
                    path: '/KF3/WinnerList',
                    name: 'WinnerList',
                    component: WinnerList
                },
                //客服系統
                {
                    path: '/MessageSystem/MessageSystem',
                    name: 'MessageSystem',
                    component: MessageSystem
                },
                {
                    path: '/MessageSystem/GroupSystem',
                    name: 'GroupSystem',
                    component: GroupSystem
                },
                {
                    path: '/FBRobot/MessageClassTypeManager',
                    name: 'MessageClassTypeManager',
                    component: MessageClassTypeManager
                },
                {
                    path: '/FBRobot/MessageCanMsgManager',
                    name: 'MessageCanMsgManager',
                    component: MessageCanMsgManager
                },
                {
                    path: '/FBRobot/MessageCanMsgManager/:isCustomize',
                    name: 'MessageCustomizeCanMsgManager',
                    component: MessageCanMsgManager
                },
                {
                    path: '/MessageSystem/MessageSocialManager',
                    name: 'MessageSocialManager',
                    component: MessageSocialManager
                },
                {
                    path: '/MessageSystem/MessageSocialDetailManager/:PlatformType?',
                    name: 'MessageSocialDetailManager',
                    component: MessageSocialDetailManager,
                },
                {
                    path: '/MessageSystem/MessageStatistics',
                    name: 'MessageStatistics',
                    component: MessageStatistics
                },
                {
                    path: '/MessageSystem/CustomerServiceLog',
                    name: 'CustomerServiceLog',
                    component: CustomerServiceLog
                },
                {
                    path: '/MessageSystem/ServerManager',
                    name: 'ServerManager',
                    component: ServerManager
                },
                //監頻
                {
                    path: '/ChannelMonitor/ChannelManager',
                    name: 'ChannelManager',
                    component: ChannelManager
                },
                {
                    path: '/ChannelMonitor/KeyWordManager',
                    name: 'KeyWordManager',
                    component: KeyWordManager
                },
                {
                    path: '/ChannelMonitor/CommunicationLog',
                    name: 'CommunicationLog',
                    component: CommunicationLog
                },
                //社群監控
                {
                    path: "/smm/AnnouncementList", // 公告列表
                    name: "smmAnnouncementList",
                    component: smmAnnouncementList,
                    props: true
                },
                {
                    path: "/smm/AnnouncementEdit/:id?", // 公告編輯
                    name: "smmAnnouncementEdit",
                    component: smmAnnouncementEdit,
                    props: true
                },
                {
                    path: "/smm/VideoList/:id?", // 影片列表
                    name: "smmVideoList",
                    component: smmVideoList,
                    props: true
                },
                {
                    path: "/smm/Videotrace", // 影片列表
                    name: "smmVideotrace",
                    component: smmVideotrace,
                    props: true
                },
                {
                    path: "/smm/Msgtrace", // 留言列表
                    name: "smmMsgtrace",
                    component: smmMsgtrace,
                    props: true
                },
                {
                    path: "/smm/Keywords", // 關鍵字
                    name: "smmKeywords",
                    component: smmKeywords,
                    props: true
                },
                {
                    path: "/smm/WarnKeywords", // 違規關鍵字
                    name: "smmWarnKeywords",
                    component: smmWarnKeywords,
                    props: true
                },
                //樂美館
                {
                    path: "/xac/AnnouncementList", // 公告列表
                    name: "XacAnnouncementList",
                    component: XacAnnouncementList,
                    props: true
                },
                {
                    path: "/xac/AnnouncementEdit/:id?", // 公告編輯
                    name: "XacAnnouncementEdit",
                    component: XacAnnouncementEdit,
                    props: true
                },
                {
                    path: '/xac/PartnerList',
                    name: 'XacPartnerList',
                    component: XacPartnerList
                },
                {
                    path: '/xac/PartnerEdit',
                    name: 'XacPartnerEdit',
                    component: XacPartnerEdit
                },
                {
                    path: '/xac/PartnerListGame',
                    name: 'XacGamePartnerList',
                    component: XacGamePartnerList
                },
                {
                    path: '/xac/PartnerEditGame',
                    name: 'XacGamePartnerEdit',
                    component: XacGamePartnerEdit
                },
                {
                    path: '/xac/ServiceList',
                    name: 'XacServiceList',
                    component: XacServiceList
                },
                {
                    path: '/xac/ServiceEdit',
                    name: 'XacServiceEdit',
                    component: XacServiceEdit
                },
                {
                    path: '/xac/AlbumList',
                    name: 'XacAlbumList',
                    component: XacAlbumList,
                    props: true,
                },
                {
                    path: '/xac/AlbumEditing',
                    name: 'XacAlbumEditing',
                    component: XacAlbumEditing,
                },
                {
                    path: '/xac/CarouselList',
                    name: 'XacCarouselList',
                    component: XacCarouselList,
                    props: true,
                },
                {
                    path: '/xac/CarouselEdit',
                    name: 'XacCarouselEdit',
                    component: XacCarouselEdit,
                },
                // 簡訊平台
                {
                    path: '/SMS/Provider',
                    name: 'SMSProvider',
                    component: SMSProvider
                },
                {
                    path: '/SMS/Account',
                    name: 'SMSAccount',
                    component: SMSAccount
                },
                {
                    path: '/SMS/AccountEdit/:id?',
                    name: 'SMSAccountEdit',
                    component: SMSAccountEdit
                },
                {
                    path: '/SMS/Setting',
                    name: 'SMSSetting',
                    component: SMSSetting
                },
                {
                    path: '/SMS/State',
                    name: 'SMSState',
                    component: SMSState
                },
                {
                    path: '/SMS/Details',
                    name: 'SMSDetails',
                    component: SMSDetails
                },
                {
                    path: '/SMS/Customize',
                    name: 'SMSCustomize',
                    component: SMSCustomize
                },
                {
                    path: '/SMS/CustomizeEdit/:id?',
                    name: 'SMSCustomizeEdit',
                    component: SMSCustomizeEdit
                },
                {
                    path: '/SMS/CustomizeDetail/:id?',
                    name: 'SMSCustomizeDetail',
                    component: SMSCustomizeDetail
                },
                {
                    path: '/SMS/BlockList',
                    name: 'SMSBlockList',
                    component: SMSBlockList
                },
                {
                    path: '/SMS/Message',
                    name: 'SMSMessage',
                    component: SMSMessage
                },
                {
                    path: '/SMS/Group',
                    name: 'SMSGroup',
                    component: SMSGroup
                },
                {
                    path: '/SMS/DeliveryRate',
                    name: 'SMSDeliveryRate',
                    component: SMSDeliveryRate
                },
                // slot
                {
                    path: '/slot/client/GameList',
                    name: 'slotClientGameList',
                    component: slotClientGameList
                },
                {
                    path: '/slot/client/GameHallList/:id?/:back?/:search?',
                    name: 'slotClientGameHallList',
                    component: slotClientGameHallList
                },
                {
                    path: '/slot/client/GameActivity/:id?',
                    name: 'slotClientGameActivity',
                    component: slotClientGameActivity
                },
                {
                    path: '/slot/client/GameContent/:gameId?/:id?/:back?/:search?',
                    name: 'clientGameContent',
                    component: clientGameContent
                },
                {
                    path: '/slot/AnalyzeList/:id?',
                    name: 'slotAnalyzeList',
                    component: slotAnalyzeList
                },
                {
                    path: '/slot/Analyze/:id?',
                    name: 'slotAnalyze',
                    component: slotAnalyze
                },
                {
                    path: '/slot/CompanyList',
                    name: 'slotCompanyList',
                    component: slotCompanyList
                },
                {
                    path: '/slot/GameCategory',
                    name: 'slotGameCategory',
                    component: slotGameCategory
                },
                {
                    path: '/slot/GameList',
                    name: 'slotGameList',
                    component: slotGameList
                },
                {
                    path: '/slot/GamehallList',
                    name: 'slotGamehallList',
                    component: slotGamehallList
                },
                {
                    path: '/slot/GameInfo/:id?',
                    name: 'slotGameInfo',
                    component: slotGameInfo
                },
                {
                    path: '/slot/GameContent/:GameId?/:id?/:name?',
                    name: 'slotGameContent',
                    component: slotGameContent
                },
                {
                    path: '/slot/GameContentEdit/:GameId?/:GameHallId?/:id?/:name',
                    name: 'slotGameContentEdit',
                    component: slotGameContentEdit
                },
                {
                    path: '/slot/Activity',
                    name: 'slotActivity',
                    component: slotActivity
                },
                {
                    path: '/slot/ActivityList/:type?/:id?/:name?',
                    name: 'slotActivityList',
                    component: slotActivityList
                },
                {
                    path: '/slot/ActivityEdit/:type?/:gameId?/:name?/:id?',
                    name: 'slotActivityEdit',
                    component: slotActivityEdit
                },
                {
                    path: '/slot/ActivityItem/:id?/:name?',
                    name: 'slotActivityItem',
                    component: slotActivityItem
                },
                {
                    path: '/slot/ActivityItemSet/:id?/:name?',
                    name: 'slotActivityItemSet',
                    component: slotActivityItemSet
                },
                {
                    path: '/slot/ActivityItemSetEdit/:gameId?/:name?/:id?',
                    name: 'slotActivityItemSetEdit',
                    component: slotActivityItemSetEdit
                },
                {
                    path: '/slot/ActivityItemControl/:gameId?/:name?/:id?',
                    name: 'slotActivityItemControl',
                    component: slotActivityItemControl
                },
                {
                    path: '/slot/PlayCategory',
                    name: 'slotPlayCategory',
                    component: slotPlayCategory
                },
                {
                    path: '/slot/PlayList',
                    name: 'slotPlayList',
                    component: slotPlayList
                },
                {
                    path: '/slot/PlayEdit/:id?',
                    name: 'slotPlayEdit',
                    component: slotPlayEdit
                },
                {
                    path: '/slot/ArticleCategory',
                    name: 'slotArticleCategory',
                    component: slotArticleCategory
                },
                {
                    path: '/slot/ArticleList',
                    name: 'slotArticleList',
                    component: slotArticleList
                },
                {
                    path: '/slot/ArticleEdit/:id?',
                    name: 'slotArticleEdit',
                    component: slotArticleEdit
                },
                {
                    path: '/slot/TagList',
                    name: 'slotTagList',
                    component: slotTagList
                },
                {
                    path: '/slot/Search/:keyword?',
                    name: 'slotSearch',
                    component: slotSearch
                },
                {
                    path: '/slot/SearchTag/:id?',
                    name: 'slotSearchTag',
                    component: slotSearchTag
                },
                {
                    path: '/MID/AnnouncementList',
                    name: 'MIDAnnouncementList',
                    component: MIDAnnouncementList
                },
                {
                    path: '/MID/AnnouncementEdit/:id?',
                    name: 'MIDAnnouncementEdit',
                    component: MIDAnnouncementEdit
                },
                {
                    path: '/MID/CertificationList',
                    name: 'MIDCertificationList',
                    component: MIDCertificationList
                },
                {
                    path: '/MID/CertificationDetailList/:memberNo?/:mobile?',
                    name: 'MIDCertificationDetailList',
                    component: MIDCertificationDetailList
                },
                //金筷子
                {
                    path: '/Pot/Member',
                    name: 'PotMember',
                    component: PotMember
                },
                // DEM
                {
                    path: '/DEM/BeforeLoginList',
                    name: 'DEMBeforeLoginList',
                    component: DEMBeforeLoginList
                },
                {
                    path: '/DEM/BeforeShareList',
                    name: 'DEMBeforeShareList',
                    component: DEMBeforeShareList
                },
                {
                    path: '/DEM/Gamer',
                    name: 'DEMGamer',
                    component: DEMGamer
                },
                {
                    path: '/DEM/Maintain',
                    name: 'DEMMaintain',
                    component: DEMMaintain
                },
            ],
        },
        {
            path: '/',
            redirect: '/Home',
        },
    ],
});
function checkUrlsPermission(to, urls) {
    let match = null;
    for (let i of to.matched) {
        if (i.name == to.name) {
            match = i; break;
        }
    }
    if (match) {
        let regex = match.regex;
        for (let url of urls) {
            if (url.match(regex)) {
                return true;
            }
        }
    }
    return false;
}
function checkPermission(to, from, loginUserMenu, projectId) {
    if (to.name == "Home") {
        return true;
    }
    try {
        let menu = loginUserMenu.filter(
            project => project.projectId == projectId
        )[0];
        //有定義在urls，回傳true
        if (checkUrlsPermission(to, menu.urls)) {
            return true;
        }
    } catch (e) {
        console.error(e);
    }
    return false;
}
rou.beforeEach(async (to, from, next) => {
    try {
        store.dispatch('setRouterAlive', false);
        if (to.name == 'Login') {
            //要到登入頁，直接到登入頁
            store.dispatch('setRouterAlive', true);
            next();
            return;
        }

        //檢查登入狀態
        let loginUser = store.state.loginUser;
        if (loginUser == '') {
            let check = await store.dispatch('loginCheck');
            if (!check) {
                //驗證失敗，轉到登入頁
                store.dispatch('setRouterAlive', true);
                next({ name: 'Login' });
                return;
            }
        }

        if (to.name == 'gotoProject' && to.params && to.params.projectId) {
            //切換專案
            await store.dispatch('switchProjectId', to.params.projectId);
            window.location.href = './';//有改變jwtToken的關系，用router切換到Home問題一堆，直接重整
        } else {
            //檢查專案權限
            let permission = true;//checkPermission(to, from, store.state.loginUserMenu, store.state.projectId);
            if (permission) {
                //進入指定頁面
                store.dispatch('setRouterAlive', true);
                next();
                return;
            } else {
                window.location.href = './';//有改變jwtToken的關系，用router切換到Home問題一堆，直接重整
            }
        }
    } catch (e) {
        alert("Router出錯，請提供以下誤訊息並通知RD修正：" + e.toString());
        window.location.href = './';
    }
});
// rou.afterEach((to, from) => {
//     loadLangByProject(store.state.projectId);
// })

export default rou;