<template>
    <div>
        <section class="content-header">
            <h1>{{ $t('交易明細') }}</h1>
            <ol class="breadcrumb">
                <li><router-link to="/">{{ $t('首頁') }}</router-link></li>
                <li class="active">{{ $t('交易明細') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <div class="form-group">
                            <div>
                                <label class="control-label">{{ $t('服務名稱') }}：</label>
                            </div>
                            <select class="selPaymentType form-control" v-model.trim="searchData.serviceID">
                                <option value="" disabled>{{ $t('請選擇服務名稱') }}</option>
                                <option v-for="item in serverList" :key="item.廠商編號" :value="item.廠商編號">{{ item.名稱 }}
                                </option>
                            </select>
                        </div>

                        <!-- 
                        <div class="form-group">
                            <label class="control-label">廠商：</label>
                            <select class="selPaymentType form-control" v-model="selFirm">
                                <option :value="item.value" v-for="item in firmList">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                        -->

                        <div class="form-group" :style="{width: /^en/.test($root.$options.i18n.locale) ? '320px':'250px'}">
                            <div class="d-flex justify-content-between">
                                <label class="control-label">{{ $t('金流') }}：</label>
                                <div class="d-flex justify-content-between">
                                    {{ $t('排除非購買訂單') }}：
                                    <div class="icheckbox_flat-green mr-5" :class="{ 'checked': searchData.IsExcludeNotPurchaseOrder }" aria-checked="1" aria-disabled="0" style="position: relative;">
                                        <input type="checkbox" class="flat-red w-100 h-100" style="position: absolute; opacity: 0;" v-model="searchData.IsExcludeNotPurchaseOrder">
                                    </div>
                                    <div>
                                        <span v-show="/^en/.test($root.$i18n.locale)" class="glyphicon glyphicon-info-sign" data-toggle="tooltip" data-placement="top" data-html="true"
                                            title="<div class='text-left'><i class='fa fa-fw fa-square'></i>Query all orders<br>
                                            <i class='fa fa-fw fa-check-square'></i>Exclude non-purchase order <br>(only when payment method is not specified)</div>">
                                        </span>
                                        <span v-show="!/^en/.test($root.$i18n.locale)" class="glyphicon glyphicon-info-sign" data-toggle="tooltip" data-placement="top" data-html="true"
                                            title="<div class='text-left'><i class='fa fa-fw fa-square'></i>查詢全部訂單<br>
                                            <i class='fa fa-fw fa-check-square'></i>排除非購買訂單 (僅限不指定交易方式查詢時有效)</div>">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <select  class=" form-control" v-model="searchData.paymentType">
                                <option value="">{{ $t('全部') }}</option>
                                <option v-for="item in IPSList" :key="item.金流代碼" :value="item.金流代碼">
                                    {{ item.金流名稱 }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <div>
                                <label class="control-label">{{ $t('訂單狀態') }}：</label>
                            </div>
                            <select class="selPaymentType form-control" v-model="searchData.orderStatus">
                                <option value="">{{ $t('全部') }}</option>
                                <option value="N">{{ 格式化訂單狀態('N') }}</option>
                                <option value="S">{{ 格式化訂單狀態('S') }}</option>
                                <option value="F">{{ 格式化訂單狀態('F') }}</option>
                                <option value="T">{{ 格式化訂單狀態('T') }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <div>
                                <label class="control-label">{{ $t('配點狀態') }}：</label>
                            </div>
                            <select class="selPaymentType form-control" v-model="searchData.givePointResult">
                                <option value="-2">{{ $t('全部') }}</option>
                                <option value="-1">{{ 格式化配點狀態(-1) }}</option>
                                <option value="0">{{ 格式化配點狀態(0) }}</option>
                                <option value="1">{{ 格式化配點狀態(1) }}</option>
                                <option value="2">{{ 格式化配點狀態(2) }}</option>
                            </select>
                        </div>
                        <br />
                        <div class="textAreas">
                            <textarea class="form-control" :title="$t('請輸入訂單編號')" :placeholder="$t('訂單編號')+'\n('+$t('多筆查詢用,或換行分隔')+')'"
                                v-model.trim="searchData.orderID"
                                :disabled="searchData.vendorOrderID != '' || searchData.upstreamVendorOrderID != ''"
                                @input="adjustHeight" />

                            <textarea class="form-control" :title="$t('請輸入金流訂單編號')"
                                :placeholder="$t('金流訂單編號')+'\n('+$t('多筆查詢用,或換行分隔')+')'" v-model.trim="searchData.vendorOrderID"
                                :disabled="searchData.orderID != '' || searchData.upstreamVendorOrderID != ''"
                                @input="adjustHeight" />

                            <textarea class="form-control" :title="$t('請輸入廠商訂單編號')"
                                :placeholder="$t('廠商訂單編號')+'\n('+$t('多筆查詢用,或換行分隔')+')'" v-model.trim="searchData.upstreamVendorOrderID"
                                :disabled="searchData.orderID != '' || searchData.vendorOrderID != '' || searchData.serviceID==10001"
                                @input="adjustHeight" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="selPaymentType form-control" :title="$t('玩家識別值')"
                                :placeholder="$t('玩家識別值')" v-model.trim="searchData.userId" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="selPaymentType form-control" :title="$t('平台識別值')"
                                :placeholder="$t('請輸入平台識別值')" v-model.trim="searchData.platformId" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="selPaymentType form-control" :title="$t('請輸入儲值類型')"
                                :placeholder="$t('請輸入儲值類型')" v-model.trim="searchData.paymentDetail" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="selPaymentType form-control" :title="$t('請輸入發票號碼')"
                                :placeholder="$t('請輸入發票號碼')" v-model.trim="searchData.InvoiceNumber" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="selPaymentType form-control" :title="$t('請輸入信用卡卡號')"
                                :placeholder="$t('請輸入信用卡卡號')" v-model.trim="searchData.creditCardNo"
                                :disabled="searchData.paymentType != 'ECPay'" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="selPaymentType form-control" :title="$t('請輸入商品代碼')"
                                :placeholder="$t('請輸入商品代碼')" v-model.trim="searchData.productCode"
                                :disabled="searchData.paymentType != 'ECPay'" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="selPaymentType form-control" :title="$t('請輸入虛擬帳號')"
                                :placeholder="$t('請輸入虛擬帳號')" v-model.trim="searchData.序號"
                                :disabled="searchData.paymentType != 'CCat'" />
                        </div>
                        <br />
                        <div class="form-group">
                            <label class="control-label">{{ $t('查詢日期') }}：</label>
                            <div class="input-group date">
                                <div class="input-group-addon"><i class="fa fa-calendar"></i></div>
                                <div class="pull-right">
                                    <DateRangePicker v-bind:propStartDate.sync="searchData.startTime"
                                        v-bind:propEndDate.sync="searchData.endTime" ref="dateRangePicker" />
                                </div>
                            </div>
                        </div>
                        <!--
                        <div class="form-group">
                            <input type="text" class="selPaymentType form-control" placeholder="請輸入遊戲暱稱"
                                v-model="searchData.遊戲暱稱" />
                        </div>
                        -->
                        <div class="form-group">
                            <button @click="onPageChange(1)" class="btn btn-primary" type="button">
                                {{ $t('查詢') }}
                            </button>
                        </div>
                        <div class="form-group">
                            <button @click="exportReportBtnClick" class="btn btn-success"
                                :class="{ disabled: !this.lastQueryCommand }" type="button">
                                {{ $t('匯出查詢結果') }}
                            </button>
                        </div>
                        <hr />
                        <div v-if="isTEST.isDev == 'development'">
                            <button class="btn btn-info mb-5" @click="isTEST.isOpen = !isTEST.isOpen">{{ $t('測試用') }}
                                ({{ $t('訂單修改狀態') }})</button>
                            <div class='d-flex' v-if="isTEST.isOpen">
                                <input type="text" class="selPaymentType form-control mr-5" width="200"
                                    :placeholder="$t('請輸入服務編號')" v-model.trim="isTEST.serviceID" />
                                <input type="text" class="selPaymentType form-control mr-5" width="200"
                                    :placeholder="$t('請輸入訂單編號')" v-model.trim="isTEST.orderID" style="width: 180px" />
                                <input type="text" class="selPaymentType form-control mr-5" width="200"
                                    :placeholder="$t('請輸入訂單狀態') + ' ex: F'" v-model.trim="isTEST.orderStatus"
                                    style="width: 180px" />
                                <button class='btn btn-danger' @click="transactionInfoUpdate('', isTEST)">
                                    {{ $t('修改訂單狀態') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered table-condensed table min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th>{{ $t('編號') }}</th>
                                    <th>{{ $t('使用者資訊') }}</th>
                                    <th>{{ $t('其他資訊') }}</th>
                                    <th style='width: 280px;'>{{ $t('金額') }}</th>
                                    <th>{{ $t('狀態') }}</th>
                                    <th>{{ $t('時間') }}</th>
                                    <!--
                                    <th class="text-nowrap">upstreamVendorOrderID</th>
                                    <th class="text-nowrap">備註</th>
                                    <th class="text-nowrap">玩家暱稱</th>
                                    <th class="text-nowrap">退貨YN</th>
                                    <th class="text-nowrap">訂單明細</th>
                                    <th class="text-nowrap">門號</th>
                                    <th class="text-nowrap">email</th>
                                    <th class="text-nowrap">發票類別</th>
                                    <th class="text-nowrap">發票號碼</th>
                                    <th class="text-nowrap">發票抬頭</th>
                                    <th class="text-nowrap">發票地址</th>
                                    <th class="text-nowrap">統一編號</th>
                                    <th class="text-nowrap">載具條碼</th>
                                    <th class="text-nowrap">愛心條碼</th>
                                    <th class="text-nowrap">載具類別</th>
                                    <th class="text-nowrap">防偽隨機碼</th>
                                    <th class="text-nowrap">收件姓名</th> -->
                                    <th v-if="$user.hasPermission('API_QueryOrderList_ShowCreditCardNum')">{{
                $t('信用卡卡號') }}</th>
                                    <th>{{ $t('功能') }}</th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="item in list" class="text-center" :key="item.訂單編號">
                                        <td>
                                            <div class='table-card-box text-left'>
                                                <div class='px-10'>
                                                    <b>{{ $t('訂單編號') }}：</b>{{ item.訂單編號 ? item.訂單編號 : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('金流訂單編號') }}：</b>{{ item.廠商訂單編號 ? item.廠商訂單編號 : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('廠商訂單編號') }}：</b>{{ item.上游廠商訂單編號 ? item.上游廠商訂單編號 : '-' }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class='table-card-box text-left'>
                                                <div class='px-10'>
                                                    <b>{{ $t('玩家暱稱') }}：</b>{{ item.玩家暱稱 ? item.玩家暱稱 : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('玩家識別值') }}：</b>{{ item.玩家識別值 ? item.玩家識別值 : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('平台識別值') }}：</b>{{ item.平台識別 ? item.平台識別 : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('使用者IP') }}：</b>{{ item.使用者IP ? item.使用者IP : '-' }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-card-box text-left">
                                                <div class="px-10">
                                                    <b>{{ $t('交易方式') }}：</b>{{ item.交易名稱 }}
                                                    <span class="text-red" v-if="item.交易名稱 === null">{{ item.交易方式
                                                        }}</span>
                                                </div>
                                                <div class="px-10">
                                                    <b>{{ $t('儲值類型') }}：</b>{{ item.儲值類型 ? item.儲值類型 : '-' }}
                                                </div>
                                                <div class="px-10">
                                                    <b>{{ $t('發票號碼') }}：</b>{{ item.發票號碼 ? item.發票號碼 : '-' }}
                                                </div>
                                                <div class="px-10">
                                                    <b>{{ $t('商品代碼') }}：</b>{{ 格式化商品代碼(item) }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class='table-card-box text-left'>
                                                <div class='px-10'>
                                                    <b>{{ $t('幣別') }}：</b>{{ item.幣別 }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('原始金額') }}：</b>
                                                    <span>
                                                        {{ item.原始金額 }}
                                                    </span> <br />

                                                    <button class='btn btn-danger' @click="item.是否調整訂單金額 = true"
                                                        v-if="((item.訂單狀態 == 'N' || item.訂單狀態 == 'F') && item.儲值類型 == 'CCatPay') && !item.是否調整訂單金額">
                                                        {{ $t('金額調整') }}
                                                    </button>
                                                    <div class='d-flex' style="min-width: 240px;" v-if="item.是否調整訂單金額">
                                                        <input class="form-control" type="number"
                                                            v-model="item.調整後訂單金額" />
                                                        <button class='btn btn-primary'
                                                            @click="transactionInfoUpdate(item)">
                                                            {{ $t('確認') }}
                                                        </button>
                                                        <button class='btn btn-danger' @click="item.是否調整訂單金額 = false">
                                                            {{ $t('取消') }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('交易金額') }}：</b>{{ item.交易金額 }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('配點金額') }}：</b>{{ item.配點金額 }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class='table-card-box text-left'>
                                                <div class='px-10'>
                                                    <b>{{ $t('訂單狀態') }}：</b>{{ 格式化訂單狀態(item.訂單狀態) }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('通知配點狀態') }}：</b>{{ 格式化配點狀態(item.通知配點狀態) }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('退貨YN') }}：</b>{{ 格式化退貨YN(item.退貨YN) }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class='table-card-box text-left'>
                                                <div class='px-10'>
                                                    <b>{{ $t('交易時間') }}：</b>{{ item.交易時間 ? $utils.formatTime(item.交易時間,
                "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss") : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('配點時間') }}：</b>{{ item.配點時間 ? $utils.formatTime(item.配點時間,
                "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss") : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{ $t('建立日期') }}：</b>{{ item.建立日期 ? $utils.formatTime(item.建立日期,
                "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss") : '-' }}
                                                </div>
                                            </div>
                                        </td>

                                        <!--
                                        <td class="text-center">{{ item.upstreamVendorOrderID }}</td>                                        
                                        <td class="text-center">{{ item.備註 }}</td>
                                        <td class="text-center">{{ item.玩家暱稱 }}</td>
                                        <td class="text-center">{{ item.退貨YN }}</td>
                                        <td class="text-center">{{ item.訂單明細 }}</td>
                                        <td class="text-center">{{ item.門號 }}</td>
                                        <td class="text-center">{{ item.email }}</td>
                                        <td class="text-center">{{ item.發票類別 }}</td>
                                        <td class="text-center">{{ item.發票號碼 }}</td>
                                        <td class="text-center">{{ item.發票抬頭 }}</td>
                                        <td class="text-center">{{ item.發票地址 }}</td>
                                        <td class="text-center">{{ item.統一編號 }}</td>
                                        <td class="text-center">{{ item.載具條碼 }}</td>
                                        <td class="text-center">{{ item.愛心條碼 }}</td>
                                        <td class="text-center">{{ item.載具類別 }}</td>
                                        <td class="text-center">{{ item.防偽隨機碼 }}</td>
                                        <td class="text-center">{{ item.收件姓名 }}</td> -->
                                        <td class="text-center"
                                            v-if="$user.hasPermission('API_QueryOrderList_ShowCreditCardNum')">{{
                item.信用卡卡號 }}
                                        </td>
                                        <td>
                                            <div style="display:flex; gap:3px; flex-wrap:wrap; justify-content:center;">
                                                <button v-if="/*item.退貨功能 &&*/ item.訂單狀態 == 'S' && !item.退貨YN" class="btn btn-danger"
                                                    @click="openRefundForm(item)">
                                                    {{ $t('退貨') }}
                                                </button>
                                                <button class="btn btn-success" @click="openDetailForm(item)">
                                                    {{ $t('明細') }}
                                                </button>
                                                <button v-show="item.交易方式 == 'CCat'" class="btn btn-warning"
                                                    @click="openCCatDetailForm(item)">
                                                    {{ $t('繳費資訊') }}
                                                </button>
                                                <button v-if="item.顯示重驗按鈕" class="btn btn-info"
                                                    @click="checkOrder(item)">
                                                    {{ $t('重驗證') }}
                                                </button>
                                                <!-- 重製訂單 -->
                                                <button class="btn btn-danger" v-if="item.訂單狀態 == 'F'"
                                                    @click="transactionInfoReset(item)">
                                                    {{ $t('訂單重置') }}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="20">{{ $t('沒有資料') }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer text-center no-border"
                    v-show="total > 0 && lastQueryCommand && lastQueryCommand.cmd && lastQueryCommand.cmd.MultipleWay == 0">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize"
                        v-on:pageMethod="onPageChange" />
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                    <div class="tip">{{ tip }}</div>
                </div>
            </div>
        </section>
        <RefoundForm ref="refoundForm" @更新退貨資料="queryAll(lastQueryCommand.cmd)"></RefoundForm>
        <DetailForm ref="detailForm"></DetailForm>

        <PopForm ref="addForm" class="popform">
            <template v-slot:slot-title>
                調整下載時間區間
            </template>
            <template v-slot:slot-body>
                <div class="form-group">
                    <div class="input-group date">
                        <div class="input-group-addon"><i class="fa fa-calendar"></i></div>
                        <div class="pull-right">
                            <DateRangePicker propsIn="format2" :propStartDate.sync="batchData.startTime"
                                :propEndDate.sync="batchData.endTime" ref="batchFormDateRangePicker" />
                        </div>
                    </div>
                    *最長一個月
                </div>
            </template>
            <template v-slot:slot-footer>
                <!-- <button class="btn" @click="$refs.addForm.hide();">關閉</button> -->
                <button class="btn btn-primary" @click="exportFormBtnClick()">下載</button>
            </template>
        </PopForm>
    </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import { IPSTool, APILib } from './IPSTool2'
import RefoundForm from "./TransactionInfoList/RefoundForm.vue";
import DetailForm from "./TransactionInfoList/DetailForm.vue";
import PopForm from "@/views/Template/PopFormTemplate";
function show重驗證按鈕(order) {
    // 目前 全家、OK 無提供, 需手動驗
    let { 訂單狀態, 通知配點狀態, 交易方式, 建立日期 } = order;
    if (order.交易方式 == 'OKMart' || order.交易方式 == 'FamilyMart') {
        return (訂單狀態 == 'S' && 通知配點狀態 == 2) ? true : false
    } else if (訂單狀態 == 'N' || 訂單狀態 == 'S' && 通知配點狀態 == 2) {
        if (交易方式 == "ECPay" && process.env.NODE_ENV == 'production') {
            let 經過秒數 = new moment().diff(new moment(建立日期), "seconds");
            return 經過秒數 > 40 * 60;
        } else {
            return true
        }
    } else {
        return false
    }
}
function isDateRangeShortThenDays(start, end, days) {
    const momentStart = moment(start);
    const momentEnd = moment(end);
    const rangeLength = momentEnd.diff(momentStart, 'days');
    return rangeLength < days;
}
function parseStrToAry(inputString) {
    const array = inputString
        .split(/\s*,\s*|\s*\n\s*/)  // 按逗号和换行符拆分，并处理前后的空白
        .map(item => item.trim())   // 移除每个元素的前后空白
        .filter(item => item !== ''); // 过滤掉空字符串
    return array;
}
// 0:單筆查詢
// 1:訂單編號
// 2:上游廠商訂單編號
// 3:廠商訂單編號

function getMultipleWay({ orderID, vendorOrderID, upstreamVendorOrderID }) {
    let orders = parseStrToAry(orderID);
    if (orders.length > 1) {
        return { MultipleWay: 1, colName: "orderID", orders: orders.join(","), amount: orders.length };
    }
    orders = parseStrToAry(vendorOrderID);
    if (orders.length > 1) {
        return { MultipleWay: 2, colName: "vendorOrderID", orders: orders.join(","), amount: orders.length };
    }

    orders = parseStrToAry(upstreamVendorOrderID);
    if (orders.length > 1) {
        return { MultipleWay: 3, colName: "upstreamVendorOrderID", orders: orders.join(","), amount: orders.length };
    }
    return { MultipleWay: 0, colName: "", orders: "", amount: 0 };
}

let isDev = process.env.NODE_ENV

function isCCatQuery({ paymentType, 序號 }) {
    return paymentType == 'CCat' && 序號 != '';
}
export default {
    components: {
        DateRangePicker,
        Pagination,
        RefoundForm,
        DetailForm,
        PopForm,
    },
    data() {
        let pageSize = 20;
        let seachLimitDay = 7;//查詢日期區間
        return {
            batchSearchLimit: 1000,//多筆查詢上限
            seachLimitDay,//
            pageSize,
            total: 0,

            isTEST: { // 測試
                isDev,
                isOpen: false,
                serviceID: '',
                orderID: '',
                orderStatus: '',
                orderEdit: { // 訂單調整用
                    isOpen: false,
                    editAmount: '',
                },
            },
            searchData: {
                IsExcludeNotPurchaseOrder: 0,
                serviceID: "",
                platformId: "",
                orderID: "",//1000120210529000011 //訂單編號
                vendorOrderID: "",//廠商訂單編號
                upstreamVendorOrderID: '',
                paymentType: "",//Gash //交易方式
                paymentDetail: "",//儲值類型
                InvoiceNumber: "",
                userId: "",//玩家儲別值
                orderStatus: "",//訂單狀態//N、S、F
                givePointResult: -2,//配點狀態
                startTime: new moment().add(-(seachLimitDay - 1), "days").format("YYYY-MM-DD 00:00:00"),
                endTime: new moment().format("YYYY-MM-DD 23:59:59"),
                startNo: 1,//起始行
                endNo: pageSize,//結束行
                chargeTimeYn: false,//是否以交易時間查詢
                productCode: "",// 商品代碼: ""
                creditCardNo: "",//信用卡卡號(只有指定金流為綠界才會有用)
                序號: "",
                MultipleWay: 0,
            },
            batchData: {
                startTime: new moment().add(-(seachLimitDay - 1), "days").format("YYYY-MM-DD"),
                endTime: new moment().format("YYYY-MM-DD"),
            },
            serverList: [],//服務
            IPSList: [],
            list: [],
            lastQueryCommand: null,
            tip: "",
        };
    },
    watch: {
        'searchData.IsExcludeNotPurchaseOrder' (val) {
            this.searchData.IsExcludeNotPurchaseOrder = val ? 1 : 0
        },
        "searchData.serviceID"(value) {
            {
                //金流
                this.searchData.paymentType = "";//自動選取全部
                let find = this.serverList.find(item => { return item.廠商編號 == value });
                if (find) {
                    this.IPSList = find.支付List;
                } else {
                    this.IPSList = [];
                }
            }
            {
                if(value == 10001){
                    this.searchData.upstreamVendorOrderID="";
                }
            }
        },
        "searchData.paymentType"(value) {
            if (value != "ECPay") {
                this.searchData.productCode = "";
                this.searchData.creditCardNo = "";
            }
            if (value != "CCat") {
                this.searchData.序號 = "";
            }
        },
        "searchData.orderID"(value) {
            this.setDatepickerDisabled();
        },
        "searchData.vendorOrderID"(value) {
            this.setDatepickerDisabled();
        },
        "searchData.upstreamVendorOrderID"(value) {
            this.setDatepickerDisabled();
        },
        "searchData.序號"(value) {
            this.setDatepickerDisabled();
        },
        searchData: {
            handler(newValue, oldValue) {
                this.lastQueryCommand = null;
            },
            deep: true
        }
        // v-if="!(searchData.orderID != '' || searchData.vendorOrderID != '')
    },
    mounted() {
        $("[data-toggle='tooltip']").tooltip();
        this.init().then(() => {
            if (this.$route.query.userId) {
                this.searchData.userId = this.$route.query.userId;
                this.onPageChange(1);
            }
            $(this.$refs.loading).fadeOut(400);
        });
    },
    methods: {
        adjustHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto'; // Reset the height
            if (textarea.scrollHeight > 100) {
                textarea.style.height = `100px`;
            } else {
                textarea.style.height = `${textarea.scrollHeight}px`;
            }
        },
        setDatepickerDisabled() {
            this.$refs.dateRangePicker.$refs.datepicker.disabled = (this.searchData.orderID != "" || this.searchData.vendorOrderID != "" || this.searchData.序號 != "" || this.searchData.upstreamVendorOrderID != "") ? true : false;
        },
        async init() {
            this.serverList = await IPSTool.ConstServerList.call(this, this.$store.state.projectId, "交易明細");//廠商專案列表;
            let 廠商支付列表FromServer = APILib.IPS(await api.IPSSetting.廠商支付設定_查詢({})).content;
            let 支付列表FromServer = await IPSTool.callAPIList.call(this, api.IPSSetting.金流設定主檔_查詢, {});
            {
                //依照各廠商有那些支付
                for (let server of this.serverList) {
                    this.$set(server, "支付List", []);
                    for (let 廠商支付 of 廠商支付列表FromServer) {
                        if (廠商支付.廠商設定主檔編號 == server.DB編號) {
                            let find金流 = 支付列表FromServer.find((金流) => { return 金流.編號 == 廠商支付.金流設定主檔編號 });
                            if (find金流 && !(server.支付List.find((item) => { return find金流.編號 == item.編號 }))) {
                                let { 編號, 金流名稱, 金流代碼 } = find金流;
                                server.支付List.push({ 編號, 金流名稱, 金流代碼 });
                            }
                        }
                    }
                }
            }

            if (this.serverList.length > 0) {
                this.searchData.serviceID = this.serverList[0].廠商編號;
            }
        },
        onPageChange(pageIdx) {
            this.searchData.startNo = (pageIdx - 1) * this.pageSize + 1;
            this.searchData.endNo = pageIdx * this.pageSize;
            let send = JSON.parse(JSON.stringify(this.searchData));


            if (send.orderID != "" || send.vendorOrderID != "" || send.upstreamVendorOrderID != "") {
                delete send.startTime;
                delete send.endTime;
            } else {
                send.startTime = this.$utils.formatTime(send.startTime, "YYYY-MM-DD");
                send.endTime = this.$utils.formatTime(send.endTime, "YYYY-MM-DD");
            }
            if (send.orderStatus === "") {
                send.orderStatus = null;
            }

            if (this.$user.hasPermission(
                "TransactionInfoAll"
            )) {
                // console.error("有權限")
            } else {
                if (
                    send.creditCardNo == "" && //信用卡號
                    send.orderID == "" && //訂單編號
                    send.paymentDetail == "" && //儲值類型
                    send.productCode == "" && //商品代碼
                    send.userId == "" && //玩家識別值
                    send.platformId == "" &&
                    send.vendorOrderID == "" && //廠商訂單編號  
                    send.upstreamVendorOrderID == "" &&
                    send.InvoiceNumber == "") {//發票號碼  
                    this.$eventBus.$emit("showAlert", "信用卡號,訂單編號,儲值類型,商品代碼,玩家識別值,平台識別值,金流訂單編號,廠商訂單編號 請擇一輸入");
                    return;
                }
            }
            if (!isDateRangeShortThenDays(send.startTime, send.endTime, this.seachLimitDay)) {
                this.$eventBus.$emit("showAlert", `查詢時間區間需小於${this.seachLimitDay}天`);
                return;
            }

            let { MultipleWay, colName, orders, amount } = getMultipleWay(send);
            send.MultipleWay = MultipleWay;
            if (send.MultipleWay == 0) {
                //不作任何事
            } else {
                if (amount > this.batchSearchLimit) {
                    this.$eventBus.$emit("showAlert", `多筆查詢限制${this.batchSearchLimit}筆`);
                    return;
                }
                let tmp = {
                    serviceID: (this.$store.state.projectId == "omp_ips") ? "" : send.serviceID,//專案是omp_ips的話，就不管那個服務，全查
                    givePointResult: send.givePointResult,
                    chargeTimeYn: send.chargeTimeYn,
                    MultipleWay: send.MultipleWay,
                };
                tmp[colName] = orders;//send[colName];
                send = tmp;
            }
            this.queryAll(send, pageIdx);
        },
        async queryAll(send, pageIdx) {
            if (!send) {
                return;
            }
            let res = {
                orders: [],
                total: 0
            };
            $(this.$refs.loading).show();
            this.tip = "";
            try {
                if (isCCatQuery(send)/*send.paymentType == 'CCat' && send.序號 != ''*/) {
                    //特殊插入：黑貓pay為查詢虛擬帳號//它是從細單反查
                    res = await IPSTool.callAPIList.call(this, api.IPSSetting.QueryOrderDetailList, {
                        服務編號: send.serviceID,
                        // 訂單編號: "1000120230713000135",
                        序號: send.序號,
                        搜尋目標: 1,
                        配點狀態: -1,
                        交易方式: send.paymentType,//CCat
                    });
                } else {
                    res = await IPSTool.callAPIList.call(this, api.IPSSetting.QueryOrderList, send);
                }
            } catch (e) {
                console.log("Error", e);
            }
            this.list = (res && res.orders) || [];
            this.total = (res && res.total) || 0;

            this.list.forEach((item) => {
                let find = this.IPSList.find((pay) => { return pay.金流代碼 === item.交易方式 });
                this.$set(item, "交易名稱", find ? find.金流名稱 : null);
                this.$set(item, "serviceID", item.服務編號);
                this.$set(item, "退貨功能", find ? find.退貨功能 : 0);
                let findServer = this.serverList.find((serverItem) => {
                    return serverItem.廠商編號 == item.serviceID
                })
                this.$set(item, "廠商代碼", findServer ? findServer.廠商代碼 : "");
                this.$set(item, "顯示重驗按鈕", show重驗證按鈕(item));
                // 調整訂單用
                this.$set(item, "調整後訂單金額", 0);
                this.$set(item, "是否調整訂單金額", false);
                this.$set(item, "服務名稱", findServer ? findServer.名稱 : item.serviceID);

            })

            if (this.total > 0 && !isCCatQuery(send)) {
                this.lastQueryCommand = { cmd: send, total: this.total };
            } else {
                this.lastQueryCommand = null;
            }
            if (pageIdx > 0) {
                this.$refs.pagination.setPage(pageIdx, this.total);
            } else {
                this.$refs.pagination.setPage(1, 0);
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async openDetailForm({ serviceID: 服務編號, 訂單編號 }) {
            let res = await IPSTool.callAPIList.call(this, api.IPSSetting.QueryOrderDetailList, {
                服務編號,
                訂單編號
            });
            if (res[0]) {
                let item = { ...res[0] };
                let title = "訂單明細：" + 訂單編號;
                this.$refs.detailForm.show(title, item);
            } else {
                this.$eventBus.$emit("showAlert", "無資料");
            }
        },
        async openCCatDetailForm({ serviceID: 服務編號, 訂單編號, 交易方式, 通知配點狀態: 配點狀態 }) {
            let 搜尋目標 = 1;
            let res = await IPSTool.callAPIList.call(this, api.IPSSetting.QueryOrderDetailList, {
                服務編號,
                訂單編號,
                搜尋目標,
                交易方式,
                配點狀態
            });
            try {
                res = res.orders;
                if (res[0]) {
                    let item = {
                        銀行代號: res[0].銀行代號,
                        匯款帳號: res[0].匯款帳號,
                        繳費期限: res[0].繳費期限,
                    };
                    let title = "繳費資訊：" + 訂單編號;
                    this.$refs.detailForm.show(title, item);
                } else {
                    this.$eventBus.$emit("showAlert", this.$t("無資料"));
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", this.$t("回傳資料格式錯誤"));
            }
        },
        async checkOrder({ 廠商代碼, 交易方式, 訂單編號, serviceID }) {
            try {
                let res = await api.IPSSetting.CheckOrder({
                    gameType: 廠商代碼,
                    paymentType: 交易方式,
                    orderNumber: 訂單編號
                });

                if (res && res.data) {
                    if (res.data.status == 1) {
                        this.$eventBus.$emit("showAlert", `${this.$t('配點成功')}:${res.data.content}${res.data.message}`);
                    } else {
                        this.$eventBus.$emit("showAlert", `${this.$t('配點失敗')}:${res.data.message}(${res.data.stateCode})`);
                    }
                } else {
                    this.$eventBus.$emit("showAlert", `${this.$t('未知')}:${res.toString()}`);
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", `${this.$t('未知')}:${e}`);
            }


            try {
                //只更新該筆資料
                let reloadCmd = {
                    serviceID: serviceID,
                    orderID: 訂單編號,
                    vendorOrderID: "",
                    paymentType: "",
                    paymentDetail: "",
                    InvoiceNumber: "",
                    userId: "",
                    orderStatus: null,
                    givePointResult: -2,
                    //   startTime: "2023-08-11",
                    //   endTime: "2023-08-18",
                    startNo: 1,
                    endNo: this.pageSize,
                    chargeTimeYn: false,
                    productCode: "",
                    creditCardNo: "",
                    序號: "",
                }

                let res = await IPSTool.callAPIList.call(this, api.IPSSetting.QueryOrderList, reloadCmd);

                let findOrderInList = this.list.find((item) => { return item.訂單編號 == res.orders[0].訂單編號 });
                if (findOrderInList) {
                    let keys = Object.keys(findOrderInList);
                    for (let key of keys) {
                        findOrderInList[key] = res.orders[0][key];
                    }
                    let find = this.IPSList.find((pay) => { return pay.金流代碼 === findOrderInList.交易方式 });
                    findOrderInList.交易名稱 = find ? find.金流名稱 : null;
                    findOrderInList.serviceID = serviceID;
                    findOrderInList.退貨功能 = find ? find.退貨功能 : 0;
                    let findServer = this.serverList.find((serverItem) => {
                        return serverItem.廠商編號 == findOrderInList.serviceID;
                    })
                    findOrderInList.廠商代碼 = findServer ? findServer.廠商代碼 : "";
                    findOrderInList.顯示重驗按鈕 = show重驗證按鈕(findOrderInList);
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", `畫面更新失敗，請重新搜尋 ${訂單編號} ，確認該筆資料正確`);
            }
        },
        openRefundForm({ serviceID: 服務編號, 訂單編號, 退貨YN, 原始金額 }) {
            this.$refs.refoundForm.show(退貨YN, {
                訂單編號,
                服務編號,
                原始金額
            });
        },
        async exportReportBtnClick() {
            if (!this.lastQueryCommand) {
                return;
            }
            let cloneCmd = (JSON.parse(JSON.stringify(this.lastQueryCommand))).cmd;
            const isMultiple = cloneCmd.MultipleWay != 0;//是否多筆查詢
            if (!isMultiple) {
                this.$refs.addForm.show();
                let picker = this.$refs.batchFormDateRangePicker.$refs.datepicker;
                $(picker).data('daterangepicker').setStartDate(cloneCmd.startTime);
                $(picker).data('daterangepicker').setEndDate(cloneCmd.endTime);

                this.batchData.startTime = cloneCmd.startTime;
                this.batchData.endTime = cloneCmd.endTime;
                return;
            } else {
                await this.doExportReport(cloneCmd);
            }
        },
        async exportFormBtnClick() {
            let d1 = new moment(this.batchData.startTime);
            let d2 = new moment(this.batchData.endTime);
            const diffInDays = d2.diff(d1, 'days') + 1;
            if (diffInDays > 31) {
                this.$eventBus.$emit("showAlert", "區間不可超過31天");
                return;
            }
            let cloneCmd = (JSON.parse(JSON.stringify(this.lastQueryCommand))).cmd;
            cloneCmd.startTime = new moment(this.batchData.startTime).format("YYYY-MM-DD");
            cloneCmd.endTime = new moment(this.batchData.endTime).format("YYYY-MM-DD");
            this.$refs.addForm.hide();
            await this.doExportReport(cloneCmd);
        },
        async doExportReport(cloneCmd) {
            $(this.$refs.loading).show();
            try {
                this.tip = `資料匯出中，請勿離開(一個月的資料量約需等待90秒)`;
                let isLookCreditNumber = this.$user.hasPermission('API_QueryOrderList_ShowCreditCardNum') ? 1 : 0;
                const isMultiple = cloneCmd.MultipleWay != 0;//是否多筆查詢
                const response = await api.IPSSetting.QueryOrderListExportExcel(isLookCreditNumber, cloneCmd);
                let filename = "交易明細-";

                if (isMultiple) {
                    //多筆查詢
                    filename = `${filename}多筆查詢${new Date().getTime()}.xlsx`;
                } else {
                    //一般查詢
                    filename = `${filename}${cloneCmd.serviceID}-${cloneCmd.startTime}-${cloneCmd.endTime}.xlsx`;
                }
                doDownload(response, filename);
            } catch (e) {
                this.$eventBus.$emit("showAlert", `匯出檔案出錯`);
                console.error(`${JSON.stringify(e)}`)
            }
            $(this.$refs.loading).fadeOut(400);
            this.tip = ``;
            function doDownload(response, filename) {
                const url = URL.createObjectURL(response.data);
                // 创建一个 <a> 元素来触发下载
                const a = document.createElement('a');
                a.href = url;
                a.download = filename; // 檔名
                document.body.appendChild(a); // 必须将 <a> 元素添加到 DOM 中
                a.click(); // 触发点击事件来开始下载
                document.body.removeChild(a); // 移除 <a> 元素
                URL.revokeObjectURL(url); // 释放 Blob URL
            }
        },

        // 調整金額、 或 修改訂單狀態為失敗(需登入並有權限)
        async transactionInfoUpdate(item, action) {
            let data = {}
            if (action) {
                // 修改訂單狀態
                data = {
                    serviceID: action.serviceID,
                    orderID: action.orderID,
                    orderStatus: action.orderStatus
                }
            } else {
                // 調整訂單
                data = {
                    serviceID: item.serviceID,
                    orderID: item.訂單編號,
                    amount: item.調整後訂單金額
                }
            }

            await this.$store.dispatch('IPSSetting/transactionInfoUpdate', data).then((res) => {
                if (res.data.status == '1') {
                    this.$eventBus.$emit("showAlert", res.data.content);
                    this.onPageChange(this.searchData.startNo)
                } else {
                    this.$eventBus.$emit("showAlert", res.data.message);
                }
            }).catch(e => console.log(e))
        },

        // 重置 交易明細訂單(需登入並有權限)
        async transactionInfoReset(item) {
            this.$eventBus.$emit('showConfirm', '是否重置訂單?', async (isOK) => {
                if (isOK) {
                    await this.$store.dispatch('IPSSetting/transactionInfoReset', {
                        serviceID: item.serviceID,
                        orderID: item.訂單編號
                    }).then((res) => {
                        if (res.data.status == '1') {
                            this.$eventBus.$emit("showAlert", res.data.content);
                            this.onPageChange(this.searchData.startNo)
                        } else {
                            this.$eventBus.$emit("showAlert", res.data.message);
                        }
                    }).catch(e => console.log(e))
                }
            });
        },
        格式化商品代碼: function ({ 商品代碼, serviceID }) {
            if (!商品代碼) {
                return '-';
            }
            switch (serviceID) {
                case "10014": {
                    switch (商品代碼) {
                        case "BindCard": return "綁卡";
                        case "YGPoint": return 商品代碼;//不變
                        default: return `禮包(${商品代碼})`;
                    }
                    // break;
                }
                default: return 商品代碼;
            }
        },
        格式化配點狀態: function (value) {
            switch (value) {
                case -1: return this.$t('初始') + "(-1)";
                case 0: return this.$t('失敗') + "(0)";
                case 1: return this.$t('成功') + "(1)";
                case 2: return this.$t('待確認') + "(2)";
                default:
                    return value;
            }
        },
        格式化訂單狀態: function (value) {
            switch (value) {
                case `N`: return this.$t('未完成') + "(N)";
                case `S`: return this.$t('成功') + "(S)";
                case `F`: return this.$t('失敗') + "(F)";
                case `T`: return this.$t('逾時') + "(T)";
                default:
                    return value;
            }
        },
        格式化退貨YN(value) {
            return value ? 'Y' : 'N';
        }
    },
};
</script>

<style lang='scss' scoped>
.box .overlay>.fa {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -15px;
    margin-top: 30px;
    color: #000;
    font-size: 30px;
}

.overlay>.tip {
    text-align: center;
    position: relative;
    top: calc(3em);
    margin-left: -15px;
    margin-top: 30px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.table th {
    word-wrap: break-word;
    word-break: keep-all;
    letter-spacing: -.05em;
    min-width: 50px;
}

.table td {
    padding-block: .5em;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.d-flex {
    display: flex;
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}

.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
}

.selPaymentType {
    width: 150px;
}

.table-card-box {
    .px-10 {
        padding: 0 10px;
    }

    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    border: 1px solid #f4f4f4;

    @media(max-width: 991px) {
        width: 100% !important;
        max-width: 280px;
    }

    &:after {
        content: '';
        width: 2px;
        background: #337ab7;
        height: 100%;
        display: list-item;
        position: absolute;
        top: 0;
    }
}

.textAreas {
    display: flex;
    flex-wrap: nowrap;
}

.textAreas>textarea {
    margin-right: 10px;
    margin-bottom: 10px;
}

@media(max-width: 991px) {
    .textAreas {
        display: block;
    }

    .textAreas {
        margin: 0;
    }
}
</style>
<style scoped>
.popform>>>.modalWidth {
    width: 270px;
}
</style>