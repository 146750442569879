var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲介紹列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲介紹列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "form",
            {
              staticClass: "top-box",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.clickSearch()
                },
              },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                  attrs: {
                    to: {
                      name: "XsgWebGameEdit",
                      params: { id: "New", searchData: _vm.searchData },
                    },
                  },
                },
                [_vm._v(" 新增 ")]
              ),
              _c("div", { staticClass: "select-group mb-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.遊戲分類,
                        expression: "searchData.遊戲分類",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "遊戲分類",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.clickSearch()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("All 所有遊戲"),
                    ]),
                    _vm._l(_vm.dfn.gameCategory, function (name, key) {
                      return _c(
                        "option",
                        { key: key, domProps: { value: key } },
                        [_vm._v(" " + _vm._s(key + " " + name) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "select-group mb-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.是否發佈,
                        expression: "searchData.是否發佈",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "是否發佈",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.clickSearch()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1" } }, [
                      _vm._v("上/下架"),
                    ]),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("上架")]),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("下架")]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "input-group mb-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.中文名稱,
                      expression: "searchData.中文名稱",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: { type: "text", placeholder: "搜尋遊戲名稱" },
                  domProps: { value: _vm.searchData.中文名稱 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "中文名稱", $event.target.value)
                    },
                  },
                }),
                _vm._m(0),
              ]),
              _c("div", { staticClass: "text mb-10" }, [
                _c("p", { staticClass: "data-num" }, [
                  _vm._v("資料總筆數: "),
                  _c("span", [_vm._v(_vm._s(_vm.total))]),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "table-responsive mt-10 text-center" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered AdListTable" },
              [
                _c(
                  "tbody",
                  [
                    _c(
                      "tr",
                      { staticClass: "bg-gray" },
                      [
                        _c(
                          "th",
                          { staticStyle: { "min-width": "80px", width: "5%" } },
                          [_vm._v("遊戲館ID")]
                        ),
                        _c(
                          "th",
                          { staticStyle: { "min-width": "80px", width: "5%" } },
                          [_vm._v("分類")]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { "min-width": "120px", width: "15%" },
                          },
                          [_vm._v("遊戲Logo")]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { "min-width": "100px", width: "10%" },
                          },
                          [_vm._v("遊戲名稱")]
                        ),
                        _vm._l(_vm.tags, function (tag, group) {
                          return _c(
                            "th",
                            {
                              key: group,
                              staticStyle: {
                                "min-width": "100px",
                                width: "auto",
                                "word-wrap": "break-word",
                                "word-break": "break-word",
                              },
                            },
                            [_vm._v(_vm._s(group))]
                          )
                        }),
                        _c(
                          "th",
                          { staticStyle: { "min-width": "75px", width: "5%" } },
                          [_vm._v("狀態")]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { "min-width": "100px", width: "8%" },
                          },
                          [_vm._v("排序")]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { "min-width": "100px", width: "8%" },
                          },
                          [_vm._v("功能")]
                        ),
                      ],
                      2
                    ),
                    _vm.message != ""
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "10" },
                            },
                            [_vm._v(_vm._s(_vm.message))]
                          ),
                        ])
                      : _vm.total <= 0
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "10" },
                            },
                            [_vm._v("無資料")]
                          ),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c(
                            "tr",
                            { key: item.id },
                            [
                              _c("td", [_vm._v(_vm._s(item.遊戲館))]),
                              _c("td", { attrs: { title: item.遊戲分類 } }, [
                                _vm._v(
                                  _vm._s(
                                    item.遊戲分類 +
                                      " " +
                                      (_vm.dfn.gameCategory[item.遊戲分類] ||
                                        "")
                                  )
                                ),
                              ]),
                              _c("td", [
                                _c("img", {
                                  staticStyle: { "max-height": "80px" },
                                  attrs: {
                                    src: item.logo,
                                    draggable: "false",
                                    alt: "",
                                  },
                                }),
                              ]),
                              _c("td", { attrs: { title: item.英文名稱 } }, [
                                _vm._v(_vm._s(item.中文名稱)),
                              ]),
                              _vm._l(_vm.tags, function (tag, group) {
                                return _c(
                                  "td",
                                  [
                                    _c("TagSelect", {
                                      attrs: {
                                        value: _vm.getTags(item, group),
                                        readonly: "",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              _c("td", [
                                _vm._v(_vm._s(item.是否發佈 ? "上架" : "下架")),
                              ]),
                              _c("td", { staticClass: "text-nowrap" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "column",
                                      gap: "5px",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: item.排序,
                                          expression: "item.排序",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control p-5",
                                      staticStyle: {
                                        "padding-right": "0",
                                        "text-align": "center",
                                      },
                                      attrs: {
                                        type: "number",
                                        min: "1",
                                        max: "",
                                        disabled: !item.是否發佈,
                                      },
                                      domProps: { value: item.排序 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "排序",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-warning btn-add",
                                        attrs: {
                                          type: "button",
                                          disabled: !item.是否發佈,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateOrder(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-arrows-v",
                                        }),
                                        _vm._v(" 更新 "),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "column",
                                      gap: "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: {
                                          to: {
                                            name: "XsgWebGameEdit",
                                            params: {
                                              id: item.gid,
                                              searchData: _vm.searchData,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-fw fa-pencil",
                                        }),
                                        _vm._v(" 修改 "),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteItem(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-fw fa-close",
                                        }),
                                        _vm._v(" 刪除 "),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          )
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-btn" }, [
      _c(
        "button",
        { staticClass: "btn btn-default", attrs: { type: "submit" } },
        [_c("i", { staticClass: "fa fa-search" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }