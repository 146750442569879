var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fieldset",
    { staticClass: "input-grid" },
    [
      _vm.label
        ? _c(
            "legend",
            {
              class: {
                required:
                  _vm.required ||
                  (_vm.required == null && _vm.langs.find((x) => x.required)) ||
                  false,
              },
            },
            [_vm._v(_vm._s(_vm.label) + "：")]
          )
        : _vm._e(),
      _vm._l(_vm.langs, function (lang) {
        return _c(
          "label",
          {
            staticClass: "input-group",
            class: {
              required:
                _vm.required ||
                (_vm.required == null && lang.required) ||
                false,
            },
          },
          [
            _c(
              "span",
              { staticClass: "input-group-addon", attrs: { title: lang.id } },
              [_vm._v(_vm._s(lang.name))]
            ),
            _c("input", {
              staticClass: "form-control",
              attrs: {
                disabled:
                  _vm.disabled ||
                  (_vm.disabled == null && lang.disabled) ||
                  false,
                required:
                  _vm.required ||
                  (_vm.required == null && lang.required) ||
                  false,
                readonly:
                  _vm.readonly ||
                  (_vm.readonly == null && lang.readonly) ||
                  false,
                placeholder:
                  _vm.required || (_vm.required == null && lang.required)
                    ? "*必填"
                    : "",
              },
              domProps: { value: _vm.getLang(lang.id) },
              on: {
                input: function ($event) {
                  return _vm.onInput($event, lang.id)
                },
              },
            }),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }