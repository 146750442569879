<template>
  <div>
    <section class="content-header">
      <h1>{{ getPlatform('title') }} {{$t('帳單查詢')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{ getPlatform('title') }} {{$t('帳單查詢')}}</li>
      </ol>
    </section>
    <section class="content" style="min-height:auto;">
      
      <form class="nav-tabs-custom tab-warning mb-10" @submit.prevent="getDataList()">
        <ul class="nav nav-tabs">
          <li :class="{ active: searchTab == 1 }">
            <a @click="setSearchTab(1)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('查詢玩家暱稱')}}</a>
          </li>
          <li :class="{ active: searchTab == 2 }">
            <a @click="setSearchTab(2)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('單筆交易查詢')}}</a>
          </li>
          <li :class="{ active: searchTab == 3 }">
            <a @click="setSearchTab(3)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('單筆交易查詢')}}</a>
          </li>
        </ul>
        <div class="tab-content clearfix mt-10">
          <div class="pull-left" v-if="searchTab == 1">
            <div class="form-group m-0 pull-left">
              <label class="control-label pull-left mt-7" for="searchUID">UID：</label>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="searchData.nickname" id="searchUID" class="form-control" :placeholder="$t('UID')" >
              </div>
            </div>
            <div class="form-group pull-left m-0">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date datetime pull-left mb-10 mr-10" style="width:300px;">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker :propStartDate.sync="searchData.startDate" :propEndDate.sync="searchData.endDate"
                  displayFormat="YYYY/MM/DD HH:mm" :propTimePicker="true" />
              </div>
            </div>
          </div>
          <div class="pull-left" v-if="searchTab == 2">
            <div class="form-group m-0 pull-left">
              <label class="control-label pull-left mt-7" for="searchSerial">交易序號：</label>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="searchData.transactionId" id="searchSerial" class="form-control" :placeholder="$t('交易序號')" >
              </div>
            </div>
          </div>
          <div class="pull-left" v-if="searchTab == 3">
            <div class="form-group m-0 pull-left">
              <label class="control-label pull-left mt-7" for="searchBatch">交易序號批次查詢：</label>
              <div class="input-group pull-left mb-10 mr-10">
                <input class="form-control mt-0" type="file" accept=".xlsx" @change="searchFile = $event.target.files[0]; getDataList()" id="searchBatch" >
              </div>
            </div>
          </div>
          <div class="pull-left mb-10">
            <button type="submit" class="btn btn-primary mr-10">
              查詢
            </button>
            <!-- <button type="button" class="btn btn-default" @click="exportData()" :disabled="datas.length === 0">
              {{$t('匯出')}}
            </button> -->
          </div>
          
          <div class="top-box clearfix" v-if="點數合計 != '-' || 不重複數 != '-'">
            <p class="text m-0 mr-10 mb-10"><b>點數合計：{{ 點數合計 }}</b></p>
            <p class="text m-0 mr-10 mb-10"><b>不重複數：{{ 不重複數 }}</b></p>
          </div>
        </div>
      </form>
      <!-- <div class="box mb-10">
        <div class="box-body">
          <form @submit.prevent="getDataList()">
            <div class="top-box clearfix">
              <div class="form-group m-0 pull-left">
                <label class="control-label pull-left mt-7" for="searchUID">UID：</label>
                <div class="input-group pull-left mb-10 mr-10">
                  <input type="text" v-model="searchData.nickname" id="searchUID" class="form-control" :placeholder="$t('UID')" >
                </div>
              </div>
              <div class="form-group m-0 pull-left">
                <label class="control-label pull-left mt-7" for="searchSerial">交易序號：</label>
                <div class="input-group pull-left mb-10 mr-10">
                  <input type="text" v-model="searchData.transactionId" id="searchSerial" class="form-control" :placeholder="$t('交易序號')" >
                </div>
              </div>
              <div class="form-group m-0 pull-left">
                <label class="control-label pull-left mt-7" for="searchBatch">交易序號批次查詢：</label>
                <div class="input-group pull-left mb-10 mr-10">
                  <input type="file" accept=".xlsx" @change="searchFile = $event.target.files[0]; getDataList()" id="searchBatch" >
                </div>
              </div>
            </div>
            <div class="top-box clearfix">
              <div class="form-group pull-left m-0">
                <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
                <div class="input-group date datetime pull-left mb-10 mr-10" style="width:300px;">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <DateRangePicker
                    :propStartDate.sync="searchData.startDate"
                    :propEndDate.sync="searchData.endDate"
                    :propTimePicker="true"
                    displayFormat="YYYY/MM/DD HH:mm"
                  />
                </div>
              </div>
              <div class="pull-left mb-10 mr-10">
                <button type="submit" class="btn btn-primary mr-10">
                  查詢
                </button>
                <button type="button" class="btn btn-default" @click="exportData()" :disabled="datas.length === 0">
                  {{$t('匯出')}}
                </button>
              </div>
            </div>
            <div class="top-box clearfix" v-if="點數合計 != '-' || 不重複數 != '-'">
              <p class="text m-0 mr-10 mb-10"><b>點數合計：{{ 點數合計 }}</b></p>
              <p class="text m-0 mr-10 mb-10"><b>不重複數：{{ 不重複數 }}</b></p>
            </div>
          </form>
        </div>
      </div> -->
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 }">
            <a @click="setTab(1)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('點數合計查詢')}}</a>
          </li>
          <li :class="{ active: tab == 2 }">
            <a @click="setTab(2)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('帳單查詢')}}</a>
          </li>
        </ul>
        <div class="tab-content" v-if="tab == 1">
          <div class="table-responsive" style="max-width:900px; margin:auto;">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:150px; width:20%">{{$t('日期')}}</th>
                  <th style="min-width:150px; width:20%">{{$t('點數合計')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="pastLoading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="pastMessage"><td colspan="11">{{ $t(message) }}</td></tr>
                <tr v-else-if="pastList.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <tr v-else v-for="(item, index) in pagedPastData" :key="item.id">
                  <td>{{ item.日期 }}</td>
                  <td>{{ item.點數合計 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-10">
            <Pagination :total="pastTotal" :pageLimitCount="searchData.pageSize" @pageMethod="onPageChange" />
          </div>
        </div>
        <div class="tab-content" v-if="tab == 2">
          <div class="table-responsive" style="max-width:900px; margin:auto;">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:150px; width:20%">{{$t('交易序號')}}</th>
                  <th style="min-width:100px; width:20%">{{$t('UID')}}</th>
                  <th style="min-width:100px; width:20%">{{$t('點數')}}</th>
                  <th style="min-width:150px; width:20%">{{$t('配點時間')}}</th>
                </tr>
              </thead>
              <tbody v-if="loading"><tr><td colspan="11">{{$t('查詢中')}}</td></tr></tbody>
              <tbody v-else-if="message"><tr><td colspan="11">{{ $t(message) }}</td></tr></tbody>
              <tbody v-else-if="datas.length === 0"><tr><td colspan="11">{{$t('查無資料')}}</td></tr></tbody>
              <tbody v-else v-for="page in pages">
                <tr v-for="(item, index) in page" :key="item.id">
                  <td>{{ item.交易ID }}</td>
                  <td>{{ item.id || item.暱稱}}</td>
                  <td>{{ item.點數 }}</td>
                  <td>{{ uiDate(item.配點時間 || item.日期) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-10" v-if="getPlatform('api') == 1 && total > searchData.pageSize">
            <Pagination :total="total" :pageLimitCount="searchData.pageSize" @pageMethod="onPageChange" />
          </div>
          <div class="text-center mt-10" v-else-if="getPlatform('api') == 2 && nextPageId">
            <button class="btn-link">查詢更多</button>
          </div>
        </div>
      </div>
    </section>

    <!-- <pre>{{ searchFile }} {{ searchData }}</pre>
    <pre>{{ datas }}</pre>
    <pre>{{ pastList }}</pre> -->
  </div>
</template>

<style lang="scss" scoped>
  table.table>tbody>tr {
    word-break: break-word;
  }
  table.table>thead>tr>th {
    word-break: keep-all;
  }
</style>
<style scoped>
  .table>>>mark{
    padding: 0;
    background: #ff8;
  }
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import FormDialog from "./components/FormDialog.vue";
import Pagination from "@/components/Pagination";
import router from "../../../router";
import utils from "@/assets/js/app-utils";
import xlsx from "xlsx";

const platformAPI = {
  Google: {
    api: 1, // API呼叫方式
    title: "GooglePlay",
    "帳單查詢": "Google帳單查詢",
    "帳單批次查詢": "Google帳單批次查詢",
    "每日點數合計查詢": "Google每日點數合計查詢",
  },
  Apple: {
    api: 1, // API呼叫方式
    title: "iOS",
    "帳單查詢": "iOS帳單查詢",
    "帳單批次查詢": "iOS帳單批次查詢",
    "每日點數合計查詢": "iOS每日點數合計查詢",
  },
  FunOne: {
    api: 2, // API呼叫方式
    title: "FunOne Games",
    "帳單查詢": "OrderDetailListQuery",
    "帳單批次查詢": "OrderDetailListQuery",
    "每日點數合計查詢": "OrderDetailListQuery",
  }
}

export default {
  props:{
    platform: {type:String, default:"Google"},
    // tab: {type:String, default:"Sum"}
  },
  components: {
    DateRangePicker,
    FormDialog,
    Pagination,
    router
  },
  data() {
    return {
      tab: 1,
      searchTab: 1,
      projectId: this.$store.state.projectId,

      filterString: "",
      searchFile: null,
      searchData: {
        "nickname": "",
        "transactionId": "",
        "startDate": new moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00"),
        "endDate": new moment().format("YYYY-MM-DD 23:59:59"),
        "pageNum": 1,
        "pageSize": 20
      },
      "點數合計": "-",
      "不重複數": "-",
      loading: false,
      message: "",
      total: 0, //總筆數

      pages: [[]],
      nextPageId: "",

      // pastList
      filterPastString: "",
      pastSearchData: {

        // 會長: "",
        // 公會名稱: "",
        開始時間: new moment(new Date("2024-01-01")).format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        // pageNum: 1,
        // pageSize: 10, //每頁顯示筆數
      },
      pastMessage: "", //錯誤訊息
      pastLoading: false,
      pastTotal: 0, //總筆數
      pastList: [],

      // 權限
      can查詢: this.$user.hasPermission(dfn.permission[platformAPI[this.platform].帳單查詢]),
      can批次: this.$user.hasPermission(dfn.permission[platformAPI[this.platform].帳單批次查詢]),
      can合計: this.$user.hasPermission(dfn.permission[platformAPI[this.platform].每日點數合計查詢]),
    };
  },
  async created() {
    if (this.$route.query.tab){
      this.tab = this.$route.query.tab
    }
    // this.getTypes();
    // window.v = this
  },
  watch: {
    platform: {
      handler(value){
        this.getDataList();
        this.getPastList();
      },
      immediate: true,
    }
  },
  computed:{
    getPlatform: () => {
      return function(key){
        let obj = platformAPI[this.platform];
        if (!obj) return "";
        return this.$t(obj[key]) || "";
      }
    } ,
    // filterData: function() {
    //   let data = this.datas;
    //   if (this.filterString != ""){
    //     let re = new RegExp(`(${this.filterString.trim()})` , "ig");
    //     let arr = [];
    //     for (let item of data){
    //       item = Object.assign({}, item);
    //       let isMatch = false;
    //       if (re.test(item.名稱)){ isMatch = true; item.名稱 = item.名稱.replace(re, "<mark>$1</mark>");}
    //       if (re.test(item.會長)){ isMatch = true; item.會長match = item.會長.replace(re, "<mark>$1</mark>");}
    //       if (isMatch) arr.push(item);
    //     }
    //     data = arr;
    //   }
    //   this.pageNum = 1;
    //   this.total = data.length;
    //   return data;
    // },
    // pagedData: function () {
    //   return this.filterData.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    // },
    pagedPastData: function () {
      return this.pastList.slice((this.searchData.pageNum - 1) * this.searchData.pageSize, (this.searchData.pageNum) * this.searchData.pageSize);
    },
  },
  methods: {
    setSearchTab(num){
      this.searchTab = num;
    },
    setTab(num){
      this.tab = num;
      let query = Object.assign({}, this.$route.query, {tab:num});
      if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
        this.$router.replace({query:query});
      }
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      if (this.tab == 2) {
        this.getDataList();
      } else {
        this.getPastInfo();
      }
    },
    getDataList() {
      let onErr = (err) => {
        this.message = err;
        this.pages[this.searchData.pageNum - 1] = [];
        this.點數合計 = '-';
        this.不重複數 = '-';
        this.total = 0;
        this.loading = false;
      }
      let onRes = (res) => {
        this.message = "";
        this.pages[this.searchData.pageNum - 1] = res.datas || [];
        this.點數合計 = res.點數合計 || '-';
        this.不重複數 = res.不重複數 || '-';
        this.total = res.total || 0;
        this.loading = false;
      }
      let fetchType1 = () => {
        this.loading = true;
        if (this.searchTab == 3 && this.searchFile){
          let form = new FormData();
          form.append("formFile", this.searchFile);
          form.append("startDate", this.searchData.startDate);
          form.append("endDate", this.searchData.endDate);
          form.append("projectId", this.$store.state.projectId);
          this.$store.dispatch("xsw/" + platformAPI[this.platform].帳單批次查詢, form).then(onRes, onErr);
        } else {
          this.$store.dispatch("xsw/" + platformAPI[this.platform].帳單查詢, this.searchData).then(onRes, onErr);
        }
      };
      let fetchType2 = () => {
        this.loading = true;
        if (this.searchTab == 3 && this.searchFile){
          let form = new FormData();
          form.append("formFile", this.searchFile);
          form.append("startDate", this.searchData.startDate);
          form.append("endDate", this.searchData.endDate);
          form.append("projectId", this.$store.state.projectId);
          this.$store.dispatch("xsw/" + platformAPI[this.platform].帳單批次查詢, form).then(onRes, onErr);
        } else {
          this.$store.dispatch("xsw/" + platformAPI[this.platform].帳單查詢, this.searchData).then(onRes, onErr);
        }
      };

      switch(platformAPI[this.platform].api){
        case 1: fetchType1(); break;
        case 2: fetchType2(); break;
      }      
    },
    getPastList(){
      this.pastLoading = true;
      this.$store.dispatch("xsw/" + platformAPI[this.platform].每日點數合計查詢, this.searchData)
      .then(res => {
        this.pastMessage = "";
        this.pastList = res || [];
        this.pastTotal = res.length || 0;
        this.pastLoading = false;
      }, err => {
        this.pastMessage = err;
        this.pastList = [];
        this.pastTotal = 0;
        this.pastLoading = false;
      });
    },
    exportData() {
        let 點數合計 = xlsx.utils.json_to_sheet(this.pastList || "");
        let 帳單 = xlsx.utils.json_to_sheet(this.datas || "");
        let fileName = `${this.platform == 'Google' ? 'GooglePlay' : 'iOS'}帳單查詢_${utils.getTimeId()}.xlsx`;
        let new_workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(new_workbook, 點數合計, "點數合計");
        xlsx.utils.book_append_sheet(new_workbook, 帳單, "帳單");
        xlsx.writeFile(new_workbook, fileName);
    },
    uiDate(date) {
      let d = new Date(date);
      if (d != "Invalid Date") return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
      else return date;
    }
  },
};
</script>