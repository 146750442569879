<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>{{$t('序號兌換記錄查詢')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('序號兌換記錄查詢')}}</li>
      </ol>
    </section>
    <section v-if="!id" class="content" style="min-height:auto;">
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 }">
            <a @click="tab = 1" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('活動列表')}}</a>
          </li>
          <li :class="{ active: tab == 2 }">
            <a @click="tab = 2" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('查詢帳號')}}</a>
          </li>
        </ul>
      
        <!-- 遊戲歷程 -->
        <div class="tab-content" v-show="tab == 1">
          <form class="top-box clearfix" @submit.prevent="getData()">
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t('序號名稱')" v-model="searchEventData.序號名稱">
            </div>
            <div class="select-group mb-10 mr-10">
              <select class="form-control" v-model.number="searchEventData.類型" @change="getData()">
                <option :value="0">{{$t('全部類型')}}</option>
                <option v-for="(name, key) in serialTypes" :key="key" :value="key">{{$t(name)}}</option>
              </select>
            </div>
            <div class="form-group pull-left m-0">
              <div class="form-group pull-left m-0">
                <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
                <div class="input-group date pull-left mb-10 mr-10">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <DateRangePicker
                    :propStartDate.sync="searchEventData.開始時間"
                    :propEndDate.sync="searchEventData.結束時間"
                    displayFormat="YYYY/MM/DD HH:mm"
                  />
                </div>
              </div>

              <div class="pull-left mb-10 mr-10">
                <button type="submit" class="btn btn-primary mr-10">
                  {{$t('查詢')}}
                </button>
                <button type="button" class="btn btn-default" @click="exportEvent()" :disabled="eventList.length === 0">
                  {{$t('匯出')}}
                </button>
              </div>
            </div>
          </form>

          <div class="table-responsive">
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width: 50px; width: 3%">{{$t('項目')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('序號名稱')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('序號獎勵')}}</th>
                  <th style="min-width:160px; width:25%">{{$t('序號兌換時間')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('類型')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('序號數量')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('已兌換數量')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('備註')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="eventLoading">
                  <td colspan="11">{{$t('查詢中')}}</td>
                </tr>
                <tr v-else-if="eventMessage">
                  <td colspan="11">{{ $t(eventMessage) }}</td>
                </tr>
                <tr v-else-if="eventList.length === 0">
                  <td colspan="11">{{$t('查無資料')}}</td>
                </tr>
                <tr v-else v-for="(item, index) in pagedEventData" :key="item.序號名稱">
                  <td>{{ index + 1 + ((searchEventData.pageNum-1)*searchEventData.pageSize) }}</td>
                  <td>{{ item.序號名稱 }}</td>
                  <td>{{ item.序號獎勵 }}</td>
                  <td>{{ item.序號兌換時間 }}</td>
                  <td>{{ serialTypes[item.類型] }}</td>
                  <td>{{ item.序號數量 }}</td>
                  <td>{{ item.已兌換數量 }}</td>
                  <td><router-link class="btn btn-primary" :to="{params:{id:item.序號名稱}}">查詢</router-link></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-show="eventTotal > 0" class="text-center mt-10">
            <Pagination ref="paginationEvent" :total="eventTotal"
              :pageLimitCount="searchEventData.pageSize"
              v-on:pageMethod="onPageChange" :type2="true"
            />
          </div>
        </div>

        <!-- 勝負分歷程 -->
        <div class="tab-content" v-show="tab == 2">
          <form class="top-box clearfix" @submit.prevent="getData()">
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t('UID')" v-model="searchAccountData.UID">
            </div>
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t('序號')" v-model="searchAccountData.序號">
            </div>
            <div class="form-group pull-left m-0">
              <div class="form-group pull-left m-0">
                <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
                <div class="input-group date pull-left mb-10 mr-10">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <DateRangePicker 
                    :propStartDate.sync="searchAccountData.開始時間"
                    :propEndDate.sync="searchAccountData.結束時間"
                    displayFormat="YYYY/MM/DD HH:mm"
                  />
                </div>
              </div>
              <div class="pull-left mb-10 mr-10">
                <button type="submit" class="btn btn-primary mr-10">
                  {{$t('查詢')}}
                </button>
                <button type="button" class="btn btn-default" 
                  @click="exportAccount()" 
                  :disabled="accountList.length == 0">
                  {{$t('匯出')}}
                </button>
              </div>
            </div>
          </form>
          <div class="table-responsive">
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width: 50px; width: 5%">{{$t('項次')}}</th>
                  <th style="min-width: 80px; width:10%">{{$t('UID')}}</th>
                  <th style="min-width: 80px; width:10%">{{$t('暱稱')}}</th>
                  <th style="min-width:100px; width:15%">{{$t('序號')}}</th>
                  <th style="min-width:100px; width:15%">{{$t('兌換時間')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="accountLoading">
                  <td colspan="11">{{$t('查詢中')}}</td>
                </tr>
                <tr v-else-if="accountMessage">
                  <td colspan="11">{{ $t(accountMessage) }}</td>
                </tr>
                <tr v-else-if="accountList.length === 0">
                  <td colspan="11">{{$t('查無資料')}}</td>
                </tr>
                <tr v-else v-for="(item, index) in pagedAccountData" :key="item.兌換時間">
                  <td>{{ index + 1 + ((searchAccountData.pageNum-1)*searchAccountData.pageSize) }}</td>
                  <td>{{ item.uid }}</td>
                  <td>{{ item.暱稱 }}</td>
                  <td>{{ item.序號 }}</td>
                  <td>{{ item.兌換時間 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-show="accountTotal > 0" class="text-center mt-10">
            <Pagination ref="paginationAccount" :total="accountTotal"
              :pageLimitCount="searchAccountData.pageSize"
              v-on:pageMethod="onPageChange" :type2="true"
            />
          </div>
        </div>
      </div>
    </section>

    <section v-else class="content" style="min-height:auto;">
      <div class="box">
        <div class="box-body">
          <form class="top-box clearfix" @submit.prevent="getData()">
            <router-link class="btn btn-default pull-right mb-10" :to="{params:{id:null}}">
              <i class="fa fa-reply"></i> {{$t('返回')}}
            </router-link>
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t('關鍵字搜尋')" v-model="filterSerialData.關鍵字">
            </div>
            <div class="select-group mb-10 mr-10">
              <select class="form-control" v-model="filterSerialData.狀態">
                <option value="">{{$t('全部')}}</option>
                <option value="已兌換">{{$t('已兌換')}}</option>
                <option value="時間區間">{{$t('時間區間')}}</option>
              </select>
            </div>
            <div class="form-group pull-left m-0" v-if="filterSerialData.狀態 == '時間區間'">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date pull-left mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker 
                  :propStartDate.sync="filterSerialData.開始時間"
                  :propEndDate.sync="filterSerialData.結束時間"
                  displayFormat="YYYY/MM/DD HH:mm"
                />
              </div>
            </div>
            <div class="form-group pull-left m-0">
              <div class="pull-left mb-10 mr-10">
                <!-- <button type="submit" class="btn btn-primary mr-10">
                  {{$t('查詢')}}
                </button> -->
                <button type="button" class="btn btn-default" 
                  @click="exportSerial()" 
                  :disabled="serialList.length == 0">
                  {{$t('匯出')}}
                </button>
              </div>
            </div>
          </form>
          <div class="table-responsive">
            <table class="table table-bordered text-center mb-10">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:100px; width:15%">{{$t('序號名稱')}}</th>
                  <th style="min-width:100px; width:15%">{{$t('序號獎勵')}}</th>
                  <th style="min-width:160px; width:40%">{{$t('序號兌換時間')}}</th>
                  <th style="min-width:100px; width:15%">{{$t('序號數量')}}</th>
                  <th style="min-width:100px; width:15%">{{$t('已兌換數量')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ id }}</td>
                  <td>{{ serialData.序號獎勵 || '-' }}</td>
                  <td>{{ serialData.序號兌換時間 || '-' }}</td>
                  <td>{{ serialData.序號數量 || '-' }}</td>
                  <td>{{ serialData.已兌換數量 || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width: 50px; width: 5%">{{$t('項次')}}</th>
                  <th style="min-width:120px; width:15%">{{$t('序號')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('兌換狀態')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('UID')}}</th>
                  <th style="min-width:100px; width:15%">{{$t('暱稱')}}</th>
                  <th style="min-width:100px; width:15%">{{$t('兌換時間')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="serialLoading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="serialMessage"><td colspan="11">{{ $t(serialMessage) }}</td></tr>
                <tr v-else-if="serialList.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <tr v-else v-for="(item, index) in pagedSerialData" :key="item.序號">
                  <td>{{ index + 1 + ((searchSerialData.pageNum-1)*searchSerialData.pageSize) }}</td>
                  <td v-html="item._序號 || item.序號"></td>
                  <td v-html="item._兌換狀態 || item.兌換狀態 || '-'"></td>
                  <td v-html="item._uid || item.uid || '-'"></td>
                  <td>
                    <span v-if="!item.暱稱">-</span>
                    <router-link v-else v-html="item._暱稱 || item.暱稱" :to="{name:'XsgMember', params:{id:item.uid}}"></router-link>
                  </td>
                  <td>{{ item.兌換時間 || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-show="serialTotal > searchSerialData.pageSize" class="text-center mt-10">
            <Pagination ref="paginationSerial" :total="serialTotal"
              :pageLimitCount="searchSerialData.pageSize"
              v-on:pageMethod="onPageChange" :type2="true"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- <pre>{{ eventList }}</pre> -->
    <!-- <pre>{{ accountList }}</pre> -->
    <!-- <pre>{{ serialList }}</pre> -->
  </div>
</template>

<style scoped>
.table >>> mark{
  padding: 0;
  background: #fea;
}
</style>

<script>
/* eslint-disable */
import moment from "moment";
import types from "@/views/projects/XSG/_Define";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheckBox";
import router from "../../../router";
import utils from "@/assets/js/app-utils";
import { prettyPrintJson } from 'pretty-print-json';

export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    Pagination,
    router
  },
  props:{
    id: ""
  },
  data() {
    return {
      tab: 1,
      serialTypes: types.serialType,

      // tab 1
      searchEventData: { 
        "序號名稱": "",
        "類型": 0, // 0: 全部， 1: 多人兌換， 2: 一人兌換
        "開始時間": new moment().subtract(12, "months").format("YYYY-MM-DD 00:00:00"),
        "結束時間": new moment().add(12, "months").format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 20,
      },
      eventList: [], //all data
      eventTotal: 0, //總筆數
      eventMessage: "",
      eventLoading: false,

      // tab 2
      searchAccountData: {
        "序號": "",
        "UID": "",
        "開始時間": new moment().subtract(12, "months").format("YYYY-MM-DD 00:00:00"),
        "結束時間": new moment().add(12, "months").format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 20, 
      },
      accountList: [],
      accountTotal: 0, //總筆數
      accountMessage: "", //錯誤訊息
      accountLoading: false,

      // id: serial
      searchSerialData: {
        "序號": "",
        pageNum: 1,
        pageSize: 100, 
      },
      filterSerialData: {
        "關鍵字": "",
        "狀態": "",
        "開始時間": "2024-01-01 00:00:00" /*new moment().subtract(12, "months").format("YYYY-MM-DD 00:00:00")*/,
        "結束時間": new moment().format("YYYY-MM-DD 23:59:59"),
      },
      serialList: [],
      serialData: {},
      serialTotal: 0, //總筆數
      serialMessage: "", //錯誤訊息
      serialLoading: false,
    };
  },
  async created() {
    this.searchSerialData.序號 = this.id;
    if (this.$route.query.nick) {
      this.searchAccountData.UID = this.$route.query.nick;
      this.tab = 2;
    }
    this.getData();
  },
  watch: {
    tab(){
      this.getData();
    },
    id(value){
      this.searchSerialData.序號 = value;
      this.getData();
    }
    // 'playerGameHistoryList'(){ this.searchEventData.pageNum = 1; },
    // 'playerPoinList'(){ this.searchAccountData.pageNum = 1; },
    // 'searchEventData.暱稱'(newNickname) { this.searchAccountData.暱稱 = newNickname; },
    // 'searchAccountData.暱稱'(newNickname) { this.searchEventData.暱稱 = newNickname; },
  },
  computed: {
    pagedEventData: function(){
      let pageNum = this.searchEventData.pageNum;
      let pageSize = this.searchEventData.pageSize;
      return this.eventList.slice((pageNum - 1) * pageSize, (pageNum) * pageSize);
    },
    pagedAccountData: function(){
      let pageNum = this.searchAccountData.pageNum;
      let pageSize = this.searchAccountData.pageSize;
      return this.accountList.slice((pageNum - 1) * pageSize, (pageNum) * pageSize);
    },
    filteredSerialData: function(){
      let data = utils.deepCloneObj(this.serialList);
      let f = this.filterSerialData;
      let sDate = new Date(f.開始時間).valueOf();
      let eDate = new Date(f.結束時間).valueOf();

      if (f.狀態 == "已兌換" || f.狀態 == "時間區間"){
        data = data.filter(x => x.兌換狀態 == "已兌換");
      }
      if (f.狀態 == "時間區間"){
        data = data.filter(x => {
          if (x.兌換時間 == "") return true;
          let d = new Date(x.兌換時間).valueOf();
          return sDate <= d && d <= eDate;
        });
      }
      if (f.關鍵字 != ""){
        let arr = [];
        let re = new RegExp('(' + f.關鍵字 + ')', "gi");
        for (let item of data){
          let isMatch = false;
          if (re.test(item.序號)){
            item._序號 = item.序號.replace(re, "<mark>$1</mark>");
            isMatch = true;
          }
          if (re.test(item.uid)){
            item._uid = item.uid.replace(re, "<mark>$1</mark>");
            isMatch = true;
          }
          if (re.test(item.暱稱)){
            item._暱稱 = item.暱稱.replace(re, "<mark>$1</mark>");
            isMatch = true;
          }
          if (isMatch) arr.push(item);
        }
        data = arr;
      }
      this.serialTotal = data.length;
      return data;
    },
    pagedSerialData: function(){
      let pageNum = this.searchSerialData.pageNum;
      let pageSize = this.searchSerialData.pageSize;
      return this.filteredSerialData.slice((pageNum - 1) * pageSize, (pageNum) * pageSize);
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      if (this.tab == 1) {
        this.searchEventData.pageNum = pageIdx;
        this.searchEventData.pageSize = pageSize;
      }
      if (this.tab == 2) {
        this.searchAccountData.pageNum = pageIdx;
        this.searchAccountData.pageSize = pageSize;
      }
      if (this.id) {
        this.searchSerialData.pageNum = pageIdx;
        this.searchSerialData.pageSize = pageSize;
      }
    },
    getData(){
      if (this.id){
        this.getSerialList();
        this.getSerialData();
      } else {
        if (this.tab == 1) this.getEventList();
        if (this.tab == 2){
          if( this.searchAccountData.UID == "") this.accountMessage = "請填寫UID查詢";
          else this.getAccountList();
        }
      }
    },
    getEventList() {
      this.eventLoading = true;
      this.$store.dispatch("xsw/序號兌換活動列表查詢", this.searchEventData)
        .then((res) => {
          let result = res.datas;
          result = result.map(x => {x.序號兌換時間 = x.序號兌換時間.replace("~", " ~ "); return x});
          this.eventList = result || [];
          this.eventTotal = this.eventList.length || 0;
          this.$refs.paginationEvent.onPageChange(1);

          this.eventLoading = false;
          this.eventMessage = "";
        }, (msg) => {
          this.eventList = [];
          this.eventTotal =  0;
          this.eventMessage = msg;
          this.eventLoading = false;
        });
    },

    getAccountList() {
      this.accountLoading = true;
      this.$store.dispatch("xsw/玩家序號兌換記錄查詢", this.searchAccountData)
        .then((result) => {
          this.accountList = result.datas || [];
          this.accountTotal = this.accountList.length || 0;
          this.$refs.paginationAccount.onPageChange(1);
          this.accountLoading = false;
          this.accountMessage = "";
          if (this.accountTotal > 0){
            let query = {};
            if (this.searchAccountData.UID != "") query['nick'] = this.searchAccountData.UID;
            if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
              this.$router.replace({query:query});
            }
          }
        }, (msg) => {
          this.accountMessage = msg;
          this.accountList = [];
          this.accountTotal =  0;
          this.accountLoading = false;
        });
    },
    getSerialList() {
      this.serialLoading = true;
      this.$store.dispatch("xsw/序號兌換狀態列表查詢", this.searchSerialData)
        .then((res) => {
          let result = res;
          // result = result.map(x => {x.序號兌換時間 = x.序號兌換時間.replace("~", " ~ "); return x});
          this.serialList = result.datas || [];
          this.serialTotal = this.serialList.length || 0;
          this.$refs.paginationSerial.onPageChange(1);

          this.serialLoading = false;
          this.serialMessage = "";
        }, (msg) => {
          this.serialList = [];
          this.serialTotal =  0;
          this.serialMessage = msg;
          this.serialLoading = false;
        });
      
    },
    getSerialData(){
      let data = this.eventList.find(x => x.序號名稱 == this.id);
      if (data){
        this.serialData = data;
        return;
      }
      this.$store.dispatch("xsw/序號兌換活動列表查詢", {"序號名稱": this.id})
        .then((res) => {
          let result = res.datas[0] || {序號兌換時間: ""};
          result.序號兌換時間 = result.序號兌換時間.replace("~", " ~ ");
          this.serialData = result;
        }, (msg) => {
          this.serialData = {};
        });
    },

    exportEvent() {
        let data = utils.deepCloneObj(this.eventList);
        let items = [];
        data.forEach(item => {
          // console.log(item);
            items.push({
                '序號名稱': item.序號名稱,
                '序號獎勵': item.序號獎勵,
                '序號兌換時間': item.序號兌換時間,
                '類型': this.serialTypes[item.類型] || item.類型,
                '序號數量': item.序號數量,
                '已兌換數量': item.已兌換數量,
            });
        });
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_序號兌換活動列表.xlsx`,
            '活動列表'
        );
    },

    exportAccount() {
        let data = utils.deepCloneObj(this.accountList);
        utils.exportData(
            data,
            `${this.searchAccountData.UID}_玩家序號兌換記錄.xlsx`,
            '玩家序號兌換記錄'
        );
    },
    exportSerial() {
        let data = utils.deepCloneObj(this.serialList);
        utils.exportData(
          data,
          `${this.id}_序號兌換狀態列表.xlsx`,
          '序號兌換狀態列表'
        );
    },
    isNullJSON(string){
      return string == "null";
    },
    isObjectMes(isString){
      return typeof isString == "object";
    },
    get訊息(item){
      let message = item.訊息;
      if ("stringContent" in message) return message.stringContent;
      if ("longContent" in message) return message.longContent;
      if ("objectArray" in message) return message.objectArray;
      return message;
    },
    toggleJSON(event){
      let commonAncestor = event.target.parentElement.parentElement.parentElement;
      for (let el of commonAncestor.querySelectorAll(".json-content")){
        el.classList.toggle("open")
      }
    },
    prettyJSON(object){
      try  {
        if (typeof object === "string"){
          object = JSON.parse(object);
        }
        return prettyPrintJson.toHtml(object, {indent:2, quoteKeys:true, trailingCommas: false});
      }
      catch{
        return object
      }
    }
  },
};
</script>