import { render, staticRenderFns } from "./iCheckBox.vue?vue&type=template&id=fa523d68"
import script from "./iCheckBox.vue?vue&type=script&lang=js"
export * from "./iCheckBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\GitProjects\\ompweb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fa523d68')) {
      api.createRecord('fa523d68', component.options)
    } else {
      api.reload('fa523d68', component.options)
    }
    module.hot.accept("./iCheckBox.vue?vue&type=template&id=fa523d68", function () {
      api.rerender('fa523d68', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/iCheckBox.vue"
export default component.exports