var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("input", {
      staticClass: "flat",
      attrs: { type: "checkbox", disabled: _vm.propDisabled },
      domProps: { value: _vm.propValue, checked: _vm.isChecked },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }