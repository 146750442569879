export default class {
  static gameCategory = {
    // "All": "所有遊戲",
    "Slots": "SLOT區",
    "Special": "特殊",
    "Table": "牌桌",
    "Bronze": "銅幣",
  };
  static gameBannerType = [
    "活動總覽",
    "隨機AD彈窗"
  ];
  static webBannerType = [
    "Banner管理",
    "首頁看版管理"
  ];
  static webNewsType = [
    "系統",
    "活動"
  ];
  static gameBannerBadge = [
    "new",
    "hot",
  ];
  static gameBannerButton = [
    "立即前往",
    "立即儲值",
    "我知道了",
  ];
  static gamePlatform = [
    "Google",
    "iOS",
    "APK"
  ];
  static gameRegion = [
    "全球",
    "新馬",
    "越南",
    "泰國"
  ];
  static gameLocale = {
    "zh-TW": "繁中",
    "zh-CN": "簡中",
    "en-US": "英文",
    "th-TH": "泰文",
    "vi-VN": "越南文",
  };
  static gameAction = {
    "Special": "特殊",
    "Table": "牌桌",
    "Bronze": "銅幣",
    "Bank": "銀行",
    "Guild": "公會",
    "Chat": "聊天",
    "NewbieMission": "新手任務",
    "Rank": "排行榜",
    "Mail": "信箱",
    "Player": "個人資訊",
    "Achievement": "成就",
    "Bag": "背包",
    "Refer": "介紹人",
    "Event": "活動",
    "Challenge": "星幣爭霸戰",
    "Pointgame": "積分館",
    "Badge": "徽章",
    "Pkstore": "PK幣商店",
  };
  static gameCoin = {
    "N": "星幣",
    "B": "銅幣",
  };
  
  // static gameAction = [
  //   "登入歷程",
  //   "Apple儲值",
  //   "Google儲值",
  //   "VIP購點",
  //   "儲值",
  //   "單局押注",
  //   "金庫配發",
  //   "金庫捐贈",
  //   "公會分潤",
  //   "配發信件",
  //   "信件領取",
  //   "客服專線禁言",
  //   "客服專線解除禁言",
  //   "永久禁言",
  //   "解除永久禁言",
  //   "保留機台",
  //   "進入機台",
  //   "離開機台",
  //   "離開遊戲館",
  //   "進入遊戲館",
  // ];
  static bannerType = {
      1: "大廳輪播",
      2: "活動公告",
  };
  static serialType = {
      1: "一人兌換",
      2: "多人兌換",
  };
  static permission = {
    // 信件管理
    信件發送: '信件發送',
    信件歷程查詢: '信件歷程查詢',
    持有信件查詢: '持有信件查詢',
    語言檔查詢: '語言檔查詢',
    官網Banner查詢: '官網Banner查詢',
  }

  static gameItem = {
    // 後端
    0: '沒有物品',
    1: '金幣',
    3: '銅幣',
  };
}