var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲操作說明" + _vm._s(_vm.isEdit ? "編輯" : "新增"))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: _vm.goBack } }, [
              _vm._v("遊戲操作說明" + _vm._s(_vm.isEdit ? "編輯" : "新增")),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.id))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "top-box with-border" },
            [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.isEdit ? "編輯" : "新增") + "內容"),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-default btn-cog pull-right",
                  attrs: { to: _vm.goBack },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ],
            1
          ),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-body",
                  staticStyle: { "max-width": "1000px" },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.version,
                            expression: "edit.version",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "inputTitle", required: "" },
                        domProps: { value: _vm.edit.version },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "version", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "col-sm-2 control-label" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.type.id == _vm.langs[0].id,
                              expression: "type.id == langs[0].id",
                            },
                          ],
                          staticClass: "text-red",
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v(" 語系版本： "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "col-sm-10 btns",
                        staticStyle: {
                          display: "flex",
                          "flex-wrap": "wrap",
                          gap: "5px",
                        },
                      },
                      _vm._l(_vm.langs, function (lang) {
                        return _c(
                          "button",
                          {
                            staticClass: "btn",
                            class: { "btn-primary": _vm.type.id == lang.id },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.type = lang
                              },
                            },
                          },
                          [_vm._v(_vm._s(lang.name))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "col-sm-2 control-label" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.type.id == _vm.langs[0].id,
                              expression: "type.id == langs[0].id",
                            },
                          ],
                          staticClass: "text-red",
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v(" " + _vm._s(_vm.type.name) + "說明： "),
                    ]),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "editor" },
                        [
                          _c("tinymce", {
                            attrs: { height: "600" },
                            model: {
                              value: _vm.toggleContent,
                              callback: function ($$v) {
                                _vm.toggleContent = $$v
                              },
                              expression: "toggleContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.edit.sort,
                            expression: "edit.sort",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", id: "inputSort", min: "0" },
                        domProps: { value: _vm.edit.sort },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "sort",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-lg mr-10",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.preview()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-fw fa-eye" }),
                      _vm._v(" 預覽 "),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-lg mr-10",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(true)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-fw fa-save" }),
                      _vm._v(" 儲存 "),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(false)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-fw fa-check" }),
                      _vm._v(" 確認送出 "),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 版本名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputSort" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 排序： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }