var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [
        _vm._v(
          _vm._s(_vm.getPlatform("title")) + " " + _vm._s(_vm.$t("帳單查詢"))
        ),
      ]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v(_vm._s(_vm.$t("首頁"))),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(
            _vm._s(_vm.getPlatform("title")) + " " + _vm._s(_vm.$t("帳單查詢"))
          ),
        ]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "content", staticStyle: { "min-height": "auto" } },
      [
        _c(
          "form",
          {
            staticClass: "nav-tabs-custom tab-warning mb-10",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.getDataList()
              },
            },
          },
          [
            _c("ul", { staticClass: "nav nav-tabs" }, [
              _c("li", { class: { active: _vm.searchTab == 1 } }, [
                _c(
                  "a",
                  {
                    staticStyle: { cursor: "pointer" },
                    attrs: { "aria-expanded": "true", "data-toggle": "tab" },
                    on: {
                      click: function ($event) {
                        return _vm.setSearchTab(1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("查詢玩家暱稱")))]
                ),
              ]),
              _c("li", { class: { active: _vm.searchTab == 2 } }, [
                _c(
                  "a",
                  {
                    staticStyle: { cursor: "pointer" },
                    attrs: { "aria-expanded": "true", "data-toggle": "tab" },
                    on: {
                      click: function ($event) {
                        return _vm.setSearchTab(2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("單筆交易查詢")))]
                ),
              ]),
              _c("li", { class: { active: _vm.searchTab == 3 } }, [
                _c(
                  "a",
                  {
                    staticStyle: { cursor: "pointer" },
                    attrs: { "aria-expanded": "true", "data-toggle": "tab" },
                    on: {
                      click: function ($event) {
                        return _vm.setSearchTab(3)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("單筆交易查詢")))]
                ),
              ]),
            ]),
            _c("div", { staticClass: "tab-content clearfix mt-10" }, [
              _vm.searchTab == 1
                ? _c("div", { staticClass: "pull-left" }, [
                    _c("div", { staticClass: "form-group m-0 pull-left" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label pull-left mt-7",
                          attrs: { for: "searchUID" },
                        },
                        [_vm._v("UID：")]
                      ),
                      _c(
                        "div",
                        { staticClass: "input-group pull-left mb-10 mr-10" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.nickname,
                                expression: "searchData.nickname",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "searchUID",
                              placeholder: _vm.$t("UID"),
                            },
                            domProps: { value: _vm.searchData.nickname },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.searchData,
                                  "nickname",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "form-group pull-left m-0" }, [
                      _c(
                        "label",
                        { staticClass: "control-label pull-left mt-7" },
                        [_vm._v(_vm._s(_vm.$t("查詢區間")) + "：")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-group date datetime pull-left mb-10 mr-10",
                          staticStyle: { width: "300px" },
                        },
                        [
                          _c("div", {
                            staticClass: "input-group-addon fa fa-calendar",
                          }),
                          _c("DateRangePicker", {
                            attrs: {
                              propStartDate: _vm.searchData.startDate,
                              propEndDate: _vm.searchData.endDate,
                              displayFormat: "YYYY/MM/DD HH:mm",
                              propTimePicker: true,
                            },
                            on: {
                              "update:propStartDate": function ($event) {
                                return _vm.$set(
                                  _vm.searchData,
                                  "startDate",
                                  $event
                                )
                              },
                              "update:prop-start-date": function ($event) {
                                return _vm.$set(
                                  _vm.searchData,
                                  "startDate",
                                  $event
                                )
                              },
                              "update:propEndDate": function ($event) {
                                return _vm.$set(
                                  _vm.searchData,
                                  "endDate",
                                  $event
                                )
                              },
                              "update:prop-end-date": function ($event) {
                                return _vm.$set(
                                  _vm.searchData,
                                  "endDate",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.searchTab == 2
                ? _c("div", { staticClass: "pull-left" }, [
                    _c("div", { staticClass: "form-group m-0 pull-left" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label pull-left mt-7",
                          attrs: { for: "searchSerial" },
                        },
                        [_vm._v("交易序號：")]
                      ),
                      _c(
                        "div",
                        { staticClass: "input-group pull-left mb-10 mr-10" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.transactionId,
                                expression: "searchData.transactionId",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "searchSerial",
                              placeholder: _vm.$t("交易序號"),
                            },
                            domProps: { value: _vm.searchData.transactionId },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.searchData,
                                  "transactionId",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.searchTab == 3
                ? _c("div", { staticClass: "pull-left" }, [
                    _c("div", { staticClass: "form-group m-0 pull-left" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label pull-left mt-7",
                          attrs: { for: "searchBatch" },
                        },
                        [_vm._v("交易序號批次查詢：")]
                      ),
                      _c(
                        "div",
                        { staticClass: "input-group pull-left mb-10 mr-10" },
                        [
                          _c("input", {
                            staticClass: "form-control mt-0",
                            attrs: {
                              type: "file",
                              accept: ".xlsx",
                              id: "searchBatch",
                            },
                            on: {
                              change: function ($event) {
                                _vm.searchFile = $event.target.files[0]
                                _vm.getDataList()
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._m(0),
              _vm.點數合計 != "-" || _vm.不重複數 != "-"
                ? _c("div", { staticClass: "top-box clearfix" }, [
                    _c("p", { staticClass: "text m-0 mr-10 mb-10" }, [
                      _c("b", [_vm._v("點數合計：" + _vm._s(_vm.點數合計))]),
                    ]),
                    _c("p", { staticClass: "text m-0 mr-10 mb-10" }, [
                      _c("b", [_vm._v("不重複數：" + _vm._s(_vm.不重複數))]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
          _c("ul", { staticClass: "nav nav-tabs" }, [
            _c("li", { class: { active: _vm.tab == 1 } }, [
              _c(
                "a",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { "aria-expanded": "true", "data-toggle": "tab" },
                  on: {
                    click: function ($event) {
                      return _vm.setTab(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("點數合計查詢")))]
              ),
            ]),
            _c("li", { class: { active: _vm.tab == 2 } }, [
              _c(
                "a",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { "aria-expanded": "true", "data-toggle": "tab" },
                  on: {
                    click: function ($event) {
                      return _vm.setTab(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("帳單查詢")))]
              ),
            ]),
          ]),
          _vm.tab == 1
            ? _c("div", { staticClass: "tab-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "table-responsive",
                    staticStyle: { "max-width": "900px", margin: "auto" },
                  },
                  [
                    _c(
                      "table",
                      { staticClass: "table table-bordered text-center" },
                      [
                        _c("thead", [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "150px",
                                  width: "20%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("日期")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "150px",
                                  width: "20%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("點數合計")))]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm.pastLoading
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("查詢中"))),
                                  ]),
                                ])
                              : _vm.pastMessage
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t(_vm.message))),
                                  ]),
                                ])
                              : _vm.pastList.length === 0
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("查無資料"))),
                                  ]),
                                ])
                              : _vm._l(
                                  _vm.pagedPastData,
                                  function (item, index) {
                                    return _c("tr", { key: item.id }, [
                                      _c("td", [_vm._v(_vm._s(item.日期))]),
                                      _c("td", [_vm._v(_vm._s(item.點數合計))]),
                                    ])
                                  }
                                ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "text-center mt-10" },
                  [
                    _c("Pagination", {
                      attrs: {
                        total: _vm.pastTotal,
                        pageLimitCount: _vm.searchData.pageSize,
                      },
                      on: { pageMethod: _vm.onPageChange },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.tab == 2
            ? _c("div", { staticClass: "tab-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "table-responsive",
                    staticStyle: { "max-width": "900px", margin: "auto" },
                  },
                  [
                    _c(
                      "table",
                      { staticClass: "table table-bordered text-center" },
                      [
                        _c("thead", [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "150px",
                                  width: "20%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("交易序號")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "100px",
                                  width: "20%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("UID")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "100px",
                                  width: "20%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("點數")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "150px",
                                  width: "20%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("配點時間")))]
                            ),
                          ]),
                        ]),
                        _vm.loading
                          ? _c("tbody", [
                              _c("tr", [
                                _c("td", { attrs: { colspan: "11" } }, [
                                  _vm._v(_vm._s(_vm.$t("查詢中"))),
                                ]),
                              ]),
                            ])
                          : _vm.message
                          ? _c("tbody", [
                              _c("tr", [
                                _c("td", { attrs: { colspan: "11" } }, [
                                  _vm._v(_vm._s(_vm.$t(_vm.message))),
                                ]),
                              ]),
                            ])
                          : _vm.datas.length === 0
                          ? _c("tbody", [
                              _c("tr", [
                                _c("td", { attrs: { colspan: "11" } }, [
                                  _vm._v(_vm._s(_vm.$t("查無資料"))),
                                ]),
                              ]),
                            ])
                          : _vm._l(_vm.pages, function (page) {
                              return _c(
                                "tbody",
                                _vm._l(page, function (item, index) {
                                  return _c("tr", { key: item.id }, [
                                    _c("td", [_vm._v(_vm._s(item.交易ID))]),
                                    _c("td", [
                                      _vm._v(_vm._s(item.id || item.暱稱)),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(item.點數))]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.uiDate(item.配點時間 || item.日期)
                                        )
                                      ),
                                    ]),
                                  ])
                                }),
                                0
                              )
                            }),
                      ],
                      2
                    ),
                  ]
                ),
                _vm.getPlatform("api") == 1 &&
                _vm.total > _vm.searchData.pageSize
                  ? _c(
                      "div",
                      { staticClass: "text-center mt-10" },
                      [
                        _c("Pagination", {
                          attrs: {
                            total: _vm.total,
                            pageLimitCount: _vm.searchData.pageSize,
                          },
                          on: { pageMethod: _vm.onPageChange },
                        }),
                      ],
                      1
                    )
                  : _vm.getPlatform("api") == 2 && _vm.nextPageId
                  ? _c("div", { staticClass: "text-center mt-10" }, [
                      _c("button", { staticClass: "btn-link" }, [
                        _vm._v("查詢更多"),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pull-left mb-10" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary mr-10", attrs: { type: "submit" } },
        [_vm._v(" 查詢 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }