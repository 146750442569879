var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "RewardMessageEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [
        _vm._v(_vm._s(_vm.id != undefined ? "編輯" : "新增") + "推播"),
      ]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/NMJ/Notification" } }, [
              _vm._v("推播設定"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.id != undefined ? "編輯" : "新增") + "內容"),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.id != undefined ? "編輯" : "新增") + "內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog fa fa-reply",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "rewardMessageForm", method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("fieldset", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.標題,
                          expression: "data.標題",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "",
                        placeholder: "建議16字內",
                      },
                      domProps: { value: _vm.data.標題 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.data, "標題", $event.target.value)
                        },
                      },
                    }),
                    _c("p", { staticClass: "help-block" }, [
                      _vm._v("※發送內容、語系與填寫內容相同"),
                    ]),
                  ]),
                ]),
                _c("fieldset", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.內容,
                          expression: "data.內容",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputContent",
                        required: "",
                        placeholder: "建議160字內",
                        rows: "3",
                      },
                      domProps: { value: _vm.data.內容 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.data, "內容", $event.target.value)
                        },
                      },
                    }),
                    _c("p", { staticClass: "help-block" }, [
                      _vm._v("※發送內容、語系與填寫內容相同"),
                    ]),
                  ]),
                ]),
                _c("fieldset", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputImage" },
                    },
                    [_vm._v(" 推播圖片： ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.推播圖片,
                            expression: "data.推播圖片",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "inputImage",
                          placeholder: "輸入圖片網址或瀏覽影片檔案",
                        },
                        domProps: { value: _vm.data.推播圖片 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.data, "推播圖片", $event.target.value)
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "input-group-addon bg-aqua btn btn-info",
                        },
                        [
                          _c("span", {}, [_vm._v("上傳圖片")]),
                          _c("input", {
                            staticClass: "sr-only",
                            attrs: { type: "file", accept: ".png" },
                            on: {
                              change: function ($event) {
                                return _vm.uploadFile($event)
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("p", { staticClass: "help-block" }, [
                      _vm._v("※檔案尺寸128×128，小於200KB，格式PNG"),
                    ]),
                  ]),
                ]),
                _c("hr"),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "col-sm-2" }),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "max-width": "400px",
                          "border-radius": "10px",
                          padding: "8px",
                          "box-shadow":
                            "2px 2px 5px rgba(0,0,0,.25), 0 0 1px 1px rgba(0,0,0,.1)",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "margin-bottom": "3px",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "1.4em",
                                height: "1.4em",
                                "border-radius": ".25em",
                                float: "left",
                                "margin-right": "5px",
                              },
                              attrs: {
                                src: "https://play-lh.googleusercontent.com/9RA8MlV-4o-Ys1Pmj7rm_cxgW9KYfytlcB9SJxgFt31qDgIwABghjfgR0Pt5095VVw=w32-h32-rw",
                              },
                            }),
                            _c("strong", [_vm._v("麻將之星")]),
                            _c(
                              "time",
                              {
                                staticStyle: { float: "right", opacity: ".5" },
                              },
                              [_vm._v(_vm._s(_vm.previewTime))]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              "line-height": "1.4",
                            },
                          },
                          [
                            _c("div", { staticStyle: { clear: "both" } }, [
                              _c("strong", { staticClass: "text-clamp" }, [
                                _vm._v(_vm._s(_vm.data.標題)),
                              ]),
                              _c("span", { staticClass: "text-clamp2" }, [
                                _vm._v(_vm._s(_vm.data.內容)),
                              ]),
                            ]),
                            _c("img", {
                              staticStyle: {
                                "max-height": "64px",
                                "max-width": "64px",
                                height: "auto",
                                width: "auto",
                                "object-fit": "cover",
                                "border-radius": ".25em",
                              },
                              attrs: { src: _vm.data.推播圖片 },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("hr"),
                _c("fieldset", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c(
                    "div",
                    { staticClass: "radio col-sm-10" },
                    [
                      _c("iCheckRedioButton", {
                        staticClass: "mr-10",
                        attrs: {
                          name: "recipientRadio",
                          message: "全部",
                          checkValue: "1",
                        },
                        model: {
                          value: _vm.data.發送對象,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "發送對象", _vm._n($$v))
                          },
                          expression: "data.發送對象",
                        },
                      }),
                      _c("iCheckRedioButton", {
                        staticClass: "mr-10",
                        attrs: {
                          name: "recipientRadio",
                          message: "iOS",
                          checkValue: "3",
                        },
                        model: {
                          value: _vm.data.發送對象,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "發送對象", _vm._n($$v))
                          },
                          expression: "data.發送對象",
                        },
                      }),
                      _c("iCheckRedioButton", {
                        staticClass: "mr-10",
                        attrs: {
                          name: "recipientRadio",
                          message: "Android",
                          checkValue: "4",
                        },
                        model: {
                          value: _vm.data.發送對象,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "發送對象", _vm._n($$v))
                          },
                          expression: "data.發送對象",
                        },
                      }),
                      _c("iCheckRedioButton", {
                        staticClass: "mr-10",
                        attrs: {
                          name: "recipientRadio",
                          message: "限定用戶",
                          checkValue: "2",
                        },
                        model: {
                          value: _vm.data.發送對象,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "發送對象", _vm._n($$v))
                          },
                          expression: "data.發送對象",
                        },
                      }),
                      _c("label", [
                        _c("span", { staticClass: "btn btn-info" }, [
                          _vm._v("瀏覽.csv"),
                        ]),
                        _c("span", { staticClass: "text ml-10" }, [
                          _vm._v(_vm._s(_vm.fileName)),
                        ]),
                        _c("input", {
                          staticClass: "sr-only",
                          attrs: { type: "file", accept: ".csv" },
                          on: {
                            change: function ($event) {
                              return _vm.onImportCSV($event)
                            },
                          },
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-success ml-10",
                            attrs: { href: "/uploads/NMJ/example.csv" },
                          },
                          [_vm._v("下載範例.csv檔")]
                        ),
                      ]),
                      _vm.data.發送對象 == 2 && _vm.data.限定用戶.length > 0
                        ? _c(
                            "pre",
                            {
                              staticClass: "mt-10 m-0",
                              staticStyle: {
                                "overflow-y": "auto",
                                "max-height": "240px",
                                "white-space": "pre-wrap",
                              },
                            },
                            [_vm._v(_vm._s(_vm.data.限定用戶.join(",\n")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.data.發送對象 == 1
                  ? _c("fieldset", { staticClass: "form-group" }, [
                      _vm._m(3),
                      _c(
                        "div",
                        { staticClass: "radio col-sm-10" },
                        [
                          _c("iCheckRedioButton", {
                            staticClass: "mr-10",
                            attrs: {
                              name: "loginRadio",
                              message: "不限",
                              checkValue: "1",
                            },
                            model: {
                              value: _vm.data.登入狀態,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "登入狀態", _vm._n($$v))
                              },
                              expression: "data.登入狀態",
                            },
                          }),
                          _c("iCheckRedioButton", {
                            staticClass: "mr-10",
                            attrs: {
                              name: "loginRadio",
                              message: "是",
                              checkValue: "2",
                            },
                            model: {
                              value: _vm.data.登入狀態,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "登入狀態", _vm._n($$v))
                              },
                              expression: "data.登入狀態",
                            },
                          }),
                          _c("iCheckRedioButton", {
                            staticClass: "mr-10",
                            attrs: {
                              name: "loginRadio",
                              message: "否",
                              checkValue: "3",
                            },
                            model: {
                              value: _vm.data.登入狀態,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "登入狀態", _vm._n($$v))
                              },
                              expression: "data.登入狀態",
                            },
                          }),
                          _c("span", { staticClass: "text text-muted" }, [
                            _vm._v(
                              "※選擇「是」需登入APP才能收到推播，選擇「否」需不開啟APP可收到推播"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("fieldset", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "radio col-sm-10" }, [
                    _c(
                      "p",
                      [
                        _c("iCheckRedioButton", {
                          staticStyle: { "margin-right": "1em" },
                          attrs: {
                            name: "timeRadio",
                            message: "立即發送",
                            checkValue: "1",
                          },
                          model: {
                            value: _vm.data.推播時間,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "推播時間", _vm._n($$v))
                            },
                            expression: "data.推播時間",
                          },
                        }),
                        _c("iCheckRedioButton", {
                          attrs: {
                            name: "timeRadio",
                            message: "預約發送",
                            checkValue: "2",
                          },
                          model: {
                            value: _vm.data.推播時間,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "推播時間", _vm._n($$v))
                            },
                            expression: "data.推播時間",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.data.推播時間 == 2,
                            expression: "data.推播時間 == 2",
                          },
                        ],
                        staticClass: "radio-date",
                      },
                      [
                        _c("DateAndTimePicker", {
                          attrs: { format: "YYYY-MM-DD HH:mm:ss" },
                          model: {
                            value: _vm.data.預約時間,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "預約時間", $$v)
                            },
                            expression: "data.預約時間",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg fa fa-send-o",
                      staticStyle: { "margin-right": ".5em" },
                      attrs: { type: "submit" },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-lg fa fa-reply",
                      staticStyle: { "margin-right": ".5em" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.backList()
                        },
                      },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _vm.id
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger btn-lg fa fa-trash",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteMessage()
                            },
                          },
                        },
                        [_vm._v(" 取消此推播 ")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 推播標題： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputContent" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 推播內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "recipientRadio" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 發送對象： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "loginRadio" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 登入狀態： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "timeRadio" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 推播時間： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }