import { render, staticRenderFns } from "./PostKeywordList.vue?vue&type=template&id=f0f4656a"
import script from "./PostKeywordList.vue?vue&type=script&lang=js"
export * from "./PostKeywordList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\GitProjects\\ompweb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f0f4656a')) {
      api.createRecord('f0f4656a', component.options)
    } else {
      api.reload('f0f4656a', component.options)
    }
    module.hot.accept("./PostKeywordList.vue?vue&type=template&id=f0f4656a", function () {
      api.rerender('f0f4656a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projects/FBRobot/PostKeywordList.vue"
export default component.exports