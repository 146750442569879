var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲聊天室公告")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _vm.id
          ? _c(
              "li",
              { staticClass: "active" },
              [
                _c("router-link", { attrs: { to: { params: { id: null } } } }, [
                  _vm._v("遊戲聊天室公告"),
                ]),
              ],
              1
            )
          : _c("li", { staticClass: "active" }, [_vm._v("遊戲聊天室公告")]),
        _vm.id
          ? _c("li", { staticClass: "active" }, [
              _vm._v(_vm._s(_vm.id != "New" ? "編輯" : "新增") + "公告"),
            ])
          : _vm._e(),
      ]),
    ]),
    !_vm.id
      ? _c("section", { staticClass: "content" }, [
          _c("div", { staticClass: "box box-primary" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "top-box" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.addNew()
                      },
                    },
                  },
                  [_vm._v(" 新增訊息 ")]
                ),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchFilter.狀態,
                          expression: "searchFilter.狀態",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectUnit", required: "" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchFilter,
                            "狀態",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                      _vm._l(_vm.states, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item } },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "form-group pull-left m-0" }, [
                  _c("label", { staticClass: "control-label pull-left mt-7" }, [
                    _vm._v(_vm._s(_vm.$t("查詢區間")) + "："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "input-group date pull-left" },
                    [
                      _c("div", {
                        staticClass: "input-group-addon fa fa-calendar",
                      }),
                      _c("DateRangePicker", {
                        attrs: {
                          propStartDate: _vm.searchFilter.開始時間,
                          propEndDate: _vm.searchFilter.結束時間,
                          propTimePicker: false,
                          displayFormat: "YYYY/MM/DD HH:mm",
                        },
                        on: {
                          "update:propStartDate": function ($event) {
                            return _vm.$set(
                              _vm.searchFilter,
                              "開始時間",
                              $event
                            )
                          },
                          "update:prop-start-date": function ($event) {
                            return _vm.$set(
                              _vm.searchFilter,
                              "開始時間",
                              $event
                            )
                          },
                          "update:propEndDate": function ($event) {
                            return _vm.$set(
                              _vm.searchFilter,
                              "結束時間",
                              $event
                            )
                          },
                          "update:prop-end-date": function ($event) {
                            return _vm.$set(
                              _vm.searchFilter,
                              "結束時間",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "text" }, [
                  _c("p", { staticClass: "data-num" }, [
                    _c("span", [_vm._v("資料總筆數：")]),
                    _c("span", { attrs: { id: "total" } }, [
                      _vm._v(_vm._s(_vm.datas.length)),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "table-responsive mt-10 text-center" }, [
                _c(
                  "table",
                  { staticClass: "table table-hover table-bordered" },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._m(0),
                        _vm.loading
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "11" } }, [
                                _vm._v(_vm._s(_vm.$t("查詢中"))),
                              ]),
                            ])
                          : _vm.message
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "11" } }, [
                                _vm._v(_vm._s(_vm.$t(_vm.message))),
                              ]),
                            ])
                          : _vm.datas.length === 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "11" } }, [
                                _vm._v(_vm._s(_vm.$t("查無紀錄"))),
                              ]),
                            ])
                          : _vm._l(_vm.pagedData, function (item, index) {
                              return _c("tr", { key: item.index }, [
                                _c("td", [
                                  _c("div", {
                                    staticClass: "textEllipsis text-left",
                                    domProps: {
                                      textContent: _vm._s(item.content),
                                    },
                                  }),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.FormatTime(item.startTime))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.FormatTime(item.endTime))),
                                ]),
                                _c("td", [_vm._v(_vm._s(_vm.getStatus(item)))]),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary mr-5",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("刪除")]
                                  ),
                                ]),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticClass: "box-footer text-center no-border",
              },
              [
                _c("Pagination", {
                  ref: "pagination",
                  attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
                  on: { pageMethod: _vm.onPageChange },
                }),
              ],
              1
            ),
          ]),
        ])
      : _c("section", { staticClass: "content" }, [
          _c("div", { staticClass: "box box-primary" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "top-box with-border" }, [
                _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                  _vm._v(_vm._s(_vm.id != "New" ? "編輯" : "新增") + "公告"),
                ]),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-block btn-default btn-cog fa fa-reply",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.cancelItem(_vm.edit)
                      },
                    },
                  },
                  [_vm._v(" 返回列表 ")]
                ),
              ]),
              _c(
                "form",
                {
                  staticClass: "form-horizontal",
                  attrs: { name: "announcementForm" },
                },
                [
                  _c("div", { staticClass: "box-body" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        { staticClass: "col-sm-10" },
                        [
                          _c("DateAndTimePicker", {
                            model: {
                              value: _vm.edit.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "startTime", $$v)
                              },
                              expression: "edit.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        { staticClass: "col-sm-10" },
                        [
                          _c("DateAndTimePicker", {
                            model: {
                              value: _vm.edit.endTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "endTime", $$v)
                              },
                              expression: "edit.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.content,
                              expression: "edit.content",
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { resize: "vertical" },
                          attrs: { rows: "20", required: "" },
                          domProps: { value: _vm.edit.content },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.edit, "content", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "box-footer" }, [
                    _c("div", { staticClass: "btns" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-lg fa fa-save",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.saveItem(_vm.edit)
                            },
                          },
                        },
                        [_vm._v(" 確認送出 ")]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", { staticStyle: { "min-width": "150px", width: "30%" } }, [
        _vm._v("公告內容"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "15%" } }, [
        _vm._v("開始日期"),
        _c("wbr"),
        _vm._v("(UTC+8)"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "15%" } }, [
        _vm._v("結束日期"),
        _c("wbr"),
        _vm._v("(UTC+8)"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticStyle: { "min-width": "140px", width: "5%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 開始時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 結束時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內文： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }