var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", { staticClass: "main-footer" }, [
      _c("p", { staticClass: "develop pull-right" }, [
        _vm._v("網銀國際(股)有限公司 研發處開發"),
      ]),
      _c("p", { staticClass: "copyright" }, [
        _vm._v("Copyright © 2023 "),
        _c("a", { attrs: { href: "https://www.wanin.tw", target: "_blank" } }, [
          _vm._v("WANIN"),
        ]),
        _vm._v(" All Rights Reserved."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }