var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "MemberData" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("會員資料")))]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _vm._v(_vm._s(_vm.$t("首頁"))),
              ]),
            ],
            1
          ),
          _vm.id
            ? _c(
                "li",
                { staticClass: "active" },
                [
                  _c("router-link", { attrs: { to: _vm.goBack } }, [
                    _vm._v(_vm._s(_vm.$t("會員資料"))),
                  ]),
                ],
                1
              )
            : _c("li", { staticClass: "active" }, [
                _vm._v(_vm._s(_vm.$t("會員資料"))),
              ]),
          _vm.id
            ? _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.id))])
            : _vm._e(),
        ]),
      ]),
      !_vm.id
        ? _c(
            "section",
            { staticClass: "content", staticStyle: { "min-height": "auto" } },
            [
              _c("div", { staticClass: "box box-primary" }, [
                _c("div", { staticClass: "box-body" }, [
                  _c(
                    "form",
                    {
                      staticClass: "top-box clearfix",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          _vm.searchData.pageNum = 1
                          _vm.getDataList()
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "select-group mb-10 mr-10" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.type,
                                expression: "searchData.type",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.searchData,
                                    "type",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  _vm.searchData.value = ""
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.selectType, function (value, key) {
                            return _c("option", { domProps: { value: key } }, [
                              _vm._v(_vm._s(_vm.$t(value))),
                            ])
                          }),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "input-group mb-10 mr-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.searchData.value,
                              expression: "searchData.value",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              _vm.selectType[_vm.searchData.type]
                            ),
                          },
                          domProps: { value: _vm.searchData.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.searchData,
                                "value",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "pull-left mb-10 mr-10" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-10",
                            attrs: { type: "submit" },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("查詢")) + " ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: {
                              type: "button",
                              disabled: _vm.datas.length === 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.exportPlayers()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("匯出")) + " ")]
                        ),
                      ]),
                      _vm.$route.params.back
                        ? _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-default pull-right mb-10 ml-10",
                              attrs: { to: _vm.$route.params.back },
                            },
                            [
                              _c("i", { staticClass: "fa fa-arrow-left" }),
                              _vm._v(" " + _vm._s(_vm.$t("返回")) + " "),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "table-responsive mt-0" }, [
                    _c(
                      "table",
                      { staticClass: "table table-bordered text-center" },
                      [
                        _c("thead", [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("UID")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "105px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("暱稱")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "120px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("綁定門號")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "90px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("登入方式")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("等級")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "90px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("角色狀態")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "105px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("最後登入日期")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "230px",
                                  width: "5%",
                                },
                                style: {
                                  minWidth: /^(en)/.test(
                                    _vm.$root.$options.i18n.locale
                                  )
                                    ? "320px"
                                    : "230px",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("查看")) + " ")]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("停權狀態")))]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm.loading
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("查詢中"))),
                                  ]),
                                ])
                              : _vm.message
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t(_vm.message))),
                                  ]),
                                ])
                              : _vm.datas.length === 0
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("查無資料"))),
                                  ]),
                                ])
                              : _vm._l(_vm.datas, function (user, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [_vm._v(_vm._s(user.玩家識別值))]),
                                    _c("td", [_vm._v(_vm._s(user.暱稱))]),
                                    _c("td", [
                                      user.綁定門號
                                        ? _c("span", [
                                            _vm._v(_vm._s(user.綁定門號)),
                                          ])
                                        : _c(
                                            "button",
                                            {
                                              staticClass: "btn-link",
                                              on: {
                                                click: function ($event) {
                                                  _vm
                                                    .getDetails(user)
                                                    .then(
                                                      (res) =>
                                                        (user.綁定門號 =
                                                          res.綁定門號 || "-")
                                                    )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("查看")))]
                                          ),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.$t(user.登入方式))),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(user.level))]),
                                    _c("td", [
                                      _c("div", {
                                        staticStyle: {
                                          width: "max-content",
                                          margin: "0 auto",
                                          "text-align": "left",
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.banStatus(user)
                                          ),
                                        },
                                      }),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.uiDate(user.最後登入))),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "grid",
                                            "grid-template-columns": "1fr 1fr",
                                            gap: "5px",
                                          },
                                          attrs: {
                                            "data-style": {
                                              gridTemplateColumns: /^(en)/.test(
                                                _vm.$root.$options.i18n.locale
                                              )
                                                ? "1fr"
                                                : "1fr 1fr",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "btn btn-purple",
                                              attrs: {
                                                to: {
                                                  params: {
                                                    id: user.玩家識別值,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-user-circle-o fa-fw",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("會員資料")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "btn btn-primary",
                                              attrs: {
                                                to: {
                                                  name: "XswGameHistory",
                                                  query: {
                                                    nick: user.玩家識別值,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-history fa-fw",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("玩家歷程")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "btn btn-success",
                                              attrs: {
                                                to: {
                                                  name: "TransactionInfoList",
                                                  query: {
                                                    userId: user.玩家識別值,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-dollar fa-fw",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("儲值歷程")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "btn btn-warning",
                                              attrs: {
                                                to: {
                                                  name: "XswGameMessage",
                                                  query: {
                                                    nick: user.玩家識別值,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-envelope fa-fw",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("信件歷程")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("td", [
                                      _c("div", {
                                        staticStyle: {
                                          width: "max-content",
                                          margin: "0 auto",
                                          "text-align": "left",
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.banStatus(user)
                                          ),
                                        },
                                      }),
                                      _vm.can控管
                                        ? _c(
                                            "p",
                                            {
                                              staticClass: "mt-10",
                                              staticStyle: {
                                                width: "max-content",
                                                margin: "0 auto",
                                              },
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn btn-info",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.moderateDialog.show(
                                                        user
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(_vm.$t("控管")))]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        user.停權狀態 != 0 ||
                                                        user.禁言狀態 != 0,
                                                      expression:
                                                        "user.停權狀態 != 0 || user.禁言狀態 != 0",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-danger ml-5",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.moderateDialog.show(
                                                        user,
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(_vm.$t("解鎖")))]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ])
                                }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "box-footer" },
                    [
                      _c("Pagination", {
                        attrs: {
                          pageLimitCount: _vm.searchData.pageSize,
                          total: _vm.total,
                        },
                        on: { pageMethod: _vm.onPageChange },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          )
        : _c(
            "section",
            { staticClass: "content", staticStyle: { "min-height": "auto" } },
            [
              _c("div", { staticClass: "box box-primary mb-20" }, [
                _c("div", { staticClass: "box-body" }, [
                  _c(
                    "div",
                    { staticClass: "top-box mb-10" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-default pull-right",
                          attrs: { to: _vm.goBack },
                        },
                        [
                          _c("i", { staticClass: "fa fa-reply" }),
                          _vm._v(" " + _vm._s(_vm.$t("返回")) + " "),
                        ]
                      ),
                      _c(
                        "h2",
                        {
                          staticClass: "pull-left mb-5 mt-5",
                          staticStyle: { "font-size": "1.5em" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("玩家暱稱")) +
                              "：" +
                              _vm._s(_vm.user.暱稱) +
                              " "
                          ),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("(" + _vm._s(_vm.id) + ")"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.user.暱稱
                    ? _c("div", { staticClass: "mb-10" }, [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-grid table-data table-bordered table-condensed",
                          },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("登入方式")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t(_vm.user.登入方式))),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("綁定門號")))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.user.綁定門號
                                        ? _vm.user.綁定門號
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("申請日期")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.uiDate(_vm.user.apply))),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("綁定日期")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.uiDate(_vm.user.apply))),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("玩家UID")))]),
                                _c("td", [_vm._v(_vm._s(_vm.user.玩家識別值))]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("暱稱")))]),
                                _c("td", [_vm._v(_vm._s(_vm.user.暱稱))]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("遊戲等級")))]),
                                _c("td", [
                                  _vm._v(
                                    "【" +
                                      _vm._s(_vm.user.階級) +
                                      "】Lv." +
                                      _vm._s(_vm.user.level)
                                  ),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("登入日期")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.uiDate(_vm.user.最後登入))),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("角色狀態")))]),
                                _c("td", [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.banStatus(_vm.user)
                                      ),
                                    },
                                  }),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("登入狀態")))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.user.sid == "1"
                                        ? _vm.$t("在線")
                                        : _vm.$t("離線")
                                    )
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("停權次數")))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.banCount /* banList.filter(i=>i.異動動作 == "角色停權").length || "-" */
                                    )
                                  ),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("停權截止")))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.user.停權狀態 != 0
                                        ? _vm.uiDate(_vm.user.停權截止)
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("公會")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.user.公會 || "-")),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("公會會長")))]),
                                _c("td", [
                                  _vm.user.公會會長
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.user.公會會長)),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("介紹人")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.user.介紹人 || "-")),
                                ]),
                                _c("th"),
                                _c("td"),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("剩餘金幣")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.user.xinvalues) + " "),
                                  _vm.can星幣
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger btn-sm",
                                          staticStyle: {
                                            "margin-left": "auto",
                                          },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.memberCoin(
                                                _vm.user,
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("扣除")) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("剩餘銅幣")))]),
                                _c("td", [_vm._v(_vm._s(_vm.user.銅幣) + " ")]),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "nav-tabs-custom" },
                [
                  _c(
                    "ul",
                    { staticClass: "nav nav-tabs" },
                    [
                      _vm._l(_vm.menuStruct, function (menu, menuIndex) {
                        return [
                          menu.tabs.find((i) => i.permission)
                            ? _c(
                                "li",
                                {
                                  key: menuIndex,
                                  class: { active: menu.active },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          !menu.active &&
                                            _vm.changeTab(menuIndex)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(menu.name)))]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._l(_vm.menuStruct, function (menu, menuIndex) {
                    return [
                      menu.active
                        ? _c(
                            "ul",
                            { key: menuIndex, staticClass: "nav nav-tabs" },
                            [
                              _vm._l(menu.tabs, function (tab, tabIndex) {
                                return [
                                  tab.permission
                                    ? _c(
                                        "li",
                                        {
                                          key: tabIndex,
                                          class: { active: tab.active },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  !tab.active &&
                                                    _vm.changeTab(
                                                      menuIndex,
                                                      tabIndex
                                                    )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t(tab.name)))]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                  _c(
                    "div",
                    { staticClass: "tab-content" },
                    [
                      _c(_vm.activeTab, {
                        tag: "component",
                        attrs: { user: _vm.user, types: _vm.serviceTypes },
                        on: {
                          update: function ($event) {
                            return _vm.getUserInfo()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]
          ),
      _c("MemberModerateDialog", {
        ref: "moderateDialog",
        attrs: { types: _vm.serviceTypes },
        on: {
          update: function ($event) {
            return _vm.getDataList()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }