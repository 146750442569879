var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "GameMessage" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("遊戲信件發送管理")))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v(_vm._s(_vm.$t("首頁"))),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.$t("遊戲信件發送管理"))),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "nav-tabs-custom mb-10" }, [
        _c(
          "div",
          { staticClass: "box-header", staticStyle: { "padding-bottom": "0" } },
          [
            _c(
              "form",
              {
                staticClass: "top-box clearfix",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    _vm.pageNum = 1
                    _vm.getDataList()
                  },
                },
              },
              [
                _vm.can歷程 || _vm.can持有
                  ? _c("div", { staticClass: "pull-left" }, [
                      _c(
                        "div",
                        { staticClass: "input-group pull-left mb-10 mr-10" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.暱稱,
                                expression: "searchData.暱稱",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: _vm.$t("UID") },
                            domProps: { value: _vm.searchData.暱稱 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.searchData,
                                  "暱稱",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "pull-left m-0" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary pull-left mb-10",
                            attrs: { type: "submit" },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("查詢")) + " ")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.can發送
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-block btn-warning btn-add pull-right mb-10",
                        attrs: {
                          to: {
                            name: "XsgGameMessageEdit",
                            params: { nick: _vm.searchData.暱稱 },
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(" " + _vm._s(_vm.$t("新增信件")) + " "),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm.tab != 0
        ? _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
            _c("ul", { staticClass: "nav nav-tabs" }, [
              _c("li", { class: { active: _vm.tab == 1 && _vm.can歷程 } }, [
                _c(
                  "a",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        _vm.tab = 1
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("歷程列表")))]
                ),
              ]),
              _c("li", { class: { active: _vm.tab == 2 && _vm.can持有 } }, [
                _c(
                  "a",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        _vm.tab = 2
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("持有信件列表")))]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tab == 1,
                    expression: "tab == 1",
                  },
                ],
                staticClass: "box-body",
              },
              [
                _c("div", { staticClass: "top-box" }, [
                  _c(
                    "div",
                    { staticClass: "input-group pull-left mb-10 mr-10" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filterString,
                            expression: "filterString",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: _vm.$t("搜尋") },
                        domProps: { value: _vm.filterString },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.filterString = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "select-group pull-left mb-10 mr-10" },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filterState,
                              expression: "filterState",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.filterState = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("全部動作"))),
                          ]),
                          _vm._l(_vm.filterOptions, function (option) {
                            return _c(
                              "option",
                              { domProps: { value: option } },
                              [_vm._v(_vm._s(_vm.$t(option)))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "form-group pull-left m-0" }, [
                    _c(
                      "label",
                      { staticClass: "control-label pull-left mt-7" },
                      [_vm._v(_vm._s(_vm.$t("發送時間")) + "：")]
                    ),
                    _c(
                      "div",
                      { staticClass: "input-group mb-10 mr-10" },
                      [
                        _c("div", {
                          staticClass: "input-group-addon fa fa-calendar",
                        }),
                        _c("DateRangePicker", {
                          attrs: {
                            propEndDate: _vm.searchData.結束時間,
                            propStartDate: _vm.searchData.開始時間,
                            displayFormat: "YYYY/MM/DD HH:mm",
                            format: "YYYY-MM-DD HH:mm:ss",
                          },
                          on: {
                            "update:propEndDate": function ($event) {
                              return _vm.$set(
                                _vm.searchData,
                                "結束時間",
                                $event
                              )
                            },
                            "update:prop-end-date": function ($event) {
                              return _vm.$set(
                                _vm.searchData,
                                "結束時間",
                                $event
                              )
                            },
                            "update:propStartDate": function ($event) {
                              return _vm.$set(
                                _vm.searchData,
                                "開始時間",
                                $event
                              )
                            },
                            "update:prop-start-date": function ($event) {
                              return _vm.$set(
                                _vm.searchData,
                                "開始時間",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "pull-left m-0" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary pull-left mb-10 mr-10",
                        on: {
                          click: function ($event) {
                            _vm.pageNum = 1
                            _vm.getDataList()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("查詢")) + " ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default pull-left mb-10",
                        attrs: { disabled: _vm.datas.length === 0 },
                        on: {
                          click: function ($event) {
                            return _vm.exportHistory()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("匯出")) + " ")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "table-responsive mt-0" }, [
                  _c(
                    "table",
                    { staticClass: "table table-bordered text-center" },
                    [
                      _c(
                        "tbody",
                        [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("信件編號")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "105px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("寄件者")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "105px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("收件者")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("信件動作")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("物品編號")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("數量")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "105px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("時間")))]
                            ),
                          ]),
                          _vm.message
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "8" } }, [
                                  _vm._v(_vm._s(_vm.$t(_vm.message))),
                                ]),
                              ])
                            : _vm.filterMessage
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "8" } }, [
                                  _vm._v(_vm._s(_vm.$t(_vm.filterMessage))),
                                ]),
                              ])
                            : _vm._l(_vm.pagedData, function (item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: { "bg-gray-light": item.狀態 === 3 },
                                  },
                                  [
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(item.信件編號),
                                      },
                                    }),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(item.寄件者),
                                      },
                                    }),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(item.收件者),
                                      },
                                    }),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(item.信件動作)
                                        ),
                                      },
                                    }),
                                    _c("td", [
                                      !["0", "1", "3"].includes(item.物品編號)
                                        ? _c("span", [_vm._v("-")])
                                        : _c(
                                            "span",
                                            { attrs: { title: item.物品編號 } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.gameTypes[item.物品編號]
                                                  )
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          item.數量 != "0" ? item.數量 : "-"
                                        )
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.uiDate(item.時間))),
                                    ]),
                                  ]
                                )
                              }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "box-footer text-center no-border" },
                  [
                    _c("Pagination", {
                      attrs: { pageLimitCount: _vm.pageSize, total: _vm.total },
                      on: { pageMethod: _vm.onPageChange },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tab == 2,
                    expression: "tab == 2",
                  },
                ],
                staticClass: "box-body",
              },
              [
                _c("div", { staticClass: "top-box" }, [
                  _c(
                    "div",
                    { staticClass: "input-group pull-left mb-10 mr-10" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filterHoldString,
                            expression: "filterHoldString",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: _vm.$t("搜尋") },
                        domProps: { value: _vm.filterHoldString },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.filterHoldString = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "select-group pull-left mb-10 mr-10" },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filterHoldState,
                              expression: "filterHoldState",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.filterHoldState = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("領取狀態"))),
                          ]),
                          _c("option", { attrs: { value: "true" } }, [
                            _vm._v(_vm._s(_vm.$t("已領取"))),
                          ]),
                          _c("option", { attrs: { value: "false" } }, [
                            _vm._v(_vm._s(_vm.$t("未領取"))),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "pull-left m-0" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default pull-left mb-10",
                        attrs: { disabled: _vm.holdList.length === 0 },
                        on: {
                          click: function ($event) {
                            return _vm.exportHold()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("匯出")) + " ")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "table-responsive mt-0" }, [
                  _c(
                    "table",
                    { staticClass: "table table-bordered text-center" },
                    [
                      _c(
                        "tbody",
                        [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("信件編號")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "105px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("寄件者")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "105px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("標題")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("領取狀態")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("物品編號")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("數量")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "105px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("領取期限")))]
                            ),
                          ]),
                          _vm.holdMessage
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "8" } }, [
                                  _vm._v(_vm._s(_vm.$t(_vm.holdMessage))),
                                ]),
                              ])
                            : _vm.filterHoldMessage
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "8" } }, [
                                  _vm._v(_vm._s(_vm.$t(_vm.filterHoldMessage))),
                                ]),
                              ])
                            : _vm._l(_vm.pagedHoldData, function (item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: { "bg-gray-light": item.狀態 === 3 },
                                  },
                                  [
                                    _c("td", {
                                      domProps: { innerHTML: _vm._s(item.id) },
                                    }),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(item.寄件者),
                                      },
                                    }),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(item.標題),
                                      },
                                    }),
                                    _c(
                                      "td",
                                      {
                                        class: item.已領取
                                          ? "text-green"
                                          : "text-red",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              item.已領取 ? "已領取" : "未領取"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("td", [
                                      !["0", "1", "3"].includes(item.物品編號)
                                        ? _c("span", [_vm._v("-")])
                                        : _c(
                                            "span",
                                            { attrs: { title: item.物品編號 } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.gameTypes[item.物品編號]
                                                  )
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(item.數量 || "-")),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.uiDate(item.期限))),
                                    ]),
                                  ]
                                )
                              }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "box-footer text-center no-border" },
                  [
                    _c("Pagination", {
                      attrs: {
                        pageLimitCount: _vm.holdPageSize,
                        total: _vm.holdTotal,
                      },
                      on: { pageMethod: _vm.onPageChange },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }