var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("圖片管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("圖片管理")]),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.id))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "top-box with-border" },
            [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.id == "New" ? "新增" : "編輯") + "內容"),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: {
                    to: {
                      name: "XsgWebBanner",
                      params: { searchData: _vm.$route.params.searchData },
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ],
            1
          ),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.Save()
                },
              },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "inputTitle" },
                    },
                    [_vm._v("類別：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.類別,
                            expression: "edit.類別",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { "max-width": "320px" },
                        attrs: { id: "webBannerType", required: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "類別",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.types.webBannerType, function (name, key) {
                        return _c(
                          "option",
                          { key: key, domProps: { value: name } },
                          [_vm._v(_vm._s(name))]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "inputTitle" },
                    },
                    [_vm._v("標題：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.標題,
                          expression: "edit.標題",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "inputTitle",
                        required: "",
                        placeholder: "",
                      },
                      domProps: { value: _vm.edit.標題 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "標題", $event.target.value)
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.edit.類別 == "首頁看版管理",
                            expression: "edit.類別 == '首頁看版管理'",
                          },
                        ],
                        staticClass: "mt-7",
                      },
                      [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          domProps: { checked: !!_vm.edit.是否顯示標題 },
                          on: {
                            change: function ($event) {
                              _vm.edit.是否顯示標題 =
                                +$event.currentTarget.checked
                            },
                          },
                        }),
                        _vm._v(" 顯示標題 "),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "inputContent" },
                    },
                    [_vm._v("內容：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.內容,
                          expression: "edit.內容",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "inputContent", rows: "3", placeholder: "" },
                      domProps: { value: _vm.edit.內容 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "內容", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "AdPic" },
                    },
                    [_vm._v("顯示圖片：")]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c(
                        "ImageSelect",
                        {
                          ref: "imageSelect1",
                          attrs: {
                            id: "AdPic1",
                            gid: _vm.edit.id,
                            info: "1",
                            module: "WebBanner",
                            maxsize: 500 * 1024,
                            accept:
                              "image/png, image/jpeg, image/webp, image/gif",
                            required: "",
                          },
                          model: {
                            value: _vm.edit.files,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "files", $$v)
                            },
                            expression: "edit.files",
                          },
                        },
                        [
                          _vm.edit.類別 == _vm.types.webBannerType[0]
                            ? _c("p", { staticClass: "help-block" }, [
                                _vm._v("* 圖片尺寸 "),
                                _c("span", { staticClass: "text-red" }, [
                                  _vm._v("1920×720"),
                                ]),
                                _vm._v(
                                  "，檔案格式為webp, jpg, png, gif，大小上限500KB。"
                                ),
                              ])
                            : _vm._e(),
                          _vm.edit.類別 == _vm.types.webBannerType[1]
                            ? _c("p", { staticClass: "help-block" }, [
                                _vm._v("* 圖片尺寸 "),
                                _c("span", { staticClass: "text-red" }, [
                                  _vm._v("265×355"),
                                ]),
                                _vm._v(
                                  "，檔案格式為webp, jpg, png, gif，大小上限500KB。"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "form-group bannerlink-part" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "inputLink" },
                    },
                    [_vm._v("連結：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.連結,
                          expression: "edit.連結",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "url", id: "inputLink", required: "" },
                      domProps: { value: _vm.edit.連結 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "連結", $event.target.value)
                        },
                      },
                    }),
                    _c("label", { staticClass: "mt-7" }, [
                      _c("input", {
                        attrs: { type: "checkbox" },
                        domProps: { checked: !!_vm.edit.是否另開視窗 },
                        on: {
                          change: function ($event) {
                            _vm.edit.是否另開視窗 =
                              +$event.currentTarget.checked
                          },
                        },
                      }),
                      _vm._v(" 另開視窗 "),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "inputOrderNum" },
                    },
                    [_vm._v("排序：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.edit.排序,
                          expression: "edit.排序",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", id: "inputOrderNum", min: "0" },
                      domProps: { value: _vm.edit.排序 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "排序",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { staticClass: "col-sm-3 control-label required" },
                    [_vm._v("開始時間：")]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("DateAndTimePicker", {
                        attrs: { format: "YYYY-MM-DD HH:mm:ss" },
                        model: {
                          value: _vm.edit.開始時間,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "開始時間", $$v)
                          },
                          expression: "edit.開始時間",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { staticClass: "col-sm-3 control-label required" },
                    [_vm._v("結束時間：")]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("DateAndTimePicker", {
                        attrs: { format: "YYYY-MM-DD HH:mm:ss" },
                        model: {
                          value: _vm.edit.結束時間,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "結束時間", $$v)
                          },
                          expression: "edit.結束時間",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._m(0),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-footer" }, [
      _c("div", { staticClass: "btns" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-lg fa fa-save",
            attrs: { type: "submit" },
          },
          [_vm._v(" 確認送出 ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }