var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    { staticClass: "select2", attrs: { multiple: "multiple" } },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }