var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "searchList", attrs: { id: "BeforeShareList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("活動虛寶查詢")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("活動虛寶查詢")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c(
              "div",
              {
                staticClass: "top-box",
                staticStyle: { "margin-bottom": "10px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "date-group", staticStyle: { width: "auto" } },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("活動期間："),
                    ]),
                    _c("div", { staticClass: "input-group date datetime" }, [
                      _vm._m(0),
                      _c(
                        "div",
                        { staticClass: "pull-right" },
                        [
                          _c("DateRangePicker", {
                            attrs: {
                              propTimePicker: true,
                              propEndDate: _vm.searchData.endTime,
                              propStartDate: _vm.searchData.startTime,
                            },
                            on: {
                              "update:propEndDate": function ($event) {
                                return _vm.$set(
                                  _vm.searchData,
                                  "endTime",
                                  $event
                                )
                              },
                              "update:prop-end-date": function ($event) {
                                return _vm.$set(
                                  _vm.searchData,
                                  "endTime",
                                  $event
                                )
                              },
                              "update:propStartDate": function ($event) {
                                return _vm.$set(
                                  _vm.searchData,
                                  "startTime",
                                  $event
                                )
                              },
                              "update:prop-start-date": function ($event) {
                                return _vm.$set(
                                  _vm.searchData,
                                  "startTime",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "select-group",
                    staticStyle: { width: "200px" },
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.activeName,
                            expression: "searchData.activeName",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "activeName",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { value: "滿額儲值活動第一輪" } },
                          [_vm._v("滿額儲值活動第一輪(3/14~5/17 23:59)")]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "滿額儲值活動第二輪" } },
                          [_vm._v("滿額儲值活動第二輪(5/18~7/31 12:01)")]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "2023心理測驗抽獎活動" } },
                          [_vm._v("心理測驗抽獎活動")]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "嘉年華滿額儲值活動" } },
                          [_vm._v("Summer嘉年華活動(8/2~8/31)")]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "滿額儲值活動第四輪" } },
                          [_vm._v("滿額儲值活動第四輪(8/31~10/2 12:00)")]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "半週年限定儲值回饋" } },
                          [_vm._v("半週年限定儲值回饋(8/31~10/2 12:00)")]
                        ),
                        _c("option", { attrs: { value: "熊本熊神秘好禮" } }, [
                          _vm._v("熊本熊神秘好禮(9/13~9/27 23:59)"),
                        ]),
                        _c(
                          "option",
                          { attrs: { value: "滿額儲值活動第五輪" } },
                          [_vm._v("滿額儲值活動第五輪(10/2-11/30)")]
                        ),
                        _c("option", { attrs: { value: "2023萬聖節活動" } }, [
                          _vm._v("萬聖活動(10/11-11/9)"),
                        ]),
                        _c(
                          "option",
                          { attrs: { value: "滿額儲值活動第六輪" } },
                          [_vm._v("滿額儲值活動第六輪(11/30~1/31)")]
                        ),
                        _c("option", { attrs: { value: "2023聖誕節活動" } }, [
                          _vm._v("聖誕活動(11/30~12/31)"),
                        ]),
                        _c(
                          "option",
                          { attrs: { value: "聖誕月特典-閃耀驚喜特別回饋" } },
                          [_vm._v("聖誕月閃耀驚喜特別回饋(12/20~1/3 10:00)")]
                        ),
                        _c(
                          "option",
                          {
                            attrs: {
                              value: "加帕里歡迎會！歡慶星城聯名特別活動",
                            },
                          },
                          [_vm._v("星城聯名活動(12/27~2/15)")]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "滿額儲值活動第七輪" } },
                          [_vm._v("滿額儲值活動第七輪(1/31~4/1)")]
                        ),
                        _c("option", { attrs: { value: "2024過年填字趣" } }, [
                          _vm._v("新年每日登入活動(1/31~2/29)"),
                        ]),
                        _c("option", { attrs: { value: "一周年活動頁" } }, [
                          _vm._v("[週年好禮]一周年活動頁(3/13~4/17)"),
                        ]),
                        _c(
                          "option",
                          { attrs: { value: "周年活動猜猜我是誰登入活動" } },
                          [_vm._v("[登入活動]一周年活動頁(3/13~4/17)")]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "滿額儲值活動第八輪" } },
                          [_vm._v("滿額儲值活動第八輪(4/1~6/5)")]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "2024勞動節登入活動" } },
                          [_vm._v("勞動節登入活動(4/30~6/5)")]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "2024端午節登入活動" } },
                          [_vm._v("2024端午節登入活動(6/5~7/7)")]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "滿額儲值活動第九輪" } },
                          [_vm._v("滿額儲值活動第九輪(6/5~8/7)")]
                        ),
                        _c("option", { attrs: { value: "2024夏日登入活動" } }, [
                          _vm._v("2024夏日登入活動(7/3~8/6)"),
                        ]),
                        _c(
                          "option",
                          { attrs: { value: "滿額儲值活動第十輪" } },
                          [_vm._v("滿額儲值活動第十輪(8/7~10/9)")]
                        ),
                        _c("option", { attrs: { value: "2024七夕登入活動" } }, [
                          _vm._v("2024七夕登入活動(8/7~9/10)"),
                        ]),
                        _c(
                          "option",
                          {
                            attrs: { value: "欲速則不達？黑曼巴蛇的大奮鬥！" },
                          },
                          [_vm._v("[前夜祭]1.5周年活動(9/4~10/9)")]
                        ),
                        _c("option", { attrs: { value: "1.5周年登入活動" } }, [
                          _vm._v("[週年好禮]1.5周年活動(9/11~10/9)"),
                        ]),
                        _c(
                          "option",
                          { attrs: { value: "滿額儲值活動通用版" } },
                          [_vm._v("滿額儲值活動通用版(10/9~)")]
                        ),
                        _c("option", { attrs: { value: "2024萬聖節活動" } }, [
                          _vm._v("2024萬聖節活動 (10/23~11/27 23:59)"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm.searchData.activeName ==
                "加帕里歡迎會！歡慶星城聯名特別活動"
                  ? _c("div", { staticClass: "select-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.awardsId,
                              expression: "searchData.awardsId",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "awardsId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.resetDataList()
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("請選擇身分"),
                          ]),
                          _c("option", { attrs: { value: "新手" } }, [
                            _vm._v("新手"),
                          ]),
                          _c("option", { attrs: { value: "老手" } }, [
                            _vm._v("老手"),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "select-group",
                    staticStyle: { width: "auto" },
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.searchData.RewardId,
                            expression: "searchData.RewardId",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.$set(
                                _vm.searchData,
                                "RewardId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.resetDataList()
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("獲得虛寶"),
                        ]),
                        _vm._l(_vm.rewardList, function (reward, index) {
                          return _c(
                            "option",
                            { domProps: { value: reward.rewardId } },
                            [
                              reward.rewardLevel
                                ? _c("span", [
                                    _vm._v(_vm._s(reward.rewardLevel) + " - "),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(reward.rewardName) +
                                  _vm._s(
                                    reward.rewardAmount != null
                                      ? `x${reward.rewardAmount}`
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                ),
                _c("div", { staticClass: "input-group search-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.friendCode,
                        expression: "searchData.friendCode",
                      },
                    ],
                    staticClass: "form-control pull-right",
                    attrs: { type: "text", placeholder: "搜尋遊戲ID" },
                    domProps: { value: _vm.searchData.friendCode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "friendCode",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("div", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.resetDataList()
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-search" })]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "text" }, [
                  _c("p", { staticClass: "data-num" }, [
                    _c("span", [_vm._v("資料總筆數：" + _vm._s(_vm.total))]),
                  ]),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-success btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.exportOrders()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-upload" }),
                    _vm._v(" 資料匯出 "),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                { staticClass: "table table-bordered text-center min-1000" },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _c("th", [_vm._v("#")]),
                        _c(
                          "th",
                          {
                            staticClass: "text-nowrap",
                            staticStyle: { "min-width": "150px" },
                          },
                          [_vm._v("活動名稱")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-nowrap",
                            staticStyle: { "min-width": "150px" },
                          },
                          [_vm._v("遊戲ID")]
                        ),
                        _vm.searchData.activeName ==
                        "加帕里歡迎會！歡慶星城聯名特別活動"
                          ? _c(
                              "th",
                              {
                                staticClass: "text-nowrap",
                                staticStyle: { "min-width": "150px" },
                              },
                              [_vm._v("身分")]
                            )
                          : _vm._e(),
                        _c(
                          "th",
                          {
                            staticClass: "text-nowrap",
                            staticStyle: { "min-width": "150px" },
                          },
                          [_vm._v("配發ID")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-nowrap",
                            staticStyle: { "min-width": "150px" },
                          },
                          [_vm._v("獲得虛寶")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-nowrap",
                            staticStyle: { "min-width": "150px" },
                          },
                          [_vm._v(" 虛寶派發時間 ")]
                        ),
                      ]),
                      _vm.loading
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "5" } }, [
                              _vm._v("查詢中"),
                            ]),
                          ])
                        : _vm.datas.length === 0
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "5" } }, [
                              _vm._v("查詢無任何資料"),
                            ]),
                          ])
                        : _vm._l(_vm.datas, function (item, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_vm._v(" " + _vm._s(index + 1) + " ")]),
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.searchData.activeName) + " "
                                ),
                                _vm.searchData.activeName ==
                                "滿額儲值活動第一輪"
                                  ? _c("span", [_vm._v("(3/14~5/17 23:59)")])
                                  : _vm._e(),
                                _vm.searchData.activeName ==
                                "滿額儲值活動第二輪"
                                  ? _c("span", [_vm._v("(5/18~7/31 12:01)")])
                                  : _vm._e(),
                              ]),
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v(" " + _vm._s(item.friendCode) + " "),
                              ]),
                              _vm.searchData.activeName ==
                              "加帕里歡迎會！歡慶星城聯名特別活動"
                                ? _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(" " + _vm._s(item.awardsId) + " "),
                                  ])
                                : _vm._e(),
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v(" " + _vm._s(item.rewardId) + " "),
                              ]),
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v(" " + _vm._s(item.information) + " "),
                              ]),
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        item.createTime,
                                        "YYYY/MM/DD HH:mm:ss"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ])
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }