var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("類別與標籤管理")))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v(_vm._s(_vm.$t("首頁"))),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.$t("類別與標籤管理"))),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box mb-10" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.add()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("新增")) + " ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _vm.message && _vm.datas.length == 0
                ? _c("p", [_vm._v(_vm._s(_vm.message))])
                : _vm.datas.length == 0
                ? _c("p", [_vm._v(_vm._s("無資料"))])
                : _vm._l(_vm.datas, function (group, groupIndex) {
                    return _c(
                      "table",
                      {
                        key: groupIndex,
                        staticClass:
                          "table table-bordered table-condensed table-striped text-center mb-10",
                      },
                      [
                        _c(
                          "tbody",
                          [
                            _c("tr", { staticClass: "bg-gray" }, [
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "min-width": "150px",
                                    width: "15%",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("標籤類別")))]
                              ),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "min-width": "100px",
                                    width: "15%",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("狀態")))]
                              ),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "min-width": "100px",
                                    width: "55%",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("標籤")))]
                              ),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "min-width": "140px",
                                    width: "15%",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("功能")))]
                              ),
                            ]),
                            _vm._l(group, function (item, index) {
                              return _c("tr", { key: item.id }, [
                                index == 0
                                  ? _c(
                                      "td",
                                      { attrs: { rowspan: group.length } },
                                      [
                                        !item.isNew || group.length > 1
                                          ? _c("p", { staticClass: "m-0" }, [
                                              _vm._v(_vm._s(item.標籤類別)),
                                            ])
                                          : _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.標籤類別,
                                                  expression: "item.標籤類別",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder: "標籤類別",
                                                required: "",
                                                disabled:
                                                  !item.isEdit && _vm.isEditing,
                                              },
                                              domProps: {
                                                value: item.標籤類別,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    item,
                                                    "標籤類別",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("td", [
                                  _c(
                                    "fieldset",
                                    {
                                      staticClass:
                                        "ckbx-style-8 ckbx toggleInput",
                                      style:
                                        !_vm.can修改 ||
                                        (_vm.isEditing && !item.isEdit)
                                          ? "opacity:.5;pointer-events:none"
                                          : false,
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.狀態,
                                            expression: "item.狀態",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: `check${groupIndex}-${index}`,
                                          name: "ckbx-style-8",
                                        },
                                        domProps: {
                                          checked: Array.isArray(item.狀態)
                                            ? _vm._i(item.狀態, null) > -1
                                            : item.狀態,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a = item.狀態,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      item,
                                                      "狀態",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      item,
                                                      "狀態",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(item, "狀態", $$c)
                                              }
                                            },
                                            function ($event) {
                                              return _vm.changeStatus(
                                                groupIndex,
                                                index
                                              )
                                            },
                                          ],
                                        },
                                      }),
                                      _c("label", {
                                        attrs: {
                                          for: `check${groupIndex}-${index}`,
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _c("td", { staticClass: "text-left" }, [
                                  !item.isEdit
                                    ? _c("span", [_vm._v(_vm._s(item.標籤))])
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.標籤,
                                            expression: "item.標籤",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: "標籤",
                                        },
                                        domProps: { value: item.標籤 },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              item,
                                              "標籤",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                ]),
                                _c("td", [
                                  _vm.can修改
                                    ? _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !item.isEdit,
                                              expression: "!item.isEdit",
                                            },
                                          ],
                                          staticClass: "btn btn-primary",
                                          attrs: { disabled: _vm.isEditing },
                                          on: {
                                            click: function ($event) {
                                              return _vm.edit(groupIndex, index)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("修改")))]
                                      )
                                    : _vm._e(),
                                  _vm.can新增
                                    ? _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !item.isEdit,
                                              expression: "!item.isEdit",
                                            },
                                          ],
                                          staticClass: "btn btn-warning ml-5",
                                          style:
                                            index == 0
                                              ? ""
                                              : "opacity:0; pointer-events:none;",
                                          attrs: { disabled: _vm.isEditing },
                                          on: {
                                            click: function ($event) {
                                              return _vm.add(groupIndex)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("新增")))]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.isEdit,
                                          expression: "item.isEdit",
                                        },
                                      ],
                                      staticClass: "btn btn-success",
                                      on: {
                                        click: function ($event) {
                                          return _vm.save(groupIndex, index)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("儲存")))]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.isEdit,
                                          expression: "item.isEdit",
                                        },
                                      ],
                                      staticClass: "btn btn-default ml-5",
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancel(groupIndex, index)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("取消")))]
                                  ),
                                ]),
                              ])
                            }),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }