var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲介紹列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲介紹管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "form",
            {
              staticClass: "top-box",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.clickSearch()
                },
              },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-warning btn-add mb-10",
                  attrs: {
                    to: { name: "AWH5GameIntroEdit", params: { id: "New" } },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增遊戲 ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-add mb-10 mr-10",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.clearCatch()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-refresh" }),
                  _vm._v(" 清除Cache "),
                ]
              ),
              _c("div", { staticClass: "select-group mb-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.遊戲分類,
                        expression: "searchData.遊戲分類",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "遊戲分類",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.clickSearch()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("全部遊戲分類"),
                    ]),
                    _vm._l(_vm.classTypes, function (item, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: item.classTypeId } },
                        [_vm._v(_vm._s(item.className))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "select-group mb-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.是否發佈,
                        expression: "searchData.是否發佈",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "是否發佈",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.clickSearch()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "null" } }, [
                      _vm._v("上/下架"),
                    ]),
                    _c("option", { attrs: { value: "true" } }, [
                      _vm._v("上架"),
                    ]),
                    _c("option", { attrs: { value: "false" } }, [
                      _vm._v("下架"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "select-group mb-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.語系,
                        expression: "searchData.語系",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "語系",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.getDataList()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.langs, function (lang) {
                    return _c("option", { domProps: { value: lang.id } }, [
                      _vm._v(_vm._s(lang.id + " " + lang.name)),
                    ])
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "input-group mb-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.遊戲名稱,
                      expression: "searchData.遊戲名稱",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: { type: "text", placeholder: "搜尋遊戲名稱" },
                  domProps: { value: _vm.searchData.遊戲名稱 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "遊戲名稱", $event.target.value)
                    },
                  },
                }),
                _vm._m(0),
              ]),
              _c("div", { staticClass: "text mb-10" }, [
                _c("p", { staticClass: "data-num" }, [
                  _vm._v("資料總筆數: "),
                  _c("span", [_vm._v(_vm._s(_vm.total))]),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered text-center" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm.message
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "10" } }, [
                            _vm._v(_vm._s(_vm.message)),
                          ]),
                        ])
                      : _vm.total <= 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "10" } }, [
                            _vm._v("查詢無任何資料"),
                          ]),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: item.gid }, [
                            _c("td", [_vm._v(_vm._s(item.遊戲館))]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.getClassTypeName(item)) + " "),
                            ]),
                            _c("td", [
                              _c("img", {
                                staticStyle: { "max-height": "100px" },
                                attrs: { src: _vm.getLogoUrl(item), alt: "" },
                              }),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.getName(item)))]),
                            _c("td", [
                              _c(
                                "fieldset",
                                {
                                  staticClass: "ckbx-style-8 ckbx toggleInput",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.是否發佈,
                                        expression: "item.是否發佈",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      "true-value": true,
                                      "false-value": false,
                                      id: "check" + index,
                                    },
                                    domProps: {
                                      checked: Array.isArray(item.是否發佈)
                                        ? _vm._i(item.是否發佈, null) > -1
                                        : item.是否發佈,
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = item.是否發佈,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "是否發佈",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "是否發佈",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "是否發佈", $$c)
                                          }
                                        },
                                        function ($event) {
                                          return _vm.toggle(item)
                                        },
                                      ],
                                    },
                                  }),
                                  _c("label", {
                                    attrs: { for: "check" + index },
                                  }),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    gap: "5px",
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: item.排序,
                                        expression: "item.排序",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    staticClass: "form-control p-5",
                                    staticStyle: {
                                      "padding-right": "0",
                                      "text-align": "center",
                                    },
                                    attrs: {
                                      type: "number",
                                      min: "1",
                                      max: "",
                                      disabled: !item.是否發佈,
                                    },
                                    domProps: { value: item.排序 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "排序",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-warning btn-add btn-upDate",
                                      attrs: {
                                        type: "button",
                                        disabled: !item.是否發佈,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateOrder(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-arrows-v",
                                      }),
                                      _vm._v(" 更新 "),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    gap: "5px",
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: {
                                        to: {
                                          name: "AWH5GameIntroEdit",
                                          params: { id: item.gid },
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-fw fa-pencil",
                                      }),
                                      _vm._v(" 修改 "),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-warning",
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyItem(item.gid)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-fw fa-copy",
                                      }),
                                      _vm._v(" 複製 "),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-fw fa-close",
                                      }),
                                      _vm._v(" 刪除 "),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total,
                expression: "total",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
                type2: true,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-btn" }, [
      _c(
        "button",
        { staticClass: "btn btn-default", attrs: { type: "submit" } },
        [_c("i", { staticClass: "fa fa-search" })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
        _vm._v("遊戲編號"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "10%" } }, [
        _vm._v("分類"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "15%" } }, [
        _vm._v("遊戲Logo"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "15%" } }, [
        _vm._v("遊戲名稱"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "3%" } }, [
        _vm._v("排序"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "3%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }