var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "popForm", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                "aria-label": "Close",
                "data-dismiss": "modal",
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          this.isEdit
            ? _c("h4", { staticClass: "modal-title" }, [
                _vm._v("修改退貨：" + _vm._s(_vm.edit.訂單編號)),
              ])
            : _c("h4", { staticClass: "modal-title" }, [
                _vm._v("新增退貨：" + _vm._s(_vm.edit.訂單編號)),
              ]),
        ]),
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "form-group" }, [
            _vm._m(0),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.edit.門號,
                  expression: "edit.門號",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "請輸入門號 ex:886919111111",
              },
              domProps: { value: _vm.edit.門號 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.edit, "門號", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(1),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.edit.收款人,
                  expression: "edit.收款人",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "請輸入收款人" },
              domProps: { value: _vm.edit.收款人 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.edit, "收款人", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(2),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.edit.退貨金額,
                  expression: "edit.退貨金額",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "請輸入退貨金額" },
              domProps: { value: _vm.edit.退貨金額 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.edit, "退貨金額", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("銀行代碼")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.edit.銀行代碼,
                  expression: "edit.銀行代碼",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "請輸入銀行代碼" },
              domProps: { value: _vm.edit.銀行代碼 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.edit, "銀行代碼", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("銀行戶名")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.edit.銀行戶名,
                  expression: "edit.銀行戶名",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "請輸入銀行戶名" },
              domProps: { value: _vm.edit.銀行戶名 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.edit, "銀行戶名", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("銀行分行")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.edit.銀行分行,
                  expression: "edit.銀行分行",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "請輸入銀行分行" },
              domProps: { value: _vm.edit.銀行分行 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.edit, "銀行分行", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("銀行帳號")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.edit.銀行帳號,
                  expression: "edit.銀行帳號",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "請輸入銀行帳號" },
              domProps: { value: _vm.edit.銀行帳號 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.edit, "銀行帳號", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("備註")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.edit.備註,
                  expression: "edit.備註",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "請輸入備註" },
              domProps: { value: _vm.edit.備註 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.edit, "備註", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _vm._m(3),
          _c("br"),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v("加入")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_vm._v("關閉")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("門號"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("收款人"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("退貨金額"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "text-red" }, [
      _vm._v(" *GASH金流退貨時，會同時刷退。"),
      _c("br"),
      _vm._v(" 請務必注意退貨訂單是否正確。"),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }