var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "DepositList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("交易明細")))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v(_vm._s(_vm.$t("首頁"))),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.$t("交易明細"))),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary mb-10" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "form",
            {
              staticClass: "top-box clearfix",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  _vm.resetDataList()
                },
              },
            },
            [
              _c("div", { staticClass: "pull-left input-group mb-10 mr-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.暱稱,
                      expression: "searchData.暱稱",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "玩家暱稱" },
                  domProps: { value: _vm.searchData.暱稱 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "暱稱",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "pull-left input-group mb-10 mr-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.交易ID,
                      expression: "searchData.交易ID",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "訂單編號" },
                  domProps: { value: _vm.searchData.交易ID },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "交易ID",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "pull-left form-group mb-10 mr-10",
                  staticStyle: { width: "max-content", "max-width": "100%" },
                },
                [
                  _c("label", { staticClass: "pull-left mt-7 control-label" }, [
                    _vm._v(_vm._s(_vm.$t("查詢區間")) + "："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "pull-left input-group date" },
                    [
                      _c("div", {
                        staticClass: "input-group-addon fa fa-calendar",
                      }),
                      _c("DateRangePicker", {
                        attrs: {
                          propTimePicker: false,
                          propEndDate: _vm.local.endTime,
                          propStartDate: _vm.local.startTime,
                          format: "YYYY-MM-DD",
                        },
                        on: {
                          "update:propEndDate": function ($event) {
                            return _vm.$set(_vm.local, "endTime", $event)
                          },
                          "update:prop-end-date": function ($event) {
                            return _vm.$set(_vm.local, "endTime", $event)
                          },
                          "update:propStartDate": function ($event) {
                            return _vm.$set(_vm.local, "startTime", $event)
                          },
                          "update:prop-start-date": function ($event) {
                            return _vm.$set(_vm.local, "startTime", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "pull-left mb-10 mr-10" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-10",
                    attrs: { type: "submit" },
                    on: { click: function ($event) {} },
                  },
                  [_vm._v(" 查詢 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button", disabled: _vm.datas.length === 0 },
                    on: {
                      click: function ($event) {
                        return _vm.exportOrders()
                      },
                    },
                  },
                  [_vm._v(" 匯出 ")]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "pull-left form-group mb-10",
                  staticStyle: {
                    width: "max-content",
                    "max-width": "100%",
                    height: "34px",
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "pull-left mt-7 control-label",
                      attrs: { for: "searchBatch" },
                    },
                    [_vm._v(_vm._s(_vm.$t("交易序號批次查詢")) + "：")]
                  ),
                  _c("input", { attrs: { type: "file", id: "searchBatch" } }),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "total-data" }, [
            _c("b", [_vm._v("點數合計：" + _vm._s(_vm.totalMoney))]),
            _c("b", [_vm._v("不重複暱稱合計：" + _vm._s(_vm.totalUnique))]),
            _c("b", [_vm._v("帳單總筆數：" + _vm._s(_vm.total))]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "nav-tabs-custom tab-warning mb-10" }, [
        _c("ul", { staticClass: "nav nav-tabs" }, [
          _c("li", { class: { active: _vm.tab == 1 } }, [
            _c(
              "a",
              {
                staticStyle: { cursor: "pointer" },
                attrs: { "aria-expanded": "true", "data-toggle": "tab" },
                on: {
                  click: function ($event) {
                    return _vm.setTab(1)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("點數合計查詢")))]
            ),
          ]),
          _c("li", { class: { active: _vm.tab == 2 } }, [
            _c(
              "a",
              {
                staticStyle: { cursor: "pointer" },
                attrs: { "aria-expanded": "true", "data-toggle": "tab" },
                on: {
                  click: function ($event) {
                    return _vm.setTab(2)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("帳單查詢")))]
            ),
          ]),
        ]),
        _vm.tab == 1
          ? _c("div", { staticClass: "tab-content" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c(
                  "table",
                  {
                    staticClass: "table table-bordered table-hover text-center",
                  },
                  [
                    _vm._m(0),
                    _c(
                      "tbody",
                      [
                        _vm.loading
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "10" } }, [
                                _vm._v("查詢中"),
                              ]),
                            ])
                          : _vm.message
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "10" } }, [
                                _vm._v(_vm._s(_vm.message)),
                              ]),
                            ])
                          : _vm.datas.length <= 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "10" } }, [
                                _vm._v("查詢無資料"),
                              ]),
                            ])
                          : _vm._l(_vm.datas, function (item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [_vm._v(_vm._s(item.平台商品ID))]),
                                _c("td", [_vm._v(_vm._s(item.獲得雀幣))]),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.tab == 2
          ? _c("div", { staticClass: "tab-content" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c(
                  "table",
                  {
                    staticClass: "table table-bordered table-hover text-center",
                  },
                  [
                    _vm._m(1),
                    _c(
                      "tbody",
                      [
                        _vm.loading
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "10" } }, [
                                _vm._v("查詢中"),
                              ]),
                            ])
                          : _vm.message
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "10" } }, [
                                _vm._v(_vm._s(_vm.message)),
                              ]),
                            ])
                          : _vm.datas.length <= 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "10" } }, [
                                _vm._v("查詢無資料"),
                              ]),
                            ])
                          : _vm._l(_vm.datas, function (item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.uiDate(item.訂單最後處理時間))
                                  ),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "NmjPlayerData",
                                            query: { nick: item.暱稱 },
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.暱稱))]
                                    ),
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v(_vm._s(item.訂單編號))]),
                                _c("td", [_vm._v(_vm._s(item.平台商品ID))]),
                                _c("td", [_vm._v(_vm._s(item.商品名稱))]),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "text-center mt-10" },
                [
                  _c("Pagination", {
                    ref: "pagination",
                    attrs: {
                      pageLimitCount: _vm.searchData.pageSize,
                      total: _vm.total,
                    },
                    on: { pageMethod: _vm.onPageChange },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("日期"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("點數合計"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "50px", width: "3%" } }, [
          _vm._v("編號"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("交易序號"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("暱稱"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "5%" } }, [
          _vm._v("點數"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("配點時間"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }