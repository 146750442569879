var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _vm._m(0),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { name: "AWH5WebHome" } } }, [
              _vm._v("官網管理"),
            ]),
          ],
          1
        ),
        _c(
          "li",
          [_c("router-link", { attrs: { to: _vm.goBack } }, [_vm._v("遊戲")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.id))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "top-box with-border" },
            [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.isEdit ? "編輯" : "新增") + "內容"),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-default btn-cog pull-right",
                  attrs: { to: _vm.goBack },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ],
            1
          ),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-body",
                  staticStyle: { "max-width": "900px" },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("fieldset", { staticClass: "col-sm-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "required",
                          attrs: { for: "inputType" },
                        },
                        [_vm._v("遊戲分類：")]
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.遊戲分類,
                              expression: "edit.遊戲分類",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "inputType" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "遊戲分類",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "",
                                disabled: "",
                                selected: "",
                                hidden: "",
                              },
                            },
                            [_vm._v("請選擇")]
                          ),
                          _vm._l(_vm.classTypes, function (item, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: item.classTypeId },
                              },
                              [_vm._v(_vm._s(item.className))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("fieldset", { staticClass: "col-sm-6 mt-10-sm" }, [
                      _c("legend", { staticClass: "required" }, [
                        _vm._v("支援語系："),
                      ]),
                      _c(
                        "div",
                        _vm._l(_vm.langs, function (lang, index) {
                          return _c(
                            "label",
                            { key: lang.id, staticClass: "mt-7 mr-20" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editSupportLangs[index],
                                    expression: "editSupportLangs[index]",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.editSupportLangs[index]
                                  )
                                    ? _vm._i(
                                        _vm.editSupportLangs[index],
                                        null
                                      ) > -1
                                    : _vm.editSupportLangs[index],
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.editSupportLangs[index],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.editSupportLangs,
                                            index,
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.editSupportLangs,
                                            index,
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.editSupportLangs, index, $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" " + _vm._s(lang.name) + " "),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("fieldset", { staticClass: "col-sm-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "required",
                          attrs: { for: "inputMaxWin" },
                        },
                        [_vm._v("最大倍率 (Max Win)：")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.最大倍率,
                            expression: "edit.最大倍率",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputMaxWin",
                          required: "",
                        },
                        domProps: { value: _vm.edit.最大倍率 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "最大倍率", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("fieldset", { staticClass: "col-sm-6 mt-10-sm" }, [
                      _c(
                        "label",
                        {
                          staticClass: "required",
                          attrs: { for: "inputVolatility" },
                        },
                        [_vm._v("波動度 (Volatility)：")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.edit.波動度,
                            expression: "edit.波動度",
                            modifiers: { trim: true },
                          },
                        ],
                        attrs: {
                          type: "range",
                          min: "1",
                          max: "5",
                          step: "1",
                          list: "markers",
                          id: "inputVolatility",
                          required: "",
                        },
                        domProps: { value: _vm.edit.波動度 },
                        on: {
                          __r: function ($event) {
                            _vm.$set(
                              _vm.edit,
                              "波動度",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._m(1),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("fieldset", { staticClass: "col-sm-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "required",
                          attrs: { for: "inputMaxBet" },
                        },
                        [_vm._v("最大押注 (Max Bet)：")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.edit.最大押註,
                            expression: "edit.最大押註",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "inputMaxBet",
                          min: "1",
                          required: "",
                        },
                        domProps: { value: _vm.edit.最大押註 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "最大押註",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _c("fieldset", { staticClass: "col-sm-6 mt-10-sm" }, [
                      _c(
                        "label",
                        {
                          staticClass: "required",
                          attrs: { for: "inputMinBet" },
                        },
                        [_vm._v("最小押注 (Min Bet)：")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.edit.最小押註,
                            expression: "edit.最小押註",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "inputMinBet",
                          min: "1",
                          required: "",
                        },
                        domProps: { value: _vm.edit.最小押註 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "最小押註",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "fieldset",
                      { staticClass: "col-sm-6" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "required",
                            attrs: { for: "inputTitle" },
                          },
                          [_vm._v("發行日期：")]
                        ),
                        _c("DateAndTimePicker", {
                          attrs: {
                            format: "YYYY-MM-DD HH:mm:ss",
                            required: "",
                          },
                          model: {
                            value: _vm.edit.發行日期,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "發行日期", $$v)
                            },
                            expression: "edit.發行日期",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("fieldset", { staticClass: "col-sm-6 mt-10-sm" }, [
                      _c("legend", { staticClass: "required" }, [
                        _vm._v("支援裝置："),
                      ]),
                      _c(
                        "div",
                        _vm._l(_vm.layouts, function (layout, index) {
                          return _c(
                            "label",
                            { key: layout, staticClass: "mt-7 mr-20" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editSupportLayouts[index],
                                    expression: "editSupportLayouts[index]",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.editSupportLayouts[index]
                                  )
                                    ? _vm._i(
                                        _vm.editSupportLayouts[index],
                                        null
                                      ) > -1
                                    : _vm.editSupportLayouts[index],
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.editSupportLayouts[index],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.editSupportLayouts,
                                            index,
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.editSupportLayouts,
                                            index,
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.editSupportLayouts,
                                        index,
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(" " + _vm._s(layout) + " "),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("fieldset", { staticClass: "col-sm-12" }, [
                      _c("label", { attrs: { for: "inputDemo" } }, [
                        _vm._v("Demo URL："),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.demoUrl,
                            expression: "edit.demoUrl",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "url", id: "inputDemo" },
                        domProps: { value: _vm.edit.demoUrl },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "demoUrl", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "nav-tabs-custom" }, [
                    _c(
                      "ul",
                      {
                        staticClass: "nav nav-tabs",
                        staticStyle: {
                          background:
                            "linear-gradient(transparent, rgba(0, 0, 0, 0.05))",
                        },
                      },
                      [
                        _vm._l(_vm.langs, function (lang, index) {
                          return [
                            _c(
                              "li",
                              { class: { active: lang.id == _vm.editLang } },
                              [
                                _c(
                                  "a",
                                  {
                                    class: { required: lang.required },
                                    attrs: { title: lang.id },
                                    on: {
                                      click: function ($event) {
                                        _vm.editLang = lang.id
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(lang.name) + " ")]
                                ),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "tab-content" },
                      [
                        _vm._l(_vm.langs, function (lang, index) {
                          return _c(
                            "div",
                            { class: { "sr-only": lang.id !== _vm.editLang } },
                            [
                              _c(
                                "fieldset",
                                { class: { required: lang.required } },
                                [
                                  _c("label", [_vm._v("遊戲封面：")]),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-sm-6" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "pull-left mt-7 mr-5",
                                            class: { required: lang.required },
                                            attrs: {
                                              for: `bannerPc_${lang.id}`,
                                            },
                                          },
                                          [_vm._v(" 桌機版： ")]
                                        ),
                                        _c(
                                          "ImageSelect",
                                          {
                                            ref: "banner",
                                            refInFor: true,
                                            attrs: {
                                              id: `bannerPc_${lang.id}`,
                                              gid: _vm.edit.gid,
                                              module: "WebGame",
                                              info: `${lang.id}_desktop`,
                                              accept:
                                                "image/webp, image/jpeg, image/png, image/gif",
                                              maxsize: 200 * 1024,
                                              required:
                                                _vm.editLang == lang.id &&
                                                lang.required,
                                            },
                                            model: {
                                              value: _vm.edit.files,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.edit, "files", $$v)
                                              },
                                              expression: "edit.files",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "help-block" },
                                              [
                                                _vm._v(
                                                  "※尺寸 W300×H380，檔案上限200KB。"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-sm-6 mt-10-sm" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "pull-left mt-7 mr-5",
                                            class: { required: lang.required },
                                            attrs: {
                                              for: `bannerMo_${lang.id}`,
                                            },
                                          },
                                          [_vm._v(" 手機版： ")]
                                        ),
                                        _c(
                                          "ImageSelect",
                                          {
                                            ref: "banner",
                                            refInFor: true,
                                            attrs: {
                                              id: `bannerMo_${lang.id}`,
                                              gid: _vm.edit.gid,
                                              module: "WebGame",
                                              info: `${lang.id}_mobile`,
                                              accept:
                                                "image/webp, image/jpeg, image/png, image/gif",
                                              maxsize: 200 * 1024,
                                              required:
                                                _vm.editLang == lang.id &&
                                                lang.required,
                                            },
                                            model: {
                                              value: _vm.edit.files,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.edit, "files", $$v)
                                              },
                                              expression: "edit.files",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "help-block" },
                                              [
                                                _vm._v(
                                                  "※尺寸 W300×H380，檔案上限200KB。"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        _c(
                          "fieldset",
                          {
                            class: {
                              required: _vm.langs.find(
                                (lang) =>
                                  lang.id == _vm.editLang && lang.required
                              ),
                            },
                          },
                          [
                            _c("label", { attrs: { for: "gameName" } }, [
                              _vm._v("遊戲名稱："),
                            ]),
                            _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "gameName",
                                required: _vm.langs.find(
                                  (lang) =>
                                    lang.id == _vm.editLang && lang.required
                                ),
                              },
                              domProps: { value: _vm.getProp("gameName") },
                              on: {
                                input: function ($event) {
                                  return _vm.setProp(
                                    "gameName",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                        _c(
                          "fieldset",
                          {
                            staticClass: "mt-15",
                            class: {
                              required: _vm.langs.find(
                                (lang) =>
                                  lang.id == _vm.editLang && lang.required
                              ),
                            },
                          },
                          [
                            _c("label", { attrs: { for: "gameIntro" } }, [
                              _vm._v("遊戲簡介："),
                            ]),
                            _c("tinymce", {
                              attrs: {
                                height: "600",
                                id: "gameIntro",
                                required: _vm.langs.find(
                                  (lang) =>
                                    lang.id == _vm.editLang && lang.required
                                ),
                                value: _vm.getProp("gameIntro"),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setProp("gameIntro", $event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "fieldset",
                          {
                            staticClass: "mt-15",
                            class: {
                              required: _vm.langs.find(
                                (lang) =>
                                  lang.id == _vm.editLang && lang.required
                              ),
                            },
                          },
                          [
                            _vm._m(2),
                            _c("TagSelect", {
                              attrs: {
                                taggable: "",
                                id: "gameTags",
                                required: _vm.langs.find(
                                  (lang) =>
                                    lang.id == _vm.editLang && lang.required
                                ),
                                value: _vm.getProp("gameTags"),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setProp("gameTags", $event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
              _vm._m(3),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [
      _c("i", { staticClass: "fa fa-fw fa-trophy" }),
      _vm._v("遊戲"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "datalist",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "space-between",
          padding: "0 2px",
        },
        attrs: { id: "markers" },
      },
      [
        _c("option", { attrs: { value: "1", label: "1" } }),
        _c("option", { attrs: { value: "2", label: "2" } }),
        _c("option", { attrs: { value: "3", label: "3" } }),
        _c("option", { attrs: { value: "4", label: "4" } }),
        _c("option", { attrs: { value: "5", label: "5" } }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "gameTags" } }, [
      _vm._v("遊戲標籤："),
      _c("small", { staticClass: "text-red" }, [
        _vm._v("(※ 應用於「您可能也有興趣的遊戲」推薦功能)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-footer" }, [
      _c("div", { staticClass: "btns" }, [
        _c(
          "button",
          { staticClass: "btn btn-primary btn-lg", attrs: { type: "submit" } },
          [_vm._v(" 確認送出 ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }