@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>{{$t('類別與標籤管理')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('類別與標籤管理')}}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box mb-10">
            <button type="button" @click="add()" class="btn btn-block btn-warning btn-add fa fa-plus">
              {{$t('新增')}}
            </button>
          </div>
          <div class="table-responsive">
            <p v-if="message && datas.length == 0">{{ message }}</p>
            <p v-else-if="datas.length == 0">{{ '無資料' }}</p>
            <table v-else v-for="(group, groupIndex) in datas" :key="groupIndex" class="table table-bordered table-condensed table-striped text-center mb-10">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px; width: 15%;">{{$t('標籤類別')}}</th>
                  <th style="min-width: 100px; width: 15%;">{{$t('狀態')}}</th>
                  <th style="min-width: 100px; width: 55%;">{{$t('標籤')}}</th>
                  <th style="min-width: 140px; width: 15%;">{{$t('功能')}}</th>
                </tr>
                <tr v-for="(item, index) in group" :key="item.id">
                  <td :rowspan="group.length" v-if="index == 0">
                    <p v-if="!item.isNew || group.length > 1" class="m-0">{{ item.標籤類別 }}</p>
                    <input v-else type="text" class="form-control" v-model="item.標籤類別" placeholder="標籤類別" required :disabled="!item.isEdit&&isEditing" />
                  </td>
                  <td>
                    <fieldset class="ckbx-style-8 ckbx toggleInput" :style="!can修改 || (isEditing && !item.isEdit) ?'opacity:.5;pointer-events:none':false">
                      <input type="checkbox" :id="`check${groupIndex}-${index}`" v-model="item.狀態" @change="changeStatus(groupIndex, index)" name="ckbx-style-8" />
                      <label :for="`check${groupIndex}-${index}`"></label>
                    </fieldset>
                  </td>
                  <td class="text-left">
                    <span v-if="!item.isEdit">{{ item.標籤 }}</span>
                    <input v-else v-model="item.標籤" type="text" class="form-control" placeholder="標籤">
                    <!-- <TagSelect v-model="item.標籤" :options="item.標籤" :readonly="!item.isEdit" taggable></TagSelect> -->
                  </td>
                  <td>
                    <button v-show="!item.isEdit" @click="edit(groupIndex, index)" v-if="can修改" class="btn btn-primary" :disabled="isEditing">{{$t('修改')}}</button>
                    <button v-show="!item.isEdit" @click="add(groupIndex)" v-if="can新增" class="btn btn-warning ml-5" :style="index==0?'':'opacity:0; pointer-events:none;'" :disabled="isEditing">{{$t('新增')}}</button>
                    <button v-show="item.isEdit" @click="save(groupIndex, index)" class="btn btn-success">{{$t('儲存')}}</button>
                    <button v-show="item.isEdit" @click="cancel(groupIndex, index)" class="btn btn-default ml-5">{{$t('取消')}}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <pre style="line-height:1.1;">{{ datas }}</pre> -->
        <!-- <pre style="line-height:1.1;">{{ editBackup }}</pre> -->
        
      </div>
    </section>
  </div>
</template>
<style scoped>
  .table-condensed .btn{
    padding-block: 5px; 
  }
</style>

<script>
/* eslint-disable */
import dfn from "../XSW/_Define";
import TagSelect from "@/views/projects/XSG/components/TagSelect";

export default {
  components: {
    TagSelect
  },
  data() {
    return {
      datas: [],
      message: "",
      isEditing: false,
      editBackup: {},

      can新增: this.$user.hasPermission(dfn.permission.類別與標籤新增),
      can修改: this.$user.hasPermission(dfn.permission.類別與標籤修改),
    };
  },
  created() {
    this.getData();
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    // 顯示列表
    getData() {
      this.$store.dispatch("xsw/類別與標籤查詢", {})
      .then((result) => {
        let data = result.datas;
        let groups = [];
        for (let item of data){
          let index = groups.findIndex(i => i[0].標籤類別 == item.標籤類別);
          if (index >= 0) groups[index].push(item);
          else groups.push([item]);
        }

        // data.map(item => {item.標籤 = item.標籤.split(',') || [] });
        this.datas = groups;
        this.message = '';
      }, err => {
        this.message = err;
      });
    },

    // 新增類別
    add(groupIndex) {
      if(this.isEditing){
        return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      }
      let newData = {
        "標籤類別": "",
        "標籤": "",
        "狀態": true,
        "isNew": true,
      };
      if (groupIndex != undefined){
        newData.標籤類別 = this.datas[groupIndex][0].標籤類別;
        this.datas[groupIndex].unshift(newData);
      } else {
        this.datas.unshift([newData]);
      }
      this.edit(groupIndex || 0, 0);
    },
    // 切換文字或輸入框
    edit(groupIndex, index) {
      let item = this.datas[groupIndex][index];
      if(this.isEditing){
        return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      }
      this.editBackup = Object.assign({}, item);
      this.isEditing = true;

      item.isEdit = true;
      console.log(item)
      // console.log(this.datas[index]);
      this.$set(this.datas[groupIndex], index, item);
    },

    // 儲存
    save(groupIndex, index) {
      let item = this.datas[groupIndex][index];
      this.isEditing = false;
      delete item.isEdit;
      delete item.isNew;

      let err = [];
      if (item.標籤類別	== "") err.push("請填寫標籤類別");
      if (item.標籤	== "") err.push("請填寫標籤");
      if (err.length > 0) return this.$eventBus.$emit("showAlert", err.join('<br>'));

      this.$store.dispatch("xsw/類別與標籤" + (item.id ? '修改' : '新增'), item).then(result => {
        this.$eventBus.$emit("showAlert", this.$t(item.id ? '修改成功' : '新增成功'));
        this.getData();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    cancel(groupIndex, index) {
      let item = this.datas[groupIndex][index];
      this.isEditing = false;
      delete item.isEdit;
      if (item.isNew){
        this.datas[groupIndex].shift();
        if (this.datas[groupIndex].length == 0) this.datas.shift();
      } 
      else this.$set(this.datas[groupIndex], index, this.editBackup);
    },

    // 改變狀態
    changeStatus(groupIndex, index) {
      let item = this.datas[groupIndex][index];
      if (item.isEdit) return;
      this.save(groupIndex, index);
    },
  }
};
</script>