var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-content", attrs: { id: "MemberModerate" } },
    [
      _c(
        "form",
        {
          staticClass: "top-box clearfix",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.searchData.pageNum = 1
              _vm.getData()
            },
          },
        },
        [
          _c("div", { staticClass: "form-group m-0" }, [
            _c("label", { staticClass: "control-label pull-left mt-7" }, [
              _vm._v(_vm._s(_vm.$t("查詢區間")) + "："),
            ]),
            _c(
              "div",
              { staticClass: "input-group date pull-left mb-10 mr-10" },
              [
                _c("div", { staticClass: "input-group-addon fa fa-calendar" }),
                _c("DateRangePicker", {
                  attrs: {
                    propStartDate: _vm.searchData.開始時間,
                    propEndDate: _vm.searchData.結束時間,
                    propTimePicker: false,
                  },
                  on: {
                    "update:propStartDate": function ($event) {
                      return _vm.$set(_vm.searchData, "開始時間", $event)
                    },
                    "update:prop-start-date": function ($event) {
                      return _vm.$set(_vm.searchData, "開始時間", $event)
                    },
                    "update:propEndDate": function ($event) {
                      return _vm.$set(_vm.searchData, "結束時間", $event)
                    },
                    "update:prop-end-date": function ($event) {
                      return _vm.$set(_vm.searchData, "結束時間", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary pull-left mb-10",
                attrs: { type: "submit" },
              },
              [_vm._v(_vm._s(_vm.$t("查詢")))]
            ),
          ]),
          _vm.can控管
            ? _c(
                "div",
                {
                  staticClass: "pull-right",
                  staticStyle: { width: "max-content", margin: "0 auto" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.dialog.show(_vm.user)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-gavel" }),
                      _vm._v(" " + _vm._s(_vm.$t("控管")) + " "),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.banStatus() != _vm.banStatus(_vm.user),
                          expression: "banStatus() != banStatus(user)",
                        },
                      ],
                      staticClass: "btn btn-danger ml-5",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.dialog.show(_vm.user, true)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-unlock" }),
                      _vm._v(" " + _vm._s(_vm.$t("解鎖")) + " "),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "table-responsive mb-10" }, [
        _c("table", { staticClass: "table table-bordered text-center" }, [
          _c("thead", [
            _c("tr", { staticClass: "bg-gray" }, [
              _c("th", { staticStyle: { "min-width": "65px", width: "5%" } }, [
                _vm._v(_vm._s(_vm.$t("編號"))),
              ]),
              _c("th", { staticStyle: { "min-width": "100px", width: "5%" } }, [
                _vm._v(_vm._s(_vm.$t("控管資訊"))),
              ]),
              _c("th", { staticStyle: { "min-width": "100px", width: "5%" } }, [
                _vm._v(_vm._s(_vm.$t("狀態"))),
              ]),
              _c("th", { staticStyle: { "min-width": "100px", width: "5%" } }, [
                _vm._v(_vm._s(_vm.$t("限制鎖定"))),
              ]),
              _c(
                "th",
                { staticStyle: { "min-width": "100px", width: "10%" } },
                [_vm._v(_vm._s(_vm.$t("期限")))]
              ),
              _c(
                "th",
                { staticStyle: { "min-width": "100px", width: "10%" } },
                [_vm._v(_vm._s(_vm.$t("服務類型")))]
              ),
              _c(
                "th",
                { staticStyle: { "min-width": "100px", width: "15%" } },
                [_vm._v(_vm._s(_vm.$t("原因")))]
              ),
              _c(
                "th",
                { staticStyle: { "min-width": "100px", width: "10%" } },
                [_vm._v(_vm._s(_vm.$t("操作時間")))]
              ),
              _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
                _vm._v(_vm._s(_vm.$t("操作人員"))),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            [
              _vm.loading
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "11" } }, [
                      _vm._v(_vm._s(_vm.$t("查詢中"))),
                    ]),
                  ])
                : _vm.message
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "11" } }, [
                      _vm._v(_vm._s(_vm.$t(_vm.message))),
                    ]),
                  ])
                : _vm.total === 0
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "11" } }, [
                      _vm._v(_vm._s(_vm.$t("查無紀錄"))),
                    ]),
                  ])
                : _vm._l(_vm.datas, function (item, index) {
                    return _c("tr", { key: item.玩家異動紀錄ID }, [
                      _c("td", [_vm._v(_vm._s(item.玩家異動紀錄ID))]),
                      _c("td", [
                        _vm._v(_vm._s(/*item.門號 ||*/ _vm.user.暱稱)),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            /雙平台鎖交易|星幣鎖交易/.test(item.異動動作)
                              ? ""
                              : _vm.$t(item.異動動作)
                          )
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            /雙平台鎖交易|星幣鎖交易/.test(item.異動動作)
                              ? _vm.$t(item.異動動作)
                              : ""
                          )
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.uiDate(item.新內容.控管期限) ||
                              _vm.uiDate(item.新內容.期限)
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.新內容.服務類型))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(item.新內容.服務原因 || item.新內容.原因)
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.uiDate(item.異動時間)))]),
                      _c("td", [_vm._v(_vm._s(item.異動者))]),
                    ])
                  }),
            ],
            2
          ),
        ]),
      ]),
      _vm.total > _vm.searchData.pageSize
        ? _c(
            "div",
            { staticClass: "text-center mt-10" },
            [
              _c("Pagination", {
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("MemberModerateDialog", {
        ref: "dialog",
        attrs: { types: _vm.types },
        on: {
          update: function ($event) {
            _vm.getData()
            _vm.$emit("update")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }