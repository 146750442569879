var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    ref: "datepicker",
    staticClass: "form-control datepicker",
    attrs: { type: "text" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }