var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn relative",
      class: _vm.btnClass,
      attrs: { disabled: _vm.spinner || _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "absolute left-0 right-0 transition-1",
          class: { "opacity-0 transition-1": !_vm.spinner },
        },
        [
          _c("i", {
            staticClass: "fa fa-spinner fa-spin",
            attrs: { "aria-hidden": "true" },
          }),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "transition-1",
          class: { "opacity-0 transition-1": _vm.spinner },
        },
        [_c("i", { class: _vm.icon }), _vm._v(" " + _vm._s(_vm.name) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }