var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "MemberData" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("序號兌換記錄查詢")))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v(_vm._s(_vm.$t("首頁"))),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.$t("序號兌換記錄查詢"))),
        ]),
      ]),
    ]),
    !_vm.id
      ? _c(
          "section",
          { staticClass: "content", staticStyle: { "min-height": "auto" } },
          [
            _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
              _c("ul", { staticClass: "nav nav-tabs" }, [
                _c("li", { class: { active: _vm.tab == 1 } }, [
                  _c(
                    "a",
                    {
                      staticStyle: { cursor: "pointer" },
                      attrs: { "aria-expanded": "true", "data-toggle": "tab" },
                      on: {
                        click: function ($event) {
                          _vm.tab = 1
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("活動列表")))]
                  ),
                ]),
                _c("li", { class: { active: _vm.tab == 2 } }, [
                  _c(
                    "a",
                    {
                      staticStyle: { cursor: "pointer" },
                      attrs: { "aria-expanded": "true", "data-toggle": "tab" },
                      on: {
                        click: function ($event) {
                          _vm.tab = 2
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("查詢帳號")))]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tab == 1,
                      expression: "tab == 1",
                    },
                  ],
                  staticClass: "tab-content",
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "top-box clearfix",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.getData()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "input-group pull-left mb-10 mr-10" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchEventData.序號名稱,
                                expression: "searchEventData.序號名稱",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("序號名稱"),
                            },
                            domProps: { value: _vm.searchEventData.序號名稱 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.searchEventData,
                                  "序號名稱",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "select-group mb-10 mr-10" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.searchEventData.類型,
                                expression: "searchEventData.類型",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.$set(
                                    _vm.searchEventData,
                                    "類型",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.getData()
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { domProps: { value: 0 } }, [
                              _vm._v(_vm._s(_vm.$t("全部類型"))),
                            ]),
                            _vm._l(_vm.serialTypes, function (name, key) {
                              return _c(
                                "option",
                                { key: key, domProps: { value: key } },
                                [_vm._v(_vm._s(_vm.$t(name)))]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _c("div", { staticClass: "form-group pull-left m-0" }, [
                        _c("div", { staticClass: "form-group pull-left m-0" }, [
                          _c(
                            "label",
                            { staticClass: "control-label pull-left mt-7" },
                            [_vm._v(_vm._s(_vm.$t("查詢區間")) + "：")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "input-group date pull-left mb-10 mr-10",
                            },
                            [
                              _c("div", {
                                staticClass: "input-group-addon fa fa-calendar",
                              }),
                              _c("DateRangePicker", {
                                attrs: {
                                  propStartDate: _vm.searchEventData.開始時間,
                                  propEndDate: _vm.searchEventData.結束時間,
                                  displayFormat: "YYYY/MM/DD HH:mm",
                                },
                                on: {
                                  "update:propStartDate": function ($event) {
                                    return _vm.$set(
                                      _vm.searchEventData,
                                      "開始時間",
                                      $event
                                    )
                                  },
                                  "update:prop-start-date": function ($event) {
                                    return _vm.$set(
                                      _vm.searchEventData,
                                      "開始時間",
                                      $event
                                    )
                                  },
                                  "update:propEndDate": function ($event) {
                                    return _vm.$set(
                                      _vm.searchEventData,
                                      "結束時間",
                                      $event
                                    )
                                  },
                                  "update:prop-end-date": function ($event) {
                                    return _vm.$set(
                                      _vm.searchEventData,
                                      "結束時間",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "pull-left mb-10 mr-10" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-10",
                              attrs: { type: "submit" },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("查詢")) + " ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              attrs: {
                                type: "button",
                                disabled: _vm.eventList.length === 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.exportEvent()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("匯出")) + " ")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table-striped table-bordered text-center",
                      },
                      [
                        _c("thead", [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "50px",
                                  width: "3%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("項目")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "120px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("序號名稱")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "120px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("序號獎勵")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "160px",
                                  width: "25%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("序號兌換時間")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("類型")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("序號數量")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("已兌換數量")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("備註")))]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm.eventLoading
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("查詢中"))),
                                  ]),
                                ])
                              : _vm.eventMessage
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t(_vm.eventMessage))),
                                  ]),
                                ])
                              : _vm.eventList.length === 0
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("查無資料"))),
                                  ]),
                                ])
                              : _vm._l(
                                  _vm.pagedEventData,
                                  function (item, index) {
                                    return _c("tr", { key: item.序號名稱 }, [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            index +
                                              1 +
                                              (_vm.searchEventData.pageNum -
                                                1) *
                                                _vm.searchEventData.pageSize
                                          )
                                        ),
                                      ]),
                                      _c("td", [_vm._v(_vm._s(item.序號名稱))]),
                                      _c("td", [_vm._v(_vm._s(item.序號獎勵))]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.序號兌換時間)),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.serialTypes[item.類型])
                                        ),
                                      ]),
                                      _c("td", [_vm._v(_vm._s(item.序號數量))]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.已兌換數量)),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "btn btn-primary",
                                              attrs: {
                                                to: {
                                                  params: { id: item.序號名稱 },
                                                },
                                              },
                                            },
                                            [_vm._v("查詢")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.eventTotal > 0,
                          expression: "eventTotal > 0",
                        },
                      ],
                      staticClass: "text-center mt-10",
                    },
                    [
                      _c("Pagination", {
                        ref: "paginationEvent",
                        attrs: {
                          total: _vm.eventTotal,
                          pageLimitCount: _vm.searchEventData.pageSize,
                          type2: true,
                        },
                        on: { pageMethod: _vm.onPageChange },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tab == 2,
                      expression: "tab == 2",
                    },
                  ],
                  staticClass: "tab-content",
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "top-box clearfix",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.getData()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "input-group pull-left mb-10 mr-10" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchAccountData.UID,
                                expression: "searchAccountData.UID",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: _vm.$t("UID") },
                            domProps: { value: _vm.searchAccountData.UID },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.searchAccountData,
                                  "UID",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "input-group pull-left mb-10 mr-10" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchAccountData.序號,
                                expression: "searchAccountData.序號",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("序號"),
                            },
                            domProps: { value: _vm.searchAccountData.序號 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.searchAccountData,
                                  "序號",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "form-group pull-left m-0" }, [
                        _c("div", { staticClass: "form-group pull-left m-0" }, [
                          _c(
                            "label",
                            { staticClass: "control-label pull-left mt-7" },
                            [_vm._v(_vm._s(_vm.$t("查詢區間")) + "：")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "input-group date pull-left mb-10 mr-10",
                            },
                            [
                              _c("div", {
                                staticClass: "input-group-addon fa fa-calendar",
                              }),
                              _c("DateRangePicker", {
                                attrs: {
                                  propStartDate: _vm.searchAccountData.開始時間,
                                  propEndDate: _vm.searchAccountData.結束時間,
                                  displayFormat: "YYYY/MM/DD HH:mm",
                                },
                                on: {
                                  "update:propStartDate": function ($event) {
                                    return _vm.$set(
                                      _vm.searchAccountData,
                                      "開始時間",
                                      $event
                                    )
                                  },
                                  "update:prop-start-date": function ($event) {
                                    return _vm.$set(
                                      _vm.searchAccountData,
                                      "開始時間",
                                      $event
                                    )
                                  },
                                  "update:propEndDate": function ($event) {
                                    return _vm.$set(
                                      _vm.searchAccountData,
                                      "結束時間",
                                      $event
                                    )
                                  },
                                  "update:prop-end-date": function ($event) {
                                    return _vm.$set(
                                      _vm.searchAccountData,
                                      "結束時間",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "pull-left mb-10 mr-10" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-10",
                              attrs: { type: "submit" },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("查詢")) + " ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              attrs: {
                                type: "button",
                                disabled: _vm.accountList.length == 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.exportAccount()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("匯出")) + " ")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table-striped table-bordered text-center",
                      },
                      [
                        _c("thead", [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "50px",
                                  width: "5%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("項次")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("UID")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("暱稱")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "100px",
                                  width: "15%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("序號")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "100px",
                                  width: "15%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("兌換時間")))]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm.accountLoading
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("查詢中"))),
                                  ]),
                                ])
                              : _vm.accountMessage
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t(_vm.accountMessage))),
                                  ]),
                                ])
                              : _vm.accountList.length === 0
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("查無資料"))),
                                  ]),
                                ])
                              : _vm._l(
                                  _vm.pagedAccountData,
                                  function (item, index) {
                                    return _c("tr", { key: item.兌換時間 }, [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            index +
                                              1 +
                                              (_vm.searchAccountData.pageNum -
                                                1) *
                                                _vm.searchAccountData.pageSize
                                          )
                                        ),
                                      ]),
                                      _c("td", [_vm._v(_vm._s(item.uid))]),
                                      _c("td", [_vm._v(_vm._s(item.暱稱))]),
                                      _c("td", [_vm._v(_vm._s(item.序號))]),
                                      _c("td", [_vm._v(_vm._s(item.兌換時間))]),
                                    ])
                                  }
                                ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.accountTotal > 0,
                          expression: "accountTotal > 0",
                        },
                      ],
                      staticClass: "text-center mt-10",
                    },
                    [
                      _c("Pagination", {
                        ref: "paginationAccount",
                        attrs: {
                          total: _vm.accountTotal,
                          pageLimitCount: _vm.searchAccountData.pageSize,
                          type2: true,
                        },
                        on: { pageMethod: _vm.onPageChange },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]
        )
      : _c(
          "section",
          { staticClass: "content", staticStyle: { "min-height": "auto" } },
          [
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "box-body" }, [
                _c(
                  "form",
                  {
                    staticClass: "top-box clearfix",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.getData()
                      },
                    },
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-default pull-right mb-10",
                        attrs: { to: { params: { id: null } } },
                      },
                      [
                        _c("i", { staticClass: "fa fa-reply" }),
                        _vm._v(" " + _vm._s(_vm.$t("返回")) + " "),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "input-group pull-left mb-10 mr-10" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filterSerialData.關鍵字,
                              expression: "filterSerialData.關鍵字",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("關鍵字搜尋"),
                          },
                          domProps: { value: _vm.filterSerialData.關鍵字 },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.filterSerialData,
                                "關鍵字",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "select-group mb-10 mr-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filterSerialData.狀態,
                              expression: "filterSerialData.狀態",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.filterSerialData,
                                "狀態",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("全部"))),
                          ]),
                          _c("option", { attrs: { value: "已兌換" } }, [
                            _vm._v(_vm._s(_vm.$t("已兌換"))),
                          ]),
                          _c("option", { attrs: { value: "時間區間" } }, [
                            _vm._v(_vm._s(_vm.$t("時間區間"))),
                          ]),
                        ]
                      ),
                    ]),
                    _vm.filterSerialData.狀態 == "時間區間"
                      ? _c("div", { staticClass: "form-group pull-left m-0" }, [
                          _c(
                            "label",
                            { staticClass: "control-label pull-left mt-7" },
                            [_vm._v(_vm._s(_vm.$t("查詢區間")) + "：")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "input-group date pull-left mb-10 mr-10",
                            },
                            [
                              _c("div", {
                                staticClass: "input-group-addon fa fa-calendar",
                              }),
                              _c("DateRangePicker", {
                                attrs: {
                                  propStartDate: _vm.filterSerialData.開始時間,
                                  propEndDate: _vm.filterSerialData.結束時間,
                                  displayFormat: "YYYY/MM/DD HH:mm",
                                },
                                on: {
                                  "update:propStartDate": function ($event) {
                                    return _vm.$set(
                                      _vm.filterSerialData,
                                      "開始時間",
                                      $event
                                    )
                                  },
                                  "update:prop-start-date": function ($event) {
                                    return _vm.$set(
                                      _vm.filterSerialData,
                                      "開始時間",
                                      $event
                                    )
                                  },
                                  "update:propEndDate": function ($event) {
                                    return _vm.$set(
                                      _vm.filterSerialData,
                                      "結束時間",
                                      $event
                                    )
                                  },
                                  "update:prop-end-date": function ($event) {
                                    return _vm.$set(
                                      _vm.filterSerialData,
                                      "結束時間",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-group pull-left m-0" }, [
                      _c("div", { staticClass: "pull-left mb-10 mr-10" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: {
                              type: "button",
                              disabled: _vm.serialList.length == 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.exportSerial()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("匯出")) + " ")]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    { staticClass: "table table-bordered text-center mb-10" },
                    [
                      _c("thead", [
                        _c("tr", { staticClass: "bg-gray" }, [
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "100px",
                                width: "15%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("序號名稱")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "100px",
                                width: "15%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("序號獎勵")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "160px",
                                width: "40%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("序號兌換時間")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "100px",
                                width: "15%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("序號數量")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "100px",
                                width: "15%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("已兌換數量")))]
                          ),
                        ]),
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v(_vm._s(_vm.id))]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.serialData.序號獎勵 || "-")),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.serialData.序號兌換時間 || "-")),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.serialData.序號數量 || "-")),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.serialData.已兌換數量 || "-")),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-striped table-bordered text-center",
                    },
                    [
                      _c("thead", [
                        _c("tr", { staticClass: "bg-gray" }, [
                          _c(
                            "th",
                            {
                              staticStyle: { "min-width": "50px", width: "5%" },
                            },
                            [_vm._v(_vm._s(_vm.$t("項次")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "120px",
                                width: "15%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("序號")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "100px",
                                width: "5%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("兌換狀態")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "100px",
                                width: "10%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("UID")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "100px",
                                width: "15%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("暱稱")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "100px",
                                width: "15%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("兌換時間")))]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm.serialLoading
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "11" } }, [
                                  _vm._v(_vm._s(_vm.$t("查詢中"))),
                                ]),
                              ])
                            : _vm.serialMessage
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "11" } }, [
                                  _vm._v(_vm._s(_vm.$t(_vm.serialMessage))),
                                ]),
                              ])
                            : _vm.serialList.length === 0
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "11" } }, [
                                  _vm._v(_vm._s(_vm.$t("查無資料"))),
                                ]),
                              ])
                            : _vm._l(
                                _vm.pagedSerialData,
                                function (item, index) {
                                  return _c("tr", { key: item.序號 }, [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          index +
                                            1 +
                                            (_vm.searchSerialData.pageNum - 1) *
                                              _vm.searchSerialData.pageSize
                                        )
                                      ),
                                    ]),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          item._序號 || item.序號
                                        ),
                                      },
                                    }),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          item._兌換狀態 || item.兌換狀態 || "-"
                                        ),
                                      },
                                    }),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          item._uid || item.uid || "-"
                                        ),
                                      },
                                    }),
                                    _c(
                                      "td",
                                      [
                                        !item.暱稱
                                          ? _c("span", [_vm._v("-")])
                                          : _c("router-link", {
                                              attrs: {
                                                to: {
                                                  name: "XsgMember",
                                                  params: { id: item.uid },
                                                },
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item._暱稱 || item.暱稱
                                                ),
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c("td", [
                                      _vm._v(_vm._s(item.兌換時間 || "-")),
                                    ]),
                                  ])
                                }
                              ),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.serialTotal > _vm.searchSerialData.pageSize,
                        expression: "serialTotal > searchSerialData.pageSize",
                      },
                    ],
                    staticClass: "text-center mt-10",
                  },
                  [
                    _c("Pagination", {
                      ref: "paginationSerial",
                      attrs: {
                        total: _vm.serialTotal,
                        pageLimitCount: _vm.searchSerialData.pageSize,
                        type2: true,
                      },
                      on: { pageMethod: _vm.onPageChange },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }