var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("APP推播管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("APP推播管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content customer-service" }, [
      _c(
        "div",
        { staticClass: "box box-primary" },
        [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addNew()
                    },
                  },
                },
                [_vm._v(" 新增推播")]
              ),
              _c("div", { staticClass: "form-group pull-left m-0" }, [
                _c(
                  "div",
                  { staticClass: "input-group mb-10 mr-10" },
                  [
                    _c("div", {
                      staticClass: "input-group-addon fa fa-calendar",
                    }),
                    _c("DateRangePicker", {
                      attrs: {
                        propEndDate: _vm.searchData.結束時間,
                        propStartDate: _vm.searchData.開始時間,
                        displayFormat: "YYYY/MM/DD HH:mm",
                        format: "YYYY-MM-DD HH:mm:ss",
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(_vm.searchData, "結束時間", $event)
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(_vm.searchData, "結束時間", $event)
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(_vm.searchData, "開始時間", $event)
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(_vm.searchData, "開始時間", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.searchData.發送狀態,
                        expression: "searchData.發送狀態",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return _vm._n(val)
                            })
                          _vm.$set(
                            _vm.searchData,
                            "發送狀態",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.resetDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1" } }, [
                      _vm._v("全部狀態"),
                    ]),
                    _vm._l(
                      _vm.def.notificationState.狀態,
                      function (option, i) {
                        return _c("option", { domProps: { value: i } }, [
                          _vm._v(_vm._s(option)),
                        ])
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "btns pull-left m-0" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList()
                      },
                    },
                  },
                  [_vm._v("查詢")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-bordered table-hover table-center min-800 AdListTable text-center",
                },
                [
                  _vm._m(0),
                  _vm.total > 0
                    ? _c(
                        "tbody",
                        _vm._l(_vm.pagedDatas, function (item, index) {
                          return _c(
                            "tr",
                            {
                              key: item.id,
                              class: { "bg-gray-light": item.發送狀態 != 1 },
                            },
                            [
                              _c("td", [_vm._v(_vm._s(item.id))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.def.notificationState.狀態[
                                      item.發送狀態
                                    ] || item.發送狀態
                                  )
                                ),
                              ]),
                              _c("td", [
                                _c("div", { staticClass: "preview" }, [
                                  _c("div", { staticClass: "text-left" }, [
                                    item.推播圖片
                                      ? _c("img", {
                                          staticStyle: {
                                            height: "3em",
                                            "border-radius": ".25em",
                                            width: "auto",
                                            float: "right",
                                            "margin-left": ".35em",
                                            "object-fit": "cover",
                                          },
                                          attrs: {
                                            src: item.推播圖片,
                                            alt: "",
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "strong",
                                      { staticClass: "text-clamp" },
                                      [_vm._v(_vm._s(item.標題))]
                                    ),
                                    _c("span", { staticClass: "text-clamp" }, [
                                      _vm._v(_vm._s(item.內容)),
                                    ]),
                                  ]),
                                  _c("button", {
                                    attrs: {
                                      onclick:
                                        "this.parentElement.classList.toggle('open')",
                                    },
                                  }),
                                ]),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.def.notificationState.發送對象[
                                      item.發送對象
                                    ] || item.發送對象
                                  )
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.def.notificationState.是否登入[
                                      item.登入狀態
                                    ] || item.登入狀態
                                  )
                                ),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.uiDate(item.預約時間))),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.uiDate(item.建立時間))),
                              ]),
                              _c(
                                "td",
                                [
                                  item.發送狀態 == 1
                                    ? [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editorItem(item)
                                              },
                                            },
                                          },
                                          [_vm._v("修改")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger ml-5",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteItem(item)
                                              },
                                            },
                                          },
                                          [_vm._v("刪除")]
                                        ),
                                      ]
                                    : _vm._e(),
                                  item.發送狀態 == 2
                                    ? [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-success",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewItem(item)
                                              },
                                            },
                                          },
                                          [_vm._v("明細")]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("tbody", [_vm._m(1)]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "box text-center no-border no-shadow mt-20" },
              [
                _c("Pagination", {
                  ref: "pagination",
                  attrs: { pageLimitCount: _vm.pageSize, total: _vm.total },
                  on: { pageMethod: _vm.onPageChange },
                }),
              ],
              1
            ),
          ]),
          _c(
            "FormDialog",
            { ref: "viewDialog", attrs: { title: "發送明細" } },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("發送對象")) + ": "),
                    _vm.viewType == 2
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.viewSuccess) +
                              " / " +
                              _vm._s(_vm.viewSuccess + _vm.viewFail)
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.def.notificationState.發送對象[
                                _vm.viewType
                              ] || _vm.viewType
                            )
                          ),
                        ]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.viewType == 2,
                        expression: "viewType == 2",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.viewFilter,
                        expression: "viewFilter",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { width: "50%" },
                    attrs: { type: "text", placeholder: _vm.$t("暱稱搜尋") },
                    domProps: { value: _vm.viewFilter },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.viewFilter = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _c(
                "table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.viewType == 2,
                      expression: "viewType == 2",
                    },
                  ],
                  staticClass: "table table-bordered mt-10 mb-10",
                },
                [
                  _c("thead", [
                    _c("tr", { staticClass: "bg-gray-light" }, [
                      _c(
                        "th",
                        {
                          staticStyle: { "padding-block": ".25em" },
                          attrs: { colspan: "10" },
                        },
                        [_vm._v(_vm._s(_vm.$t("玩家名單")))]
                      ),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.pagedResults, function (row) {
                      return _c("tr", [
                        _c(
                          "td",
                          {
                            staticStyle: { padding: ".1em .5em", width: "35%" },
                          },
                          [_vm._v(_vm._s(row.name1))]
                        ),
                        _c(
                          "td",
                          {
                            class: row.status1 == 1 ? "text-green" : "text-red",
                            staticStyle: { padding: ".1em .5em", width: "15%" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.status1 == 1
                                    ? _vm.$t("成功")
                                    : _vm.$t("失敗")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: { padding: ".1em .5em", width: "35%" },
                          },
                          [_vm._v(_vm._s(row.name2))]
                        ),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.name2,
                                expression: "row.name2",
                              },
                            ],
                            class: row.status2 == 1 ? "text-green" : "text-red",
                            staticStyle: { padding: ".1em .5em", width: "15%" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.status2 == 1
                                    ? _vm.$t("成功")
                                    : _vm.$t("失敗")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.viewPageTotal > _vm.viewPageSize,
                      expression: "viewPageTotal > viewPageSize",
                    },
                  ],
                  staticClass: "text-center",
                },
                [
                  _c("Pagination", {
                    ref: "paginationView",
                    attrs: {
                      pageLimitCount: _vm.viewPageSize,
                      total: _vm.viewPageTotal,
                    },
                    on: { pageMethod: _vm.onViewPageChange },
                  }),
                ],
                1
              ),
              _c("template", { slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    staticStyle: { "min-width": "8em" },
                    attrs: { type: "submit", "data-dismiss": "modal" },
                  },
                  [_vm._v(_vm._s(_vm.$t("確認")))]
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "60px",
              width: "3%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("編號")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "60px",
              width: "3%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("狀態")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "200px",
              width: "25%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("推播內容")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "80px",
              width: "3%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("發送對象")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "80px",
              width: "3%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("是否登入APP")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "92px",
              width: "10%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("發送時間")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "92px",
              width: "10%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("建立時間")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "135px",
              width: "3%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("功能")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "11" } }, [_vm._v("查詢沒有資料")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }