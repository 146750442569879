var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("最新消息列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _vm.id
          ? _c(
              "li",
              { staticClass: "active" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { params: { id: null, searchData: _vm.searchData } },
                    },
                  },
                  [_vm._v("最新消息列表")]
                ),
              ],
              1
            )
          : _c("li", { staticClass: "active" }, [_vm._v("最新消息列表")]),
        _vm.id
          ? _c("li", { staticClass: "active" }, [
              _vm._v(_vm._s(_vm.id != "New" ? "編輯" : "新增") + "內容"),
            ])
          : _vm._e(),
      ]),
    ]),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.id,
            expression: "!id",
          },
        ],
        staticClass: "content",
      },
      [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c(
              "form",
              {
                staticClass: "top-box",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.clickSearch()
                  },
                },
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-block btn-warning btn-add fa fa-plus mb-10",
                    attrs: {
                      to: { params: { id: "New", searchData: _vm.searchData } },
                    },
                  },
                  [_vm._v(" 新增訊息 ")]
                ),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.類別,
                          expression: "searchData.類別",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "類別",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.clickSearch()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("全部類別"),
                      ]),
                      _vm._l(_vm.newsType, function (name, key) {
                        return _c(
                          "option",
                          { key: key, domProps: { value: name } },
                          [_vm._v(_vm._s(name))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.是否開啟,
                          expression: "searchData.是否開啟",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "是否開啟",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.clickSearch()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { domProps: { value: -1 } }, [
                        _vm._v("全部狀態"),
                      ]),
                      _c("option", { domProps: { value: 1 } }, [
                        _vm._v("開啟"),
                      ]),
                      _c("option", { domProps: { value: 0 } }, [
                        _vm._v("關閉"),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group pull-left mb-10 mr-10" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.公告標題,
                          expression: "searchData.公告標題",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: _vm.$t("搜尋標題") },
                      domProps: { value: _vm.searchData.公告標題 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "公告標題",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "form-group pull-left m-0" }, [
                  _c("label", { staticClass: "control-label pull-left mt-7" }, [
                    _vm._v(_vm._s(_vm.$t("查詢區間")) + "："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "input-group date pull-left mb-10 mr-10" },
                    [
                      _c("div", {
                        staticClass: "input-group-addon fa fa-calendar",
                      }),
                      _c("DateRangePicker", {
                        attrs: {
                          propStartDate: _vm.searchData.開始時間,
                          propEndDate: _vm.searchData.結束時間,
                          propTimePicker: false,
                        },
                        on: {
                          "update:propStartDate": function ($event) {
                            return _vm.$set(_vm.searchData, "開始時間", $event)
                          },
                          "update:prop-start-date": function ($event) {
                            return _vm.$set(_vm.searchData, "開始時間", $event)
                          },
                          "update:propEndDate": function ($event) {
                            return _vm.$set(_vm.searchData, "結束時間", $event)
                          },
                          "update:prop-end-date": function ($event) {
                            return _vm.$set(_vm.searchData, "結束時間", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary pull-left mb-10",
                      attrs: { type: "submit" },
                    },
                    [_vm._v(_vm._s(_vm.$t("查詢")))]
                  ),
                  _c("div", { staticClass: "text data-num" }, [
                    _c("span", [_vm._v("資料總筆數：")]),
                    _c("span", { attrs: { id: "total" } }, [
                      _vm._v(_vm._s(_vm.total)),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "table-responsive mt-10 text-center" }, [
              _c("table", { staticClass: "table table-hover table-bordered" }, [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.loading
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "10" } }, [
                            _vm._v("查詢中"),
                          ]),
                        ])
                      : _vm.message
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "10" } }, [
                            _vm._v(_vm._s(_vm.message)),
                          ]),
                        ])
                      : _vm.total <= 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "10" } }, [
                            _vm._v("沒有資料"),
                          ]),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: item.newsId }, [
                            _c("td", [
                              _c("img", {
                                staticStyle: { "max-height": "80px" },
                                attrs: {
                                  src: _vm.getImageUrl(item, "1"),
                                  alt: _vm.getImageUrl(item, "1"),
                                },
                              }),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.公告標題))]),
                            _c("td", [_vm._v(_vm._s(item.類別))]),
                            _c("td", [
                              _c(
                                "fieldset",
                                {
                                  staticClass: "ckbx-style-8 ckbx toggleInput",
                                  style: !_vm.can修改 ? "opacity:.5;" : false,
                                  attrs: { disabled: !_vm.can修改 },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: item.是否開啟,
                                        expression: "item.是否開啟",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      "true-value": 1,
                                      "false-value": 0,
                                      id: `check${index}`,
                                      name: "ckbx-style-8",
                                    },
                                    domProps: {
                                      checked: Array.isArray(item.是否開啟)
                                        ? _vm._i(item.是否開啟, null) > -1
                                        : _vm._q(item.是否開啟, 1),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = item.是否開啟,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? 1 : 0
                                          if (Array.isArray($$a)) {
                                            var $$v = _vm._n(null),
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "是否開啟",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "是否開啟",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "是否開啟", $$c)
                                          }
                                        },
                                        function ($event) {
                                          return _vm.saveItem(item, false)
                                        },
                                      ],
                                    },
                                  }),
                                  _c("label", {
                                    attrs: { for: `check${index}` },
                                  }),
                                ]
                              ),
                              _c("p", [
                                _vm._v(_vm._s(item.是否開啟 ? "開啟" : "關閉")),
                              ]),
                            ]),
                            _c("td", [
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.是否置頂 === 1,
                                    expression: "item.是否置頂 === 1",
                                  },
                                ],
                                staticClass: "fa fa-thumb-tack fa-lg",
                              }),
                            ]),
                            _c("td", [
                              _c(
                                "fieldset",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    gap: "5px",
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: item.排序,
                                        expression: "item.排序",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    staticClass: "form-control p-5",
                                    staticStyle: {
                                      "padding-right": "0",
                                      "text-align": "center",
                                    },
                                    attrs: {
                                      type: "number",
                                      min: "1",
                                      max: "",
                                    },
                                    domProps: { value: item.排序 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "排序",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-warning btn-add btn-upDate",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveItem(item, false)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-fw fa-arrows-v",
                                      }),
                                      _vm._v("更新 "),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.FormatTime(item.建立時間))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(item.異動人員 || item.建立者)),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.FormatTime(item.異動時間))),
                            ]),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewItem(item)
                                    },
                                  },
                                },
                                [_vm._v("預覽")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary ml-5",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editorItem(item)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger ml-5",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [_vm._v("刪除")]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > _vm.searchData.PageSize,
                  expression: "total > searchData.PageSize",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.total,
                  pageLimitCount: _vm.searchData.PageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "section",
      {
        directives: [
          { name: "show", rawName: "v-show", value: _vm.id, expression: "id" },
        ],
        key: _vm.edit.newsId,
        staticClass: "content",
      },
      [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c(
              "div",
              { staticClass: "top-box with-border" },
              [
                _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                  _vm._v(_vm._s(_vm.id != "New" ? "編輯" : "新增") + "內容"),
                ]),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-block btn-default btn-cog fa fa-reply",
                    attrs: {
                      to: { params: { id: null, searchData: _vm.searchData } },
                    },
                  },
                  [_vm._v(" 返回列表 ")]
                ),
              ],
              1
            ),
            _c(
              "form",
              {
                staticClass: "form-horizontal",
                attrs: { name: "announcementForm" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveItem(
                      _vm.edit,
                      $event.submitter.value == "preview"
                    )
                  },
                },
              },
              [
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "col-sm-2 control-label" }, [
                      _vm._v(" 公告編號： "),
                    ]),
                    _c("div", { staticClass: "col-sm-10 mt-7" }, [
                      _vm._v(" " + _vm._s(this.edit.newsId) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.類別,
                              expression: "edit.類別",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "類別",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { disabled: "disabled", value: "" } },
                            [_vm._v("請選擇分類")]
                          ),
                          _vm._l(_vm.newsType, function (name, key) {
                            return _c(
                              "option",
                              { key: key, domProps: { value: name } },
                              [_vm._v(_vm._s(name))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.公告標題,
                            expression: "edit.公告標題",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputTitle",
                          required: "",
                          maxlength: "20",
                        },
                        domProps: { value: _vm.edit.公告標題 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "公告標題", $event.target.value)
                          },
                        },
                      }),
                      _c("p", { staticClass: "help-block" }, [
                        _vm._v(
                          "標題上限 " +
                            _vm._s(_vm.edit.公告標題.length) +
                            "/20 字"
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.edit.排序,
                            expression: "edit.排序",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "inputOrderNum",
                          min: "0",
                          required: "",
                        },
                        domProps: { value: _vm.edit.排序 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "排序",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "否",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.是否置頂,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否置頂", _vm._n($$v))
                              },
                              expression: "edit.是否置頂",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "1",
                              message: "是",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.是否置頂,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否置頂", _vm._n($$v))
                              },
                              expression: "edit.是否置頂",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(5),
                    _c("div", { staticClass: "col-sm-10 mt-7" }, [
                      _c(
                        "fieldset",
                        { staticClass: "ckbx-style-8 ckbx toggleInput mr-10" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.edit.是否開啟,
                                expression: "edit.是否開啟",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              "true-value": 1,
                              "false-value": 0,
                              id: `checkEdit`,
                              name: "ckbx-style-8",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.edit.是否開啟)
                                ? _vm._i(_vm.edit.是否開啟, null) > -1
                                : _vm._q(_vm.edit.是否開啟, 1),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.edit.是否開啟,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 1 : 0
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm._n(null),
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.edit,
                                          "是否開啟",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.edit,
                                          "是否開啟",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.edit, "是否開啟", $$c)
                                  }
                                },
                                function ($event) {
                                  return _vm.changeStatus(_vm.item)
                                },
                              ],
                            },
                          }),
                          _c("label", { attrs: { for: `checkEdit` } }),
                        ]
                      ),
                      _c("span", [
                        _vm._v(_vm._s(_vm.edit.是否開啟 ? "開啟" : "關閉")),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(6),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _c("DateAndTimePicker", {
                          attrs: { format: "YYYY-MM-DD HH:mm:ss" },
                          model: {
                            value: _vm.edit.發佈時間,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "發佈時間", $$v)
                            },
                            expression: "edit.發佈時間",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-group image-part" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "AdPic" },
                      },
                      [
                        _vm.edit.類別 == "活動"
                          ? _c("span", { staticClass: "text-red" }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                        _vm._v(" 圖片： "),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _c(
                          "ImageSelect",
                          {
                            ref: "imageSelect",
                            attrs: {
                              id: "AdPic2",
                              gid: _vm.edit.newsId,
                              info: "1",
                              module: "ActivityNews",
                              maxsize: 500 * 1024,
                              accept:
                                "image/webp, image/png, image/jpeg, image/gif",
                              required: _vm.edit.類別 == "活動",
                            },
                            model: {
                              value: _vm.edit.files,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "files", $$v)
                              },
                              expression: "edit.files",
                            },
                          },
                          [
                            _c("p", { staticClass: "help-block" }, [
                              _vm._v("※"),
                              _c("span", { staticClass: "text-red" }, [
                                _vm._v("圖片尺寸 1130×665"),
                              ]),
                              _vm._v(
                                "，檔案格式 webp, jpg, png, gif，大小上限500KB。"
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(7),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "editor" },
                        [
                          _c("tinymce", {
                            attrs: { height: "600" },
                            model: {
                              value: _vm.edit.內文,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "內文", $$v)
                              },
                              expression: "edit.內文",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _vm._m(8),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("圖片"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "15%" } }, [
        _vm._v("標題"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "5%" } }, [
        _vm._v("類別"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "5%" } }, [
        _vm._v("置頂"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("排序"),
      ]),
      _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
        _vm._v("建立日期"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
        _vm._v("異動人員"),
      ]),
      _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
        _vm._v("異動日期"),
      ]),
      _c("th", { staticStyle: { "min-width": "190px", width: "5%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 類別： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 公告標題： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "inputOrderNum" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 排序： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 置頂： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 是否開啟： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內文： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-footer" }, [
      _c("div", { staticClass: "btns" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-success btn-lg fa fa-eye",
            staticStyle: { "margin-right": "10px" },
            attrs: { type: "submit", value: "preview" },
          },
          [_vm._v(" 預覽 ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-lg fa fa-save",
            attrs: { type: "submit" },
          },
          [_vm._v(" 確認送出 ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }