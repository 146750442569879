import { render, staticRenderFns } from "./ForumStatisticList.vue?vue&type=template&id=770f515b"
import script from "./ForumStatisticList.vue?vue&type=script&lang=js"
export * from "./ForumStatisticList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\GitProjects\\ompweb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('770f515b')) {
      api.createRecord('770f515b', component.options)
    } else {
      api.reload('770f515b', component.options)
    }
    module.hot.accept("./ForumStatisticList.vue?vue&type=template&id=770f515b", function () {
      api.rerender('770f515b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projects/Newtalk/ForumStatisticList.vue"
export default component.exports