var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PopForm", {
        ref: "editForm",
        staticClass: "popform",
        scopedSlots: _vm._u([
          {
            key: "slot-title",
            fn: function () {
              return [_vm._v(" 識別值設定 ")]
            },
            proxy: true,
          },
          {
            key: "slot-body",
            fn: function () {
              return [
                _c("div", { staticClass: "nav-tabs-custom" }, [
                  _c("ul", { staticClass: "nav nav-tabs" }, [
                    _c(
                      "li",
                      { class: { active: _vm.idType === _vm.IDTypeEnum.產品 } },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                _vm.idType = _vm.IDTypeEnum.產品
                              },
                            },
                          },
                          [_vm._v("產品")]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      { class: { active: _vm.idType === _vm.IDTypeEnum.金流 } },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                _vm.idType = _vm.IDTypeEnum.金流
                              },
                            },
                          },
                          [_vm._v("金流")]
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "tab-content" }, [
                    _c("div", { staticClass: "div-border" }, [_vm._v(" ")]),
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane",
                        class: { active: _vm.idType === _vm.IDTypeEnum.產品 },
                      },
                      [
                        _c("table", { staticClass: "table table-bordered" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _vm._v("名稱"),
                                ]),
                              ]),
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _vm._v("識別值"),
                                ]),
                              ]),
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _vm._v("描述"),
                                ]),
                              ]),
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _vm._v("功能"),
                                ]),
                              ]),
                            ]),
                            _c("tr", [
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.companyAddField.vendorName,
                                        expression:
                                          "companyAddField.vendorName",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "名稱",
                                    },
                                    domProps: {
                                      value: _vm.companyAddField.vendorName,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.companyAddField,
                                          "vendorName",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.companyAddField.vendorId,
                                        expression: "companyAddField.vendorId",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "識別值,不可為空",
                                    },
                                    domProps: {
                                      value: _vm.companyAddField.vendorId,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.companyAddField,
                                          "vendorId",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value:
                                          _vm.companyAddField.vendorDescription,
                                        expression:
                                          "companyAddField.vendorDescription",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "描述",
                                    },
                                    domProps: {
                                      value:
                                        _vm.companyAddField.vendorDescription,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.companyAddField,
                                          "vendorDescription",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addCompanyBtnClick()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("新增/修改")))]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.companyList, function (item) {
                              return _c("tr", { key: item.vendorId }, [
                                _c("td", [_vm._v(_vm._s(item.vendorName))]),
                                _c("td", [_vm._v(_vm._s(item.vendorId))]),
                                _c("td", [
                                  _vm._v(_vm._s(item.vendorDescription)),
                                ]),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          _vm.刪除產品(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("刪除")))]
                                  ),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane",
                        class: { active: _vm.idType === _vm.IDTypeEnum.金流 },
                      },
                      [
                        _c("table", { staticClass: "table table-bordered" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _vm._v("名稱"),
                                ]),
                              ]),
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _vm._v("識別值"),
                                ]),
                              ]),
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _vm._v("描述"),
                                ]),
                              ]),
                              _c("th", [
                                _c("div", { staticClass: "border" }, [
                                  _vm._v("功能"),
                                ]),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [
                                _c("div", { staticClass: "border" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.paymentAddField.paymentName,
                                        expression:
                                          "paymentAddField.paymentName",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "名稱",
                                    },
                                    domProps: {
                                      value: _vm.paymentAddField.paymentName,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.paymentAddField,
                                          "paymentName",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("td", [
                                _c("div", { staticClass: "border" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.paymentAddField.paymentId,
                                        expression: "paymentAddField.paymentId",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "識別值,不可為空",
                                    },
                                    domProps: {
                                      value: _vm.paymentAddField.paymentId,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.paymentAddField,
                                          "paymentId",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("td", [
                                _c("div", { staticClass: "border" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value:
                                          _vm.paymentAddField
                                            .paymentDescription,
                                        expression:
                                          "paymentAddField.paymentDescription",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "描述",
                                    },
                                    domProps: {
                                      value:
                                        _vm.paymentAddField.paymentDescription,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.paymentAddField,
                                          "paymentDescription",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("td", [
                                _c("div", { staticClass: "border" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addPaymentBtnClick()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("新增/修改")))]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.paymentList, function (item) {
                              return _c("tr", { key: item.paymentId }, [
                                _c("td", [_vm._v(_vm._s(item.paymentName))]),
                                _c("td", [_vm._v(_vm._s(item.paymentId))]),
                                _c("td", [
                                  _vm._v(_vm._s(item.paymentDescription)),
                                ]),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          _vm.刪除金流(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("刪除")))]
                                  ),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "slot-footer",
            fn: function () {
              return [_vm._v("   ")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }