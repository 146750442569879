<template>
  <div id="CustomizeList">
    <section class="content-header">
      <h1>簡訊自訂發送</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>簡訊自訂發送</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">

            <div class="date-group" style="width: auto;">
              <label class="control-label">時間：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-left">
                  <DateRangePicker
                    :propTimePicker="false"
                    :propEndDate.sync="searchData.結束時間"
                    :propStartDate.sync="searchData.開始時間"
                    :key="resetDate"
                  />
                </div>
              </div>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">預約狀態：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.是否預約"
                @change="resetDataList()"
              >
                <option :value="-1">全部</option>
                <option :value="0">直接發送</option>
                <option :value="1">預約發送</option>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">發送狀態：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.是否已發送"
                @change="resetDataList()"
              >
                <option :value="-1">全部</option>
                <option :value="0">未發送</option>
                <option :value="1">已發送</option>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">發送簡訊商：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.簡訊商代碼"
                @change="resetDataList()"
              >
                <option
                  v-for="(sendCom, index) in sendComList"
                  :value="sendCom.簡訊商代碼"
                  :key="index"
                >
                  {{ sendCom.簡訊商名稱 }}
                </option>
              </select>
            </div>

            <div class="input-group">
              <label for="" class="control-label">發送客服</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="輸入人員"
                type="text"
                v-model="searchData.建立人員"
              />
            </div>

            <div class="input-group ml-10">
              <label for="" class="control-label">門號：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="範例(0925151184)"
                type="text"
                v-model="searchData.門號"
              />
            </div>

            <div class="btns">
              <button class="btn btn-primary mr-10" type="button" @click="resetDataList()">查詢</button>
            </div>

            <div class="form-group fr snmMT w100-md mt-15-md">
              <button class="btn btn-warning w100-md" type="button" @click="add()">新增發送</button>
            </div>
            
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%;">序號</th>
                  <th class="text-nowrap" style="width: 15%; min-width: 350px;">簡訊內容</th>
                  <th class="text-nowrap" style="width: 8%;">發送簡訊商</th>
                  <th class="text-nowrap" style="width: 5%;">發送筆數</th>
                  <th class="text-nowrap" style="width: 5%;">發送完成</th>
                  <th class="text-nowrap" style="width: 5%;">到達筆數</th>
                  <th class="text-nowrap" style="width: 5%;">是否預約</th>
                  <th class="text-nowrap" style="width: 15%;">預約發送時間</th>
                  <th class="text-nowrap" style="width: 15%;">建立時間</th>
                  <th class="text-nowrap" style="width: 8%;">建立人員</th>
                  <th class="text-nowrap text-center" style="width: 10%;">功能</th>
                </tr>
                <template v-if="list.length > 0">
                  <tr :key="item.id" v-for="(item, index) in list">
                    <td class="text-nowrap text-center">
                      {{ (searchData.pageNum - 1) * searchData.pageSize + index + 1 }}
                    </td>
                    <td
                      class="text-nowrap"
                      style="white-space: normal;"
                    >
                      <div>
                        {{ item.發送內容 }}
                      </div>
                    </td>
                    <td class="text-nowrap text-center">{{ SMSName[item.簡訊商代碼] }}</td>
                    <td class="text-nowrap text-center">{{ item.筆數 }}</td>
                    <td class="text-nowrap text-center">
                      {{ sendStatus(item) }}
                    </td>
                    <td class="text-nowrap text-center">{{ item.到達筆數 }}</td>
                    <td class="text-nowrap text-center">
                      {{ item.是否預約 == '1' ? '是' : '否' }}
                    </td>
                    <td class="text-nowrap text-center">
                      <span v-if="item.是否預約 == '1'">{{ item.預約發送時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</span>
                    </td>
                    <td class="text-nowrap text-center">
                      {{ item.建立時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}
                    </td>
                    <td class="text-nowrap text-center">{{ item.建立人員 }}</td>

                    <td class="text-nowrap text-center">
                      <button
                        class="btn btn-success mr-10"
                        type="button"
                        @click="showItem(item)"
                      >
                        查看
                      </button>
                      <button
                        class="btn btn-primary mr-10"
                        type="button"
                        @click="editItem(item)"
                        v-if="item.是否已發送 == 0"
                      >
                        編輯
                      </button>
                      <button
                        class="btn btn-danger"
                        type="button"
                        @click="deleteItem(item)"
                        v-if="item.是否已發送 == 0"
                      >
                        刪除
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import DateRangePicker from '@/components/DateRangePicker';
import api from "@/assets/js/app-api";
import moment from "moment";

export default {
  components: {
    Pagination,
    DateRangePicker
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      searchData: {
        是否預約: -1, //-1為不下條件  0:直接發送 1:預約發送
        是否已發送: -1, //-1為不下條件  0:未發送 1:已發送
        pageNum: 1, 
        pageSize: 10, 
        開始時間: new moment().subtract(3, "month"),
        結束時間: new moment(),
        建立人員: "",
        門號: "",
        簡訊商代碼: "",
      },
      resetDate: new Date().getTime(),
      list: [],
      total: 0,
      comList: {},
      sendComList: [],
      SMSName:{}
    }
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    this.getSendComList();
    this.getDataList();
  },
  mounted() {},
  computed: {},
  methods: {
    getSendComList() {
      api.moloSMS.sendComList().then(({data}) => {
        this.sendComList = data.result;
        this.sendComList.forEach(item => {
          this.SMSName[item.簡訊商代碼] = item.簡訊商名稱
        })
        this.searchData.簡訊商代碼 = this.sendComList[0].簡訊商代碼
      })
    },
    getDataList() {
      this.searchData.開始時間 = new Date(this.searchData.開始時間);
      this.searchData.結束時間 = new Date(this.searchData.結束時間);
      
      this.loading = true;
      api.moloSMS.customize.queryAll(this.searchData)
        .then((res) => {
          this.loading = false;
          this.list = res.data.result.datas;
          this.total = res.data.result.total;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    add() {
      this.$router.push({
        name: "SMSCustomizeEdit",
        params: { 
          searchData: this.searchData
        }
      })
    },
    editItem(item) {
      this.$router.push({
        name: "SMSCustomizeEdit",
        params: { 
          id: item.流水號,
          searchData: this.searchData
        }
      })
    },
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.moloSMS.customize.delete({
            流水號: item.流水號,
          }).then((res) => {
            this.global.showResponse(this, res, 'delete', this.getDataList)
          })
        }
      });
    },
    showItem(item) {
      this.$router.push({
        name: "SMSCustomizeDetail",
        params: { 
          id: item.流水號,
          searchData: this.searchData
        }
      })
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    sendStatus(item) {
      if (item.是否已發送 == 1) {
        return '是'
      }
      if (item.是否已發送 == 2) {
        return '發送中'
      }
      if (item.是否已發送 == 3) {
        return '排程中'
      }
      return '否';
    }
  }
}
</script>

<style scoped lang="scss">
#CustomizeList {
  .date-group {
    .input-group {
      width: 265px;
      .form-control {
        float: left;
        width: 225px;
      }
    }
  }
}
</style>