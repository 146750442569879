var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _vm._m(0),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { name: "AWH5WebHome" } } }, [
              _vm._v("官網管理"),
            ]),
          ],
          1
        ),
        _c(
          "li",
          [_c("router-link", { attrs: { to: _vm.goBack } }, [_vm._v("消息")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.id))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "top-box with-border" },
            [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.isEdit ? "編輯" : "新增") + "內容"),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-default btn-cog pull-right",
                  attrs: { to: _vm.goBack },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ],
            1
          ),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-body",
                  staticStyle: { "max-width": "900px" },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("fieldset", { staticClass: "col-sm-12" }, [
                      _c(
                        "label",
                        {
                          staticClass: "required",
                          attrs: { for: "inputType" },
                        },
                        [_vm._v("消息分類：")]
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.分類,
                              expression: "edit.分類",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "inputType", required: "" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "分類",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "",
                                disabled: "",
                                selected: "",
                                hidden: "",
                              },
                            },
                            [_vm._v("請選擇")]
                          ),
                          _vm._l(_vm.classTypes, function (item, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: item.classTypeId },
                              },
                              [_vm._v(_vm._s(item.className))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "nav-tabs-custom" }, [
                    _c(
                      "ul",
                      {
                        staticClass: "nav nav-tabs",
                        staticStyle: {
                          background:
                            "linear-gradient(transparent, rgba(0, 0, 0, 0.05))",
                        },
                      },
                      [
                        _vm._l(_vm.langs, function (lang, index) {
                          return [
                            _c(
                              "li",
                              { class: { active: lang.id == _vm.editLang } },
                              [
                                _c(
                                  "a",
                                  {
                                    class: { required: lang.required },
                                    attrs: { title: lang.id },
                                    on: {
                                      click: function ($event) {
                                        _vm.editLang = lang.id
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(lang.name) + " ")]
                                ),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "tab-content" },
                      [
                        _vm._l(_vm.langs, function (lang, index) {
                          return _c(
                            "div",
                            { class: { "sr-only": lang.id !== _vm.editLang } },
                            [
                              _c(
                                "fieldset",
                                { class: { required: lang.required } },
                                [
                                  _c("label", [_vm._v("消息封面：")]),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-sm-12" },
                                      [
                                        _c(
                                          "ImageSelect",
                                          {
                                            ref: "banner",
                                            refInFor: true,
                                            attrs: {
                                              id: `bannerPc_${lang.id}`,
                                              gid: _vm.edit.newsId,
                                              module: "WebNews",
                                              info: `${lang.id}_desktop`,
                                              accept:
                                                "image/webp, image/jpeg, image/png, image/gif",
                                              maxsize: 200 * 1024,
                                              required:
                                                _vm.editLang == lang.id &&
                                                lang.required,
                                            },
                                            model: {
                                              value: _vm.edit.files,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.edit, "files", $$v)
                                              },
                                              expression: "edit.files",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "help-block" },
                                              [
                                                _vm._v(
                                                  "※尺寸 W460×H270，檔案上限200KB。"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        _c(
                          "fieldset",
                          {
                            class: {
                              required: _vm.langs.find(
                                (lang) =>
                                  lang.id == _vm.editLang && lang.required
                              ),
                            },
                          },
                          [
                            _c("label", { attrs: { for: "newsTitle" } }, [
                              _vm._v("消息標題："),
                            ]),
                            _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "newsTitle",
                                required: _vm.langs.find(
                                  (lang) =>
                                    lang.id == _vm.editLang && lang.required
                                ),
                              },
                              domProps: { value: _vm.getProp("newsTitle") },
                              on: {
                                input: function ($event) {
                                  return _vm.setProp(
                                    "newsTitle",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                        _c(
                          "fieldset",
                          {
                            staticClass: "mt-15",
                            class: {
                              required: _vm.langs.find(
                                (lang) =>
                                  lang.id == _vm.editLang && lang.required
                              ),
                            },
                          },
                          [
                            _c("label", { attrs: { for: "newsContent" } }, [
                              _vm._v("消息內容："),
                            ]),
                            _c("tinymce", {
                              attrs: {
                                height: "600",
                                id: "newsContent",
                                required: _vm.langs.find(
                                  (lang) =>
                                    lang.id == _vm.editLang && lang.required
                                ),
                                value: _vm.getProp("newsContent"),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setProp("newsContent", $event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "fieldset",
                          {
                            staticClass: "mt-15",
                            class: {
                              required: _vm.langs.find(
                                (lang) =>
                                  lang.id == _vm.editLang && lang.required
                              ),
                            },
                          },
                          [
                            _c("label", { attrs: { for: "newsTags" } }, [
                              _vm._v("標籤："),
                            ]),
                            _c("TagSelect", {
                              attrs: {
                                taggable: "",
                                id: "newsTags",
                                required: _vm.langs.find(
                                  (lang) =>
                                    lang.id == _vm.editLang && lang.required
                                ),
                                value: _vm.getProp("newsTags"),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setProp("newsTags", $event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
              _vm._m(1),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [
      _c("i", { staticClass: "fa fa-fw fa-commenting" }),
      _vm._v("消息"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-footer" }, [
      _c("div", { staticClass: "btns" }, [
        _c(
          "button",
          { staticClass: "btn btn-primary btn-lg", attrs: { type: "submit" } },
          [_vm._v(" 確認送出 ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }