var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("兌換查詢統計")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _vm._v(_vm._s(_vm.$t("首頁"))),
              ]),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("Yoegames兌換查詢統計")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("產品")) + "："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchData.vendorId,
                        expression: "searchData.vendorId",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "selPaymentType form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "vendorId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("全部"))),
                    ]),
                    _vm._l(_vm.companyList, function (item) {
                      return _c(
                        "option",
                        {
                          key: item.vendorId,
                          domProps: { value: item.vendorId },
                        },
                        [_vm._v(_vm._s(item.vendorName))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("金流")) + "："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.paymentType,
                        expression: "searchData.paymentType",
                      },
                    ],
                    staticClass: "selPaymentType form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "paymentType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("全部"))),
                    ]),
                    _vm._l(_vm.paymentList, function (item) {
                      return _c(
                        "option",
                        {
                          key: item.paymentId,
                          domProps: { value: item.paymentId },
                        },
                        [_vm._v(_vm._s(item.paymentName))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("br"),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("交易日期")) + "："),
                ]),
                _c("div", { staticClass: "input-group date" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "pull-right" },
                    [
                      _c("DateRangePicker", {
                        ref: "dateRangePicker",
                        attrs: {
                          propStartDate: _vm.searchData.startTime,
                          propEndDate: _vm.searchData.endTime,
                        },
                        on: {
                          "update:propStartDate": function ($event) {
                            return _vm.$set(_vm.searchData, "startTime", $event)
                          },
                          "update:prop-start-date": function ($event) {
                            return _vm.$set(_vm.searchData, "startTime", $event)
                          },
                          "update:propEndDate": function ($event) {
                            return _vm.$set(_vm.searchData, "endTime", $event)
                          },
                          "update:prop-end-date": function ($event) {
                            return _vm.$set(_vm.searchData, "endTime", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.onSearchBtnClick()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("查詢")) + " ")]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    class: { disabled: !this.lastQueryCommand },
                    attrs: { type: "button" },
                    on: { click: _vm.onExportStatisticsReportBtnClick },
                  },
                  [_vm._v(" 匯出統計結果 ")]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    class: { disabled: !this.lastQueryCommand },
                    attrs: { type: "button" },
                    on: { click: _vm.onExportReportBtnClick },
                  },
                  [_vm._v(" 匯出明細 ")]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    attrs: { type: "button" },
                    on: { click: _vm.onOpenIDPageBtnClick },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("識別值設定")) + " ")]
                ),
              ]),
              _c("hr"),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-hover table-bordered table-condensed table",
                },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(1),
                      _vm.list.length > 0
                        ? [
                            _vm._l(_vm.list, function (item) {
                              return _c(
                                "tr",
                                { key: item.vendorName + item.payDetailType },
                                [
                                  _c("td", [_vm._v(_vm._s(item.vendorName))]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.payDetailType)),
                                  ]),
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatNumber(item.totalCount)
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("td", [_vm._v(_vm._s(item.currency))]),
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.formatNumber(item.amount))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatNumber(
                                            item.consumptionState0
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatNumber(
                                            item.consumptionState1
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _vm._l(_vm.sum, function (value, currency) {
                              return _c("tr", { key: currency }, [
                                _c("th", [_vm._v("總計")]),
                                _c("th", [_vm._v(" ")]),
                                _c(
                                  "th",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formatNumber(value.totalCount))
                                    ),
                                  ]
                                ),
                                _c("th", [_vm._v(_vm._s(currency))]),
                                _c(
                                  "th",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formatNumber(value.amount))
                                    ),
                                  ]
                                ),
                                _c(
                                  "th",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatNumber(
                                          value.consumptionState0
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "th",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatNumber(
                                          value.consumptionState1
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            }),
                          ]
                        : [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text-center",
                                  attrs: { colspan: "7" },
                                },
                                [_vm._v(_vm._s(_vm.$t("沒有資料")))]
                              ),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
            _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tip))]),
          ]),
        ]),
      ]),
      _c("FMSIDPage", {
        ref: "FMSIDPage",
        on: { updateIdList: _vm.updateIdList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("產品")]),
      _c("th", [_vm._v("金流")]),
      _c("th", [_vm._v("數量")]),
      _c("th", [_vm._v("幣別")]),
      _c("th", [_vm._v("總金額")]),
      _c("th", [_vm._v("未配點")]),
      _c("th", [_vm._v("已配點")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }