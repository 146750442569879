var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("活動總覽")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("活動總覽")]),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.id))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "top-box with-border" },
            [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.id == "New" ? "新增" : "編輯") + "內容"),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: {
                    to: {
                      name: "XsgGameBanner",
                      params: { searchData: _vm.$route.params.searchData },
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ],
            1
          ),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  _vm.Save()
                },
              },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _vm.id !== "New"
                  ? _c("div", { staticClass: "form-group mb-2" }, [
                      _c("span", { staticClass: "col-sm-3 control-label" }, [
                        _vm._v("活動編號："),
                      ]),
                      _c("div", { staticClass: "col-sm-9 mt-7" }, [
                        _vm._v(_vm._s(_vm.edit.公告ID)),
                      ]),
                    ])
                  : _vm._e(),
                _vm.id !== "New"
                  ? _c("div", { staticClass: "form-group mb-2" }, [
                      _c("span", { staticClass: "col-sm-3 control-label" }, [
                        _vm._v("建立日期："),
                      ]),
                      _c("div", { staticClass: "col-sm-9 mt-7" }, [
                        _vm._v(_vm._s(_vm.uiDate(_vm.edit.建立時間))),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "gameType" },
                    },
                    [_vm._v("公告類別：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _vm.id == "New"
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.類別,
                                expression: "edit.類別",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: { "max-width": "320px" },
                            attrs: { id: "gameType", required: "" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.edit,
                                    "類別",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  _vm.edit.標籤 = ""
                                },
                              ],
                            },
                          },
                          _vm._l(
                            _vm.types.gameBannerType,
                            function (name, key) {
                              return _c(
                                "option",
                                { key: key, domProps: { value: name } },
                                [_vm._v(_vm._s(name))]
                              )
                            }
                          ),
                          0
                        )
                      : _c("div", { staticClass: "mt-7" }, [
                          _vm._v(_vm._s(_vm.edit.類別)),
                        ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "gameOrder" },
                    },
                    [_vm._v("排序：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.edit.排序,
                          expression: "edit.排序",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "gameOrder",
                        min: "1",
                        required: "",
                        placeholder: "排序",
                      },
                      domProps: { value: _vm.edit.排序 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "排序",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "gameStatus" },
                    },
                    [_vm._v("狀態：")]
                  ),
                  _c("div", { staticClass: "col-sm-9 mt-5" }, [
                    _c(
                      "fieldset",
                      { staticClass: "ckbx-style-8 ckbx toggleInput" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.edit.狀態,
                              expression: "edit.狀態",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "gameStatus",
                            "true-value": 1,
                            "false-value": 0,
                            name: "ckbx-style-8",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.edit.狀態)
                              ? _vm._i(_vm.edit.狀態, null) > -1
                              : _vm._q(_vm.edit.狀態, 1),
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.edit.狀態,
                                $$el = $event.target,
                                $$c = $$el.checked ? 1 : 0
                              if (Array.isArray($$a)) {
                                var $$v = _vm._n(null),
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.edit,
                                      "狀態",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.edit,
                                      "狀態",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.edit, "狀態", $$c)
                              }
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "gameStatus" } }),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { staticClass: "col-sm-3 control-label required" },
                    [_vm._v("顯示開始時間：")]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c(
                        "DateAndTimePicker",
                        {
                          attrs: { format: "YYYY/MM/DD HH:mm:ss" },
                          model: {
                            value: _vm.edit.顯示開始時間,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "顯示開始時間", $$v)
                            },
                            expression: "edit.顯示開始時間",
                          },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "mt-7 ml-10",
                              staticStyle: { "flex-shrink": "0" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.isForever,
                                    expression: "isForever",
                                  },
                                ],
                                staticStyle: {
                                  position: "relative",
                                  width: "1.5em",
                                  height: "1.5em",
                                  "vertical-align": "sub",
                                  "margin-top": "-.2em",
                                  "accent-color": "#3498db",
                                  cursor: "pointer",
                                },
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.isForever)
                                    ? _vm._i(_vm.isForever, null) > -1
                                    : _vm.isForever,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.isForever,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.isForever = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.isForever = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.isForever = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(" 常駐顯示 "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                !_vm.isForever
                  ? _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        { staticClass: "col-sm-3 control-label required" },
                        [_vm._v("顯示結束時間：")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("DateAndTimePicker", {
                            attrs: { format: "YYYY/MM/DD HH:mm:ss" },
                            model: {
                              value: _vm.edit.顯示結束時間,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "顯示結束時間", $$v)
                              },
                              expression: "edit.顯示結束時間",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "gameAction" },
                    },
                    [_vm._v("動作1：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        staticStyle: { "margin-bottom": "0" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "0",
                                name: "gameAction",
                                message: "無功能",
                              },
                              model: {
                                value: _vm.edit.動作1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "動作1", $$v)
                                },
                                expression: "edit.動作1",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "1",
                                name: "gameAction",
                                message: "遊戲館",
                              },
                              model: {
                                value: _vm.edit.動作1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "動作1", $$v)
                                },
                                expression: "edit.動作1",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "2",
                                name: "gameAction",
                                message: "連結至遊戲功能",
                              },
                              model: {
                                value: _vm.edit.動作1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "動作1", $$v)
                                },
                                expression: "edit.動作1",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mt-10",
                        staticStyle: {
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _vm.edit.動作1 == "1"
                          ? [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label mr-5 required",
                                  staticStyle: { "flex-shrink": "0" },
                                  attrs: { for: "gameId" },
                                },
                                [_vm._v("遊戲館分類：")]
                              ),
                              _c("div", { staticClass: "select-group mr-10" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.actions[_vm.edit.動作1].選項,
                                        expression: "actions[edit.動作1].選項",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { required: "" },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.actions[_vm.edit.動作1],
                                            "選項",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          return _vm.updateActions()
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          value: "",
                                          disabled: "",
                                          hidden: "",
                                        },
                                      },
                                      [_vm._v("請選擇幣別")]
                                    ),
                                    _vm._l(
                                      _vm.types.gameCoin,
                                      function (option, key) {
                                        return _c("option", {
                                          domProps: {
                                            value: key,
                                            textContent: _vm._s(
                                              `${option} | ${key}`
                                            ),
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _c("div", { staticClass: "select-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.actions[_vm.edit.動作1].附加動作,
                                      expression:
                                        "actions[edit.動作1].附加動作",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "gameId",
                                    min: "1",
                                    required: "",
                                    placeholder: "遊戲館ID",
                                  },
                                  domProps: {
                                    value: _vm.actions[_vm.edit.動作1].附加動作,
                                  },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.actions[_vm.edit.動作1],
                                          "附加動作",
                                          $event.target.value.trim()
                                        )
                                      },
                                      function ($event) {
                                        return _vm.updateActions()
                                      },
                                    ],
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ]
                          : _vm._e(),
                        _vm.edit.動作1 == "2"
                          ? [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label mr-5 required",
                                  staticStyle: { "flex-shrink": "0" },
                                },
                                [_vm._v("遊戲功能：")]
                              ),
                              _c("div", { staticClass: "select-group" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.string",
                                        value:
                                          _vm.actions[_vm.edit.動作1].附加動作,
                                        expression:
                                          "actions[edit.動作1].附加動作",
                                        modifiers: { string: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { required: "" },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.actions[_vm.edit.動作1],
                                            "附加動作",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          return _vm.updateActions()
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          value: "",
                                          disabled: "",
                                          hidden: "",
                                        },
                                      },
                                      [_vm._v("請選擇遊戲功能")]
                                    ),
                                    _vm._l(
                                      _vm.types.gameAction,
                                      function (option, key) {
                                        return _c("option", {
                                          domProps: {
                                            value: key,
                                            textContent: _vm._s(
                                              `${option} | ${key}`
                                            ),
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "gameAction2" },
                    },
                    [_vm._v("動作2：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        staticStyle: { "margin-bottom": "0" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: null,
                                name: "gameAction2",
                                message: "無功能",
                              },
                              model: {
                                value: _vm.edit.動作2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "動作2", $$v)
                                },
                                expression: "edit.動作2",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "",
                                name: "gameAction2",
                                message: "URL",
                              },
                              model: {
                                value: _vm.edit.動作2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "動作2", _vm._n($$v))
                                },
                                expression: "edit.動作2",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mt-10",
                        staticStyle: {
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _vm.edit.動作2 != null
                          ? [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label mr-5 required",
                                  staticStyle: { "flex-shrink": "0" },
                                },
                                [_vm._v("網址：")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "input-group",
                                  staticStyle: { flex: "1" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.edit.動作2,
                                        expression: "edit.動作2",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "url",
                                      placeholder: "請輸入網址連結",
                                      required: "",
                                    },
                                    domProps: { value: _vm.edit.動作2 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.edit,
                                          "動作2",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm.edit.類別 == "活動總覽"
                  ? _c("div", { staticClass: "form-group image-part" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 control-label required",
                          attrs: { for: "gameLang" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.edit.類別 == "活動總覽" ? "標籤" : "按鈕選項"
                            ) + "："
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.標籤,
                                expression: "edit.標籤",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: { "max-width": "320px" },
                            attrs: { id: "gameLang" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.edit,
                                  "標籤",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("無"),
                            ]),
                            _vm._l(
                              _vm.types.gameBannerBadge,
                              function (name, key) {
                                return _vm.edit.類別 == "活動總覽"
                                  ? _c(
                                      "option",
                                      { key: key, domProps: { value: name } },
                                      [_vm._v(_vm._s(name))]
                                    )
                                  : _vm._e()
                              }
                            ),
                            _vm._l(
                              _vm.types.gameBannerButton,
                              function (name, key) {
                                return _vm.edit.類別 != "活動總覽"
                                  ? _c(
                                      "option",
                                      { key: key, domProps: { value: name } },
                                      [_vm._v(_vm._s(name))]
                                    )
                                  : _vm._e()
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("hr"),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "gameTop" },
                    },
                    [_vm._v("顯示條件：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "mr-10",
                            staticStyle: { "flex-shrink": "0", margin: "0" },
                          },
                          [
                            _vm._v(" 等級 "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isOptLevel,
                                  expression: "isOptLevel",
                                },
                              ],
                              staticStyle: {
                                position: "relative",
                                width: "1.5em",
                                height: "1.5em",
                                "vertical-align": "sub",
                                "margin-top": "-.2em",
                                "accent-color": "#3498db",
                                cursor: "pointer",
                              },
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.isOptLevel)
                                  ? _vm._i(_vm.isOptLevel, null) > -1
                                  : _vm.isOptLevel,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.isOptLevel,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.isOptLevel = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.isOptLevel = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.isOptLevel = $$c
                                  }
                                },
                              },
                            }),
                          ]
                        ),
                        _c(
                          "fieldset",
                          {
                            staticStyle: {
                              display: "flex",
                              width: "100%",
                              "align-items": "center",
                            },
                            attrs: { disabled: !_vm.isOptLevel },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.edit.minlevel,
                                  expression: "edit.minlevel",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: { "padding-right": "0" },
                              attrs: {
                                type: "number",
                                min: "1",
                                max: _vm.edit.maxlevel,
                                required: _vm.isOptLevel,
                              },
                              domProps: { value: _vm.edit.minlevel },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.edit,
                                    "minlevel",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _c("span", { staticClass: "mt-7 mr-5 ml-5" }, [
                              _vm._v("~"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.edit.maxlevel,
                                  expression: "edit.maxlevel",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: { "padding-right": "0" },
                              attrs: {
                                type: "number",
                                min: _vm.edit.minlevel,
                                required: _vm.isOptLevel,
                                disabled: _vm.edit.isLevelNoLimit,
                              },
                              domProps: { value: _vm.edit.maxlevel },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.edit,
                                    "maxlevel",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "ml-10",
                                staticStyle: {
                                  "flex-shrink": "0",
                                  margin: "0",
                                },
                              },
                              [
                                _vm._v(" 無上限 "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.edit.isLevelNoLimit,
                                      expression: "edit.isLevelNoLimit",
                                    },
                                  ],
                                  staticStyle: {
                                    position: "relative",
                                    width: "1.5em",
                                    height: "1.5em",
                                    "vertical-align": "sub",
                                    "margin-top": "-.2em",
                                    "accent-color": "#3498db",
                                    cursor: "pointer",
                                  },
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.edit.isLevelNoLimit
                                    )
                                      ? _vm._i(_vm.edit.isLevelNoLimit, null) >
                                        -1
                                      : _vm.edit.isLevelNoLimit,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.edit.isLevelNoLimit,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.edit,
                                              "isLevelNoLimit",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.edit,
                                              "isLevelNoLimit",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.edit,
                                          "isLevelNoLimit",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("p", { staticClass: "help-block text-red" }, [
                      _vm._v(" 若無選擇則全玩家皆可看到 "),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "gameTop" },
                    },
                    [_vm._v("置頂：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        staticStyle: { "margin-bottom": "0" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: false,
                                name: "gameTop",
                                message: "否",
                              },
                              model: {
                                value: _vm.edit.isTop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "isTop", $$v)
                                },
                                expression: "edit.isTop",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: true,
                                name: "gameTop",
                                message: "是",
                              },
                              model: {
                                value: _vm.edit.isTop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "isTop", $$v)
                                },
                                expression: "edit.isTop",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass: "form-group col-sm-9",
                      staticStyle: { "margin-bottom": "0" },
                    },
                    _vm._l(_vm.types.gameRegion, function (region, i) {
                      return _c(
                        "div",
                        { staticClass: "checkbox checkbox-inline" },
                        [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regions[i],
                                  expression: "regions[i]",
                                },
                              ],
                              staticStyle: {
                                position: "relative",
                                width: "1.5em",
                                height: "1.5em",
                                "vertical-align": "sub",
                                "margin-top": "-.2em",
                                "accent-color": "#3498db",
                                cursor: "pointer",
                              },
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.regions[i])
                                  ? _vm._i(_vm.regions[i], null) > -1
                                  : _vm.regions[i],
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.regions[i],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.regions,
                                            i,
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.regions,
                                            i,
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.regions, i, $$c)
                                    }
                                  },
                                  function ($event) {
                                    return _vm.updateRegions()
                                  },
                                ],
                              },
                            }),
                            _vm._v(" " + _vm._s(region) + " "),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c(
                    "div",
                    {
                      staticClass: "form-group col-sm-9",
                      staticStyle: { "margin-bottom": "0" },
                    },
                    _vm._l(_vm.types.gamePlatform, function (platform, i) {
                      return _c(
                        "div",
                        { staticClass: "checkbox checkbox-inline" },
                        [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.platforms[i],
                                  expression: "platforms[i]",
                                },
                              ],
                              staticStyle: {
                                position: "relative",
                                width: "1.5em",
                                height: "1.5em",
                                "vertical-align": "sub",
                                "margin-top": "-.2em",
                                "accent-color": "#3498db",
                                cursor: "pointer",
                              },
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.platforms[i])
                                  ? _vm._i(_vm.platforms[i], null) > -1
                                  : _vm.platforms[i],
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.platforms[i],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.platforms,
                                            i,
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.platforms,
                                            i,
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.platforms, i, $$c)
                                    }
                                  },
                                  function ($event) {
                                    return _vm.updatePlatforms()
                                  },
                                ],
                              },
                            }),
                            _vm._v(" " + _vm._s(platform) + " "),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c("hr"),
                _c("div", { staticClass: "form-group image-part" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "AdPic1" },
                    },
                    [_vm._v("活動圖片連結1：")]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c(
                        "ImageSelect",
                        {
                          ref: "imageSelect1",
                          attrs: {
                            id: "AdPic1",
                            gid: _vm.edit.公告ID,
                            info: "1",
                            module: "GameBanner",
                            maxsize: 500 * 1024,
                            accept: "image/png, image/jpeg",
                          },
                          model: {
                            value: _vm.edit.files,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "files", $$v)
                            },
                            expression: "edit.files",
                          },
                        },
                        [
                          _vm.edit.類別 == "活動總覽"
                            ? _c("p", { staticClass: "help-block" }, [
                                _vm._v("* "),
                                _c("span", { staticClass: "text-red" }, [
                                  _vm._v("圖片尺寸 740×370"),
                                ]),
                                _vm._v("，檔案格式為png或jpg，大小上限500KB。"),
                              ])
                            : _c("p", { staticClass: "help-block" }, [
                                _vm._v("* "),
                                _c("span", { staticClass: "text-red" }, [
                                  _vm._v("圖片尺寸 1280×720"),
                                ]),
                                _vm._v("，檔案格式為png或jpg，大小上限500KB。"),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.edit.類別 == "活動總覽"
                  ? _c("div", { staticClass: "form-group image-part" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 control-label",
                          attrs: { for: "AdPic2" },
                        },
                        [_vm._v("活動圖片連結2：")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c(
                            "ImageSelect",
                            {
                              ref: "imageSelect2",
                              attrs: {
                                id: "AdPic2",
                                gid: _vm.edit.公告ID,
                                info: "2",
                                module: "GameBanner",
                                maxsize: 500 * 1024,
                                accept: "image/png, image/jpeg",
                              },
                              model: {
                                value: _vm.edit.files,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "files", $$v)
                                },
                                expression: "edit.files",
                              },
                            },
                            [
                              _c("p", { staticClass: "help-block" }, [
                                _vm._v("* "),
                                _c("span", { staticClass: "text-red" }, [
                                  _vm._v("圖片尺寸 362×510"),
                                ]),
                                _vm._v("，檔案格式為png或jpg，大小上限500KB。"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "inputTitle" },
                    },
                    [_vm._v("標題：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.標題,
                          expression: "edit.標題",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "inputTitle",
                        maxlength: "50",
                        required: "",
                        placeholder: "",
                      },
                      domProps: { value: _vm.edit.標題 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "標題", $event.target.value)
                        },
                      },
                    }),
                    _c("p", { staticClass: "help-block" }, [
                      _vm._v(
                        "上限 " + _vm._s(_vm.edit.標題.length) + " / 50 字"
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "inputDescription" },
                    },
                    [_vm._v("備註：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.備註,
                          expression: "edit.備註",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputDescription",
                        rows: "3",
                        placeholder: "",
                      },
                      domProps: { value: _vm.edit.備註 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "備註", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label required",
                      attrs: { for: "inputContent" },
                    },
                    [_vm._v("內文：")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c(
                      "div",
                      { staticClass: "editor" },
                      [
                        _c("tinymce", {
                          attrs: { height: "600" },
                          model: {
                            value: _vm.edit.內文,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "內文", $$v)
                            },
                            expression: "edit.內文",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._m(2),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputPlatform" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 所屬地區(可複選)： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputPlatform" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 顯示平台(可複選)： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-footer" }, [
      _c("div", { staticClass: "btns" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-lg fa fa-save",
            attrs: { type: "submit" },
          },
          [_vm._v(" 確認送出 ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }