<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>遊戲介紹{{ isEdit ? '編輯' : '新增' }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link :to="{name:'XsgWebGame', params:{ searchData: $route.params.searchData }}">遊戲介紹列表</router-link></li>
        <li class="active">{{ id }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ isEdit ? "編輯" : "新增"}}內容</h4>
            <router-link :to="{name:'XsgWebGame', params:{ searchData: $route.params.searchData }}" class="btn btn-block btn-default btn-cog">
              <i class="fa fa-reply"></i> 返回列表
            </router-link>
            <!-- <button type="button" @click="backList()" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </button> -->
          </div>
          <form class="form-horizontal" name="announcementForm" @submit="$event.preventDefault(); Save()">
            <div class="box-body">
              
              <div class="form-group">
                <label class="col-sm-3 control-label">
                  GID：
                </label>
                <div class="col-sm-9 mt-7">
                  {{ this.edit.gid }}
                </div>
              </div>
              
              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 遊戲分類：
                </label>
                <div class="col-sm-9">
                  <select class="form-control select-part" v-model="edit.遊戲分類">
                    <option value="" selected disabled>請選擇分類</option>
                    <option v-for="(name, key) in dfn.gameCategory" :key="key" :value="key">
                      {{ key + ' ' + name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputGameId">
                  <span class="text-red">*</span> 遊戲館ID：
                </label>
                <div class="col-sm-9">
                  <input type="text" id="inputGameId" class="form-control" v-model="edit.遊戲館" required placeholder="" />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputTitle">
                  <span class="text-red">*</span> 中文名稱：
                </label>
                <div class="col-sm-9">
                  <input type="text" id="inputTitle" class="form-control" v-model="edit.中文名稱" required maxlength="20" placeholder="" />
                  <span class="text-muted">{{ edit.中文名稱.length }} / 20</span>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputName">
                  <span class="text-red">*</span> 英文名稱：
                </label>
                <div class="col-sm-9">
                  <input type="text" id="inputName" class="form-control" v-model="edit.英文名稱" required maxlength="20" placeholder="" />
                  <span class="text-muted">{{ edit.英文名稱.length }} / 20</span>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="AdPic">
                  <span class="text-red">*</span> 遊戲LOGO：
                </label>
                <div class="col-sm-9">
                  <ImageSelect id="AdPic" ref="imageSelect"
                    :gid="edit.gid" info="logo" module="WebGame"
                    :maxsize="200*1024" accept="image/png"
                    v-model="imgEdit">
                    <p class="help-block">尺寸266&times;260px，背景透明png<br>(圖片綁定貼文，新增圖片即新增貼文)</p>
                  </ImageSelect>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputContent">
                  標題內容：
                </label>
                <div class="col-sm-9"><textarea id="inputName" class="form-control" rows="5" v-model="edit.標題內容" placeholder=""></textarea>
                </div>
              </div>
              
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputVideo">
                  遊戲影片ID：
                </label>
                <div class="col-sm-9">
                  <input type="text" id="inputVideo" class="form-control" v-model="edit.遊戲影片ID" placeholder="填入代碼即可。ex: 8zf6Wi2BtsE" />
                  <div class="help-block">
                    ※ID位置: <span>https://www.youtube.com/watch?v=</span><span class="text-red">8zf6Wi2BtsE</span>
                  </div>
                </div>
              </div>
              
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputContent">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-9">
                  <div class="editor">
                    <tinymce v-model="edit.內容" height="400" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputDescription">
                  Description：
                </label>
                <div class="col-sm-9">
                  <textarea type="text" id="inputDescription" class="form-control" v-model="edit.description" rows="2" placeholder="網頁的描述"></textarea>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputKeywords">
                  Keywords：
                </label>
                <div class="col-sm-9">
                  <input type="text" id="inputKeywords" class="form-control" v-model="edit.keyword" placeholder="網頁的關鍵字" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputLink">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-9">
                  <input type="number" id="inputOrderNum" class="form-control" v-model.number="edit.排序" min="0" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-9">
                  <div class="radio radio-inline">
                    <iCheckRedioButton checkValue="1" message="上架" name="radioMode" v-model="edit.是否發佈" />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton checkValue="0" message="下架" name="radioMode" v-model="edit.是否發佈" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 開始時間：
                </label>
                <div class="col-sm-9">
                  <DateAndTimePicker v-model="edit.開始時間" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 結束時間：
                </label>
                <div class="col-sm-9">
                  <DateAndTimePicker v-model="edit.結束時間" />
                </div>
              </div>
              <hr>
              <div class="form-group" v-for="(group, name) in tagOptions">
                <label class="col-sm-3 control-label">
                  {{ name }}：
                </label>
                <div class="col-sm-9">
                  <TagSelect v-model="tagEdit[name]" :options="tagOptions[name]"></TagSelect>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label"></label>
                <div class="col-sm-9">
                  <p class="help-block">編輯類別與標籤請至 <router-link :to="{name:'XsgWebGameTag'}" target="_blank">類別與標籤管理</router-link></p>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" class="btn btn-primary btn-lg fa fa-save">
                  確認送出
                </button>
              </div>
            </div>
          </form>
          <!-- <pre>{{ edit }}</pre>
          <pre>{{ imgEdit }}</pre> -->
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSG/_Define";
import TagSelect from "@/views/projects/XSG/components/TagSelect";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import tinymce from "@/components/Tinymce";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

// 文件參考: https://vue-multiselect.js.org/#sub-multiple-select
class TagOption{
  constructor(id, label, isDisabled = false){
    this.id = id;
    this.label = label;
    this.$isDisabled = isDisabled;
  }
}

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton,
    DateAndTimePicker,
    tinymce,
    TagSelect,
    ImageSelect,
  },
  props: {
    id: "",
  },
  data() {
    return {
      dfn: dfn,
      isEdit: false,
      projectId: this.$store.state.projectId, // 專案Id
      lang: "zh-tw",
      edit: {
        gid: utils.getTimeId(),
        遊戲分類: "",
        遊戲館: "",
        中文名稱: "",
        英文名稱: "",
        logo: "",
        標題內容: "",
        遊戲影片ID: "",
        內容: "",
        description: "",
        keyword: "",
        排序: 1,
        是否發佈: 1,
        開始時間: new moment().add(1, "days").format("YYYY-MM-DD 12:00:00"),
        結束時間: new moment().add(8, "days").format("YYYY-MM-DD 23:59:59"),
        tagType: [
            {
                "type": "遊戲種類Type",
                "tags": "Slot-Lines,Slot-Ways"
            },
            {
                "type": "遊戲特色Feature",
                "tags": "FG,BG,FG/BG,Respin,Test"
            }
        ],
      },
      
      imgEdit: [],

      tagEdit: {},
      tagOptions: {},
    };
  },
  created() {
    this.getTags();
    this.getData();
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.isEdit = true;
    }
  },
  computed: {
  },
  watch: {
    // imgEdit:{
    //   handler(value){
    //   },
    //   deep: true
    // },
    tagEdit:{
      handler(value){
        let arr = [];
        for (let [key, tags] of Object.entries(value)){
          for (let tag of tags) arr.push(tag.id);
        }
        this.$set(this.edit, 'tags', arr);
      },
      deep: true
    }
  },
  methods: {
    getData(){
      if (this.id == 'New') return;
      this.$store.dispatch("xsw/遊戲館查詢", { GID: this.id }).then(res => {
        this.edit = res;
        let groups = {};
        for (let item of this.edit.tagType || []){
          let tag = new TagOption(item.id, item.tags);
          if (groups[item.type]) groups[item.type].push(tag);
          else groups[item.type] = [tag];
        }
        this.tagEdit = groups;
        this.imgEdit = [{id:0, info:"logo", filesUrl:this.edit.logo}]
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    getTags(){
      this.$store.dispatch("xsw/類別與標籤查詢", {}).then(res => {
        let data = res.datas || [];
        data = data.filter(i => i.狀態);
        let groups = {};
        for (let item of data){
          let tag = new TagOption(item.id, item.標籤, !item.狀態);
          if (groups[item.標籤類別]) groups[item.標籤類別].push(tag);
          else groups[item.標籤類別] = [tag];
        }
        this.tagOptions = groups;
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "XsgWebGame",
        params: { searchData: this.$route.params.searchData },
      });
    },

    //送出
    async Save() {
      let err = [];
      if (this.edit.遊戲分類 == "") err.push("請選擇遊戲分類");
      if (this.edit.中文名稱 == "") err.push("請填寫中文名稱");
      if (this.edit.英文名稱 == "") err.push("請填寫英文名稱");
      if (this.imgEdit[0].filesUrl == "") err.push("請上傳遊戲LOGO");
      // if (this.edit.遊戲影片ID == "") err.push("請填寫遊戲影片ID");
      if (this.edit.排序 == "") err.push("請填寫排序");
      if (this.edit.開始時間 == "") err.push("請填寫開始時間");
      if (this.edit.結束時間 == "") err.push("請填寫結束時間");
      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }

      /* if (this.edit.斷線規則 == "") {
          this.$eventBus.$emit("showAlert", "ISP業者斷線為必填欄位");
          return;
      } */

      console.log(this.edit);

      if (this.imgEdit[0] && /^data/.test(this.imgEdit[0].filesUrl)){
        this.$refs.imageSelect.upload().then(res => {
          // console.log(res)
          let src = res[0][0].filesUrl;
          this.edit.logo = src;
          this.submit();
        }, err => {
          this.$eventBus.$emit("showAlert", "圖片上傳失敗!!" + "<br>" + err);
        })
      } else {
        this.submit();
      }
    },

    submit(){
      let api = this.isEdit ? "修改" : "新增";
      this.$store.dispatch("xsw/遊戲館" + api, this.edit).then(result => {
        this.backList();
      }, error => {
        this.$eventBus.$emit("showAlert", error);
      });
    },
  },
};
</script>
