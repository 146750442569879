var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "PlayerHistoryList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _vm._m(0),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("玩家歷程查詢")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "form",
            {
              staticClass: "top-box",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  _vm.getDataList()
                },
              },
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("操作時間："),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group date" },
                  [
                    _c("div", {
                      staticClass: "input-group-addon fa fa-calendar",
                    }),
                    _c("DateRangePicker", {
                      attrs: {
                        propTimePicker: true,
                        propEndDate: _vm.local.endTime,
                        propStartDate: _vm.local.startTime,
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(_vm.local, "endTime", $event)
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(_vm.local, "endTime", $event)
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(_vm.local, "startTime", $event)
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(_vm.local, "startTime", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.暱稱,
                      expression: "searchData.暱稱",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "玩家暱稱" },
                  domProps: { value: _vm.searchData.暱稱 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "暱稱",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.searchData.種類,
                        expression: "searchData.種類",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return _vm._n(val)
                            })
                          _vm.$set(
                            _vm.searchData,
                            "種類",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.getDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1" } }, [
                      _vm._v("全部種類"),
                    ]),
                    _vm._l(_vm.def.playerAction, function (option, i) {
                      return _c("option", { domProps: { value: i } }, [
                        _vm._v(_vm._s(option)),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "btns mb-10" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit" },
                    on: { click: function ($event) {} },
                  },
                  [_vm._v(" 查詢 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button", disabled: _vm.datas.length === 0 },
                    on: {
                      click: function ($event) {
                        return _vm.exportOrders()
                      },
                    },
                  },
                  [_vm._v(" 匯出 ")]
                ),
              ]),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: Object.keys(_vm.$route.query).length > 0,
                      expression: "Object.keys($route.query).length > 0",
                    },
                  ],
                  staticClass: "btn btn-default pull-right fa fa-arrow-left",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v(" 返回")]
              ),
              _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.datas.length > 0 && _vm.$route.params.nick,
                      expression: "datas.length > 0 && $route.params.nick",
                    },
                  ],
                  staticClass: "btn btn-warning btn-add fa fa-user-circle",
                  attrs: {
                    to: {
                      name: "NmjPlayerData",
                      params: { nick: _vm.$route.params.nick },
                    },
                  },
                },
                [_vm._v(" 玩家資料")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "mt-0" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                { staticClass: "table table-bordered table-hover text-center" },
                [
                  _vm._m(1),
                  _c(
                    "tbody",
                    [
                      _vm.loading
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "15" } }, [
                              _vm._v("查詢中"),
                            ]),
                          ])
                        : _vm.datas.length <= 0
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "15" } }, [
                              _vm._v("查詢無資料"),
                            ]),
                          ])
                        : _vm._l(_vm.datas, function (item, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_vm._v(_vm._s(_vm.uiDate(item.時間)))]),
                              _c("td", [_vm._v(_vm._s(item.玩家暱稱))]),
                              _c("td", [_vm._v(_vm._s(item.種類))]),
                              _c("td", [_vm._v(_vm._s(item.項目))]),
                              _c("td", [_vm._v(_vm._s(item.數量))]),
                              _c("td", [_vm._v(_vm._s(item.當下經驗值))]),
                              _c("td", [_vm._v(_vm._s(item.當下牌技))]),
                              _c("td", [
                                _vm._v(_vm._s(item.當下雀幣 || item.當下雀能)),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.當下雀銀))]),
                              _c("td", [_vm._v(_vm._s(item.原因))]),
                              _c(
                                "td",
                                [
                                  item.種類 == "閃電戰"
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.ID || "-")),
                                      ])
                                    : /^\d+$/.test(item.ID)
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "NmjGameRecord",
                                              query: {
                                                id: item.ID,
                                                nick: item.玩家暱稱,
                                              },
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.ID) + " ")]
                                      )
                                    : _c("span", [_vm._v("-")]),
                                ],
                                1
                              ),
                              _c("td", [_vm._v(_vm._s(item.備註))]),
                            ])
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "box-footer text-center no-border" }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [
      _vm._v("玩家歷程查詢 "),
      _c("small", { staticClass: "text-red" }, [
        _vm._v("(僅能查詢最近三個月內的玩家歷程紀錄，請選擇符合範圍的日期。)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "92px", width: "15%" } }, [
          _vm._v("時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "105px", width: "10%" } }, [
          _vm._v("玩家"),
          _c("wbr"),
          _vm._v("暱稱"),
        ]),
        _c("th", { staticStyle: { "min-width": "105px", width: "5%" } }, [
          _vm._v("種類"),
        ]),
        _c("th", { staticStyle: { "min-width": "120px", width: "15%" } }, [
          _vm._v("項目"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("數量"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("當下"),
          _c("wbr"),
          _vm._v("經驗值"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("當下"),
          _c("wbr"),
          _vm._v("牌技"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("當下"),
          _c("wbr"),
          _vm._v("雀幣"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("當下"),
          _c("wbr"),
          _vm._v("雀銀"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
          _vm._v("原因"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("ID"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
          _vm._v("備註"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }