var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "RewardMessageEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("新增信件")))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v(_vm._s(_vm.$t("首頁"))),
            ]),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "XswGameMessage",
                    query: { nick: _vm.$route.params.nick },
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("遊戲信件發送管理")))]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.$t("新增信件"))),
        ]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c(
              "div",
              { staticClass: "top-box with-border" },
              [
                _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                  _vm._v(_vm._s(_vm.$t("信件內容"))),
                ]),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-block btn-default btn-cog pull-right",
                    attrs: {
                      to: {
                        name: "XswGameMessage",
                        query: { nick: _vm.$route.params.nick },
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-reply" }),
                    _vm._v(" " + _vm._s(_vm.$t("返回列表")) + " "),
                  ]
                ),
              ],
              1
            ),
            _c(
              "form",
              {
                staticClass: "form-horizontal",
                attrs: { name: "rewardMessageForm", method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { for: "inputTitle" },
                      },
                      [
                        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                        _vm._v(" " + _vm._s(_vm.$t("信件標題")) + "： "),
                      ]
                    ),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.data.標題,
                            expression: "data.標題",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputTitle",
                          maxlength: _vm.titleMax,
                          placeholder: _vm.$t("上限X字", [_vm.titleMax]),
                          required: "",
                        },
                        domProps: { value: _vm.data.標題 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.data,
                              "標題",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _c("p", { staticClass: "help-block m-0" }, [
                        _vm._v(
                          _vm._s(_vm.data.標題.length) +
                            " / " +
                            _vm._s(_vm.titleMax)
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { for: "inputSender" },
                      },
                      [
                        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                        _vm._v(" " + _vm._s(_vm.$t("寄件人")) + "： "),
                      ]
                    ),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.data.寄件者,
                            expression: "data.寄件者",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputSender",
                          required: "",
                        },
                        domProps: { value: _vm.data.寄件者 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.data,
                              "寄件者",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { for: "inputLink" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("連結")) + "： ")]
                    ),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.data.連結,
                            expression: "data.連結",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "inputLink" },
                        domProps: { value: _vm.data.連結 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.data,
                              "連結",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "col-sm-3 control-label" }, [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" " + _vm._s(_vm.$t("發送名單")) + "： "),
                    ]),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("div", { staticClass: "form-group mb-10" }, [
                        _c(
                          "div",
                          { staticClass: "radio col-md-3" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                name: "sendRadio",
                                message: _vm.$t("單一玩家"),
                                checkValue: "1",
                              },
                              model: {
                                value: _vm.playerSource,
                                callback: function ($$v) {
                                  _vm.playerSource = _vm._n($$v)
                                },
                                expression: "playerSource",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "fieldset",
                          {
                            staticClass: "col-md-9",
                            style:
                              _vm.playerSource != 1
                                ? "pointer-events:none"
                                : "",
                            attrs: { disabled: _vm.playerSource != 1 },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex", gap: ".5em" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.player,
                                      expression: "player",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("輸入玩家暱稱"),
                                  },
                                  domProps: { value: _vm.player },
                                  on: {
                                    keydown: function ($event) {
                                      ;(function (e) {
                                        if (e.key == "Enter") {
                                          e.preventDefault()
                                          _vm.addPlayer()
                                        }
                                      })($event)
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.player = $event.target.value.trim()
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.player == "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPlayer()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("新增")))]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.playerList.length > 0,
                                    expression: "playerList.length > 0",
                                  },
                                ],
                                staticClass: "mt-10",
                                staticStyle: {
                                  display: "flex",
                                  gap: ".5em",
                                  "align-items": "flex-end",
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "player-list form-control" },
                                  _vm._l(_vm.playerList, function (player, i) {
                                    return _c("li", [
                                      _c("span", [_vm._v(_vm._s(player))]),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-default text-red pull-right",
                                          staticStyle: {
                                            padding: "0 .5em",
                                            "border-color": "currentColor",
                                          },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.playerList.splice(i, 1)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("移除")))]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearPlayers()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("清空")))]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "form-group mb-10" }, [
                        _c(
                          "div",
                          { staticClass: "radio col-md-3 col" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                name: "sendRadio",
                                message: _vm.$t("匯入名單"),
                                checkValue: "2",
                              },
                              model: {
                                value: _vm.playerSource,
                                callback: function ($$v) {
                                  _vm.playerSource = _vm._n($$v)
                                },
                                expression: "playerSource",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "fieldset",
                          {
                            staticClass: "col-md-9 col",
                            style:
                              _vm.playerSource != 2
                                ? "pointer-events:none"
                                : "",
                            attrs: { disabled: _vm.playerSource != 2 },
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "btn btn-primary fa fa-file-alt",
                                on: {
                                  click: function ($event) {
                                    return _vm.importNames()
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("選擇檔案")) + " ")]
                            ),
                            _vm.importList.length > 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success fa fa-list",
                                    staticStyle: { "margin-left": ".35em" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkContent(_vm.data, true)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("查看名單")) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              { staticStyle: { "padding-left": "8px" } },
                              [_vm._v(" " + _vm._s(_vm.importFileName) + " ")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.playerSource != 3
                    ? _c("div", [
                        _c("hr"),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-3 control-label",
                              attrs: { for: "inputMessage" },
                            },
                            [
                              _c("span", { staticClass: "text-red" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("訊息內容")) + "： "),
                            ]
                          ),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.內文,
                                  expression: "data.內文",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                cols: "100",
                                rows: "6",
                                id: "inputMessage",
                                maxlength: _vm.contentMax,
                                placeholder: _vm.$t("上限X字", [
                                  _vm.contentMax,
                                ]),
                                required: "",
                              },
                              domProps: { value: _vm.data.內文 },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.data,
                                    "內文",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("p", { staticClass: "help-block m-0" }, [
                              _vm._v(
                                _vm._s(_vm.data.內文.length) +
                                  " / " +
                                  _vm._s(_vm.contentMax)
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-3 control-label",
                              attrs: { for: "sendAwards" },
                            },
                            [
                              _c("span", { staticClass: "text-red" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("發送獎勵")) + "： "),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-9" },
                            [
                              _vm._l(_vm.data.Items, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "box-group sendAwards",
                                  },
                                  [
                                    item.type != "2"
                                      ? _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.物品編號,
                                                expression: "item.物品編號",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            staticStyle: {
                                              "max-width": "10em",
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  item,
                                                  "物品編號",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          _vm._l(
                                            _vm.gameTypes,
                                            function (name, id) {
                                              return _c(
                                                "option",
                                                { domProps: { value: id } },
                                                [_vm._v(_vm._s(_vm.$t(name)))]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: item.數量,
                                          expression: "item.數量",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control inputNumber",
                                      attrs: {
                                        type: "number",
                                        placeholder: "請輸入數量",
                                        required: "",
                                        min: "1",
                                      },
                                      domProps: { value: item.數量 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "數量",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeReward(item, index)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("刪除")) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-warning fa fa-plus",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addReward()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("新增獎勵")) + " ")]
                              ),
                            ],
                            2
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("fieldset", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { for: "effectiveTime" },
                      },
                      [
                        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                        _vm._v(" " + _vm._s(_vm.$t("期限")) + "： "),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-9" },
                      [
                        _c("DateAndTimePicker", {
                          attrs: { format: "YYYY-MM-DDTHH:mm:ss" },
                          model: {
                            value: _vm.data.期限,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "期限", $$v)
                            },
                            expression: "data.期限",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("hr"),
                    _c("label", { staticClass: "col-sm-3 control-label" }, [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" " + _vm._s(_vm.$t("服務紀錄")) + "： "),
                    ]),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("table", { staticClass: "table table-bordered" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v(_vm._s(_vm.$t("服務類型")))]),
                            _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.data.分類,
                                      expression: "data.分類",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { required: "" },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return _vm._n(val)
                                        })
                                      _vm.$set(
                                        _vm.data,
                                        "分類",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "0",
                                        selected: "",
                                        disabled: "",
                                        hidden: "",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("請選擇類別")))]
                                  ),
                                  _vm._l(_vm.serviceTypes, function (name, id) {
                                    return _c(
                                      "option",
                                      { key: id, domProps: { value: id } },
                                      [_vm._v(_vm._s(name))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v(_vm._s(_vm.$t("內容")))]),
                            _c("td", [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.內容,
                                    expression: "data.內容",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { min: "1", rows: "5", required: "" },
                                domProps: { value: _vm.data.內容 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.data,
                                      "內容",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "box-footer" }, [
                  _c("div", { staticClass: "btns" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-lg fa fa-send-o",
                        attrs: { type: "submit" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("確認送出")) + " ")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _c(
          "FormDialog",
          {
            ref: "nameDialog",
            attrs: { title: `${_vm.$t("匯入名單")}：${_vm.importFileName}}` },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "submit", "data-dismiss": "modal" },
                      },
                      [_vm._v(_vm._s(_vm.$t("關閉")))]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "p",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "space-between",
                },
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("名單數量")) +
                      ": " +
                      _vm._s(_vm.importList.length) +
                      " "
                  ),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filterString,
                      expression: "filterString",
                    },
                  ],
                  staticClass: "form-control",
                  staticStyle: { width: "50%" },
                  attrs: { type: "text", placeholder: _vm.$t("暱稱搜尋") },
                  domProps: { value: _vm.filterString },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.filterString = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _c("table", { staticClass: "table table-bordered" }, [
              _c("thead", [
                _c("tr", { staticClass: "bg-gray-light" }, [
                  _c("th", { attrs: { colspan: "10" } }, [
                    _vm._v(_vm._s(_vm.$t("玩家暱稱"))),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.pagedTable, function (row) {
                  return _c(
                    "tr",
                    _vm._l(row, function (col) {
                      return _c(
                        "td",
                        { staticStyle: { padding: ".1em .5em", width: "25%" } },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                "data-dismiss": "modal",
                                to: {
                                  name: "XswMemberData",
                                  query: { nick: col },
                                },
                              },
                            },
                            [_vm._v(_vm._s(col))]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              { staticClass: "box-footer text-center no-border" },
              [
                _c("Pagination", {
                  ref: "pagination",
                  attrs: { pageLimitCount: _vm.pageSize, total: _vm.pageTotal },
                  on: { pageMethod: _vm.onPageChange },
                }),
              ],
              1
            ),
          ]
        ),
        _c("ImportData", {
          ref: "ImportData",
          attrs: { title: "玩家", checks: _vm.checks },
          on: { importData: _vm.importData },
        }),
        _c("ImportData", {
          ref: "ImportData2",
          attrs: { title: "批次信件", checks: _vm.checks2 },
          on: { importData: _vm.importAllData },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }