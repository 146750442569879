var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "MemberData" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("序號管理")))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v(_vm._s(_vm.$t("首頁"))),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.$t("序號管理"))),
        ]),
      ]),
    ]),
    !_vm.id
      ? _c(
          "section",
          { staticClass: "content", staticStyle: { "min-height": "auto" } },
          [
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "box-body" }, [
                _c(
                  "form",
                  {
                    staticClass: "top-box clearfix",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.getData()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "input-group pull-left mb-10 mr-10" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.序號,
                              expression: "searchData.序號",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: _vm.$t("序號") },
                          domProps: { value: _vm.searchData.序號 },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.searchData,
                                "序號",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "form-group pull-left m-0" }, [
                      _c("div", { staticClass: "pull-left mb-10 mr-10" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-10",
                            attrs: { type: "submit" },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("查詢")) + " ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: {
                              type: "button",
                              disabled: _vm.datas.length === 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.exportData()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("匯出")) + " ")]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "table-responsive max850" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-striped table-bordered text-center",
                    },
                    [
                      _c("thead", [
                        _c("tr", { staticClass: "bg-gray" }, [
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "150px",
                                width: "10%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("序號")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "120px",
                                width: "10%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("兌換品項")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "120px",
                                width: "10%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("兌換項目")))]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "150px",
                                width: "10%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("兌換時間")))]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm.loading
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "11" } }, [
                                  _vm._v(_vm._s(_vm.$t("查詢中"))),
                                ]),
                              ])
                            : _vm.message
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "11" } }, [
                                  _vm._v(_vm._s(_vm.$t(_vm.message))),
                                ]),
                              ])
                            : _vm.datas.length === 0
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "11" } }, [
                                  _vm._v(_vm._s(_vm.$t("查無資料"))),
                                ]),
                              ])
                            : _vm._l(_vm.pagedData, function (item, index) {
                                return _c("tr", { key: item.兌換日期 }, [
                                  _c("td", [_vm._v(_vm._s(item.序號))]),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.$t("新手禮包"))),
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _vm._l(item.兌換物品, function (x, i) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(i != 0 ? ", " : "") +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.dfn.gameItem[x.id]
                                                ) || `(id:${x.id})`
                                              ) +
                                              "×" +
                                              _vm._s(
                                                Number(x.count).toLocaleString(
                                                  "zh-TW"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                  _c("td", [_vm._v(_vm._s(_vm.datas.length))]),
                                ])
                              }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false && _vm.total > _vm.searchData.pageSize,
                        expression: "false && total > searchData.pageSize",
                      },
                    ],
                    staticClass: "text-center mt-10",
                  },
                  [
                    _c("Pagination", {
                      ref: "pagination",
                      attrs: {
                        total: _vm.total,
                        pageLimitCount: _vm.searchData.pageSize,
                        type2: true,
                      },
                      on: { pageMethod: _vm.onPageChange },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }