<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>遊戲聊天室公告</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li v-if="id" class="active"><router-link :to="{params:{ id:null }}">遊戲聊天室公告</router-link></li>
        <li v-else class="active">遊戲聊天室公告</li>
        <li v-if="id" class="active">{{ id != 'New' ? "編輯" : "新增" }}公告</li>
      </ol>
    </section>
    <section v-if="!id" class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <!-- <router-link :to="{params:{id:'New'}}" class="btn btn-block btn-warning btn-add fa fa-plus">
              新增訊息
            </router-link> -->
            <button @click="addNew()" type="button" class="btn btn-block btn-warning btn-add fa fa-plus">
              新增訊息
            </button>
            <div class="select-group">
              <select id="selectUnit" class="form-control" v-model="searchFilter.狀態" required>
                <option value="">全部</option>
                <option v-for="(item, index) in states" :value="item" :key="index">
                  {{ item }}
                </option>
              </select>
            </div>
            <div class="form-group pull-left m-0">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date pull-left">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propStartDate.sync="searchFilter.開始時間"
                  :propEndDate.sync="searchFilter.結束時間"
                  :propTimePicker="false"
                  displayFormat="YYYY/MM/DD HH:mm"
                />
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ datas.length }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10 text-center">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th style="min-width:150px; width:30%">公告內容</th>
                  <th style="min-width:100px; width:15%">開始日期<wbr>(UTC+8)</th>
                  <th style="min-width:100px; width:15%">結束日期<wbr>(UTC+8)</th>
                  <th style="min-width: 75px; width: 5%">狀態</th>
                  <th style="min-width:140px; width: 5%">功能</th>
                </tr>
                <tr v-if="loading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="message"><td colspan="11">{{ $t(message) }}</td></tr>
                <tr v-else-if="datas.length === 0"><td colspan="11">{{$t('查無紀錄')}}</td></tr>
                <tr v-else v-for="(item, index) in pagedData" :key="item.index">
                  <td>
                    <div v-text="item.content" class="textEllipsis text-left"></div>
                  </td>
                  <td>{{ FormatTime(item.startTime) }}</td>
                  <td>{{ FormatTime(item.endTime) }}</td>
                  <td>{{ getStatus(item) }}</td>
                  <td>
                    <button @click="editItem(item)" class="btn btn-primary mr-5">修改</button>
                    <button @click="deleteItem(item)" class="btn btn-danger">刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <!-- <pre style="line-height:1.1;">{{ news.datas[0] }}</pre> -->
    </section>

    <section v-else class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id != "New" ? "編輯" : "新增" }}公告</h4>
            <!-- <router-link @click="cancelItem()" :to="{params:{ id:null }}" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </router-link> -->
            <button type="button" @click="cancelItem(edit)" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 開始時間：
                </label>
                <div class="col-sm-10">
                  <DateAndTimePicker v-model="edit.startTime" />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 結束時間：
                </label>
                <div class="col-sm-10">
                  <DateAndTimePicker v-model="edit.endTime" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內文：
                </label>
                <div class="col-sm-10">
                  <textarea class="form-control" v-model="edit.content" rows="20" required style="resize:vertical;"></textarea>
                  <!-- <div class="editor">
                    <tinymce v-model="edit.content" height="600" />
                  </div> -->
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="button" @click="saveItem(edit)" class="btn btn-primary btn-lg fa fa-save">
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <!-- <pre>{{ datas }}</pre> -->
  </div>
</template>

<style lang="scss" scoped>
  .control-label{
    padding-right:0;
  }
</style>
<style scoped>
  .textEllipsis {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    position: relative;
    overflow: hidden;
    white-space: pre-wrap;
  }

  .textEllipsis>>>img {
    max-width: 100%;
    /* max-height: 120px; */
    height: auto;
    width: auto;
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import tinymce from "@/components/Tinymce";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import { mapState, mapActions } from "vuex";

class Announcement{
  constructor(index = -1){
    this.index = index;
    this.content = "";
    this.startTime = new moment().add(1, "day").format("YYYY-MM-DDT10:00:00");
    this.endTime = new moment().add(1, "month").format("YYYY-MM-DDT23:59:59");
  }
}

export default {
  components: {
    Pagination,
    DateRangePicker,
    DateAndTimePicker,
    iCheckRedioButton,
    tinymce
  },
  props: {
    id: String,
  },
  data() {
    return {
      searchFilter: {
        狀態: "",
        開始時間: new moment().subtract(6, "month").format("YYYY-MM-01T23:59:59"),
        結束時間: new moment().add(6, "month").format("YYYY-MM-01T23:59:59"),
      },
      
      datas: [],
      total: 0,
      loading: false,
      message: "",
      pageNum: 1,
      pageSize: 20,

      edit: {
        content: "",
        startTime: "",
        endTime: "",
        index: -1, // -1 = 新增
      },

      states: ["未開始", "進行中", "已結束"]
    };
  },
  async created() {
    this.getDataList();
  },
  mounted() {
    // window.v = this;
  },
  computed: {
    filteredData: function() {
      console.log("FILTER")
      let data = this.datas;
      let f = this.searchFilter;
      let startDate = new Date(f.開始時間).valueOf();
      let endDate = new Date(f.結束時間).valueOf();
      let arr = [];
      for (let item of data){
        let isMatch = true;
        let sd = new Date(item.startTime).valueOf();
        let ed = new Date(item.endTime).valueOf();
        if (f.狀態 && f.狀態 != this.getStatus(item)) isMatch = false;
        if (sd > endDate || ed < startDate) isMatch = false;
        if (isMatch) arr.push(item);
      }

      this.pageNum = 1;
      this.total = arr.length;
      return arr;
    },
    pagedData: function () {
      return this.filteredData.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
      // this.getDataList();
    },

    getDataList() {
      this.loading = true;
      //列表
      this.$store.dispatch("xsw/聊天室公告查詢", {}).then(res => {
        let data = res.內容 || [];
        for(let i = 0; i < data.length; i++){
          data[i].index = i;
        }
        this.datas = data;
        this.total = data.length;
        this.loading = false;
        this.message = "";
      }, err => {
        this.datas = [];
        this.total = 0;
        this.loading = false;
        this.message = err;
      });
    },

    clickSearch() {
      this.pageNum = 1;
      this.getDataList();
    },

    //*---- 新增內容
    addNew() {
      this.edit = new Announcement(-1);
      this.$router.push({ params: {id: "New"} });
      return;
    },

    //*---- 修改內容
    editItem(item) {
      this.edit = Object.assign({}, item);
      this.$router.push({ params: {id: "Edit" }});
      return;
    },

    cancelItem(){
      this.$router.push({ params: {id: null }});
      this.getDataList();
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.datas.splice(item.index, 1);
          this.$store.dispatch("xsw/聊天室公告異動", {
            設定時間: new moment().format("YYYY-MM-DDTHH:mm:ss"),
            間隔: 10,
            內容: this.datas.map(x => {delete x.index; return x}),
          }).then(res => {
            this.$eventBus.$emit("showAlert", "刪除成功");
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err);
          });
        }
      });
    },

    saveItem(item) {
      let err = [];
      if (item.startTime == "") err.push("請填寫 開始時間");
      if (item.endTime == "") err.push("請填寫 結束時間");
      if (item.content == "") err.push("請填寫 內文");
      if (err.length > 0) return this.$eventBus.$emit("showAlert", err.join('<br>'));


      if (item.index < 0) this.datas.unshift(item);
      else this.datas.splice(item.index, 1, item);
      let action = item.index < 0 ? "新增" : "修改";
      
      this.$store.dispatch("xsw/聊天室公告異動", {
        設定時間: new moment().format("YYYY-MM-DDTHH:mm:ss"),
        間隔: 10,
        內容: this.datas.map(x => {delete x.index; return x}),
      }).then(res => {
        this.$eventBus.$emit("showAlert", action + "成功");
        this.cancelItem();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    getStatus(item){
      let now = Date.now();
      let start = new Date(item.startTime).valueOf();
      let end = new Date(item.endTime).valueOf();
      if (now < start) return this.states[0];
      if (now < end) return this.states[1];
      else return this.states[2];
    },
    FormatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>